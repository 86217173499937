import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { isFreeUser } from '../../services/entity/user/user-service';
import { Tabs } from '../../components/tabs/Tabs';
import { Panel } from '../../components/panels/Panel';
import { useEffect, useState } from 'react';
import { DashboardProvider, useDashboard } from '../../contexts/DashboardContext';
import { ActionCenter } from './components/ActionCenter';

/* More info about charts used in this dashboard:
 * https://www.chartjs.org/docs/latest/
 */

const tabs = [
  { id: 1, name: 'overview', current: true },
  { id: 2, name: 'workshop', current: false },
  { id: 3, name: 'workstation', current: false },
];

export const DashboardPage = () => {
  const navigate = useNavigate();

  const { user } = useUser();

  useEffect(() => {
    if (isFreeUser(user)) {
      navigate('/demo/live');
    }
  }, [user]);

  return (
    <DashboardProvider>
      <DashboardLayout />
    </DashboardProvider>
  );
};

const DashboardLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { charts, workstationSelected, workshopSelected } = useDashboard();

  const [activeTab, setActiveTab] = useState(tabs[0].name);

  const handleClickTab = (tabName) => {
    setActiveTab(tabName);
    navigate(tabs.find((tab) => tab.name === tabName).name);
  };

  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    if (pathSegments.includes('overview')) {
      setActiveTab(tabs[0].name);
    } else if (pathSegments.includes('workshop')) {
      setActiveTab(tabs[1].name);
    } else if (pathSegments.includes('workstation')) {
      setActiveTab(tabs[2].name);
    } else {
      navigate('overview');
    }
  }, [location]);

  if (!charts.length || !workstationSelected || !workshopSelected) return null;

  return (
    <Panel category='main' title='DASHBOARD'>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={handleClickTab} />
      <div className='h-full flex'>
        <div className='flex flex-col w-3/4 mr-4'>
          <Outlet />
        </div>
        <ActionCenter />
      </div>
    </Panel>
  );
};
