import { BellIcon } from '@heroicons/react/24/outline';
import { useUser } from '../../../contexts/UserContext';
import { useEffect, useState } from 'react';
import { readAllNotifications } from '../../../services/entity/notification/notification-api';
import { useSocket } from '../../../contexts/SocketContext';
import { InboxIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import moment from 'moment';

export const Notification = () => {
  const { user } = useUser();
  const { socket, subscribeToEvent, unsubscribeFromEvent } = useSocket();

  const [notifications, setNotifications] = useState([]);
  const [panelIsOpen, setPanelIsOpen] = useState(false);

  useEffect(() => {
    if (user) setNotifications(user.notifications.sort((n1, n2) => n2.creation_date - n1.creation_date));
  }, [user]);

  const togglePanelIsOpen = (isOpen) => {
    setPanelIsOpen(isOpen);

    if (isOpen) readAllNotifications(notifications);
    else {
      setNotifications(
        notifications.map((notification) => ({
          ...notification,
          read_date: Date.now(),
        })),
      );
    }
  };

  const unreadCount = notifications.filter((element) => !element.read_date).length;

  useEffect(() => {
    if (!socket) return;

    const handleNotification = ([notification, users_notifications_ids]) => {
      if (user.id in users_notifications_ids) {
        const notificationId = users_notifications_ids[user.id];
        setNotifications((notifications) => [{ ...notification, id: notificationId }, ...notifications]);
      }
    };

    subscribeToEvent('notification', handleNotification);

    return () => unsubscribeFromEvent('notification', handleNotification);
  }, [socket]);

  return (
    <div>
      <div className='relative'>
        <BellIcon className='h-6 w-6 text-perception-gray-500 cursor-pointer' onClick={() => togglePanelIsOpen(true)} />
        {unreadCount > 0 && (
          <div className='absolute pointer-events-none inline-flex items-center justify-center w-5 h-5 text-xs text-white bg-perception-blue rounded-full -top-1 -end-1'>
            {unreadCount}
          </div>
        )}
      </div>
      <Transition.Root show={panelIsOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={() => togglePanelIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter='ease-in-out duration-200'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in-out duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-70 transition-opacity backdrop-blur-sm' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-hidden'>
            <div className='absolute inset-0 overflow-hidden'>
              <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10'>
                <Transition.Child
                  as={Fragment}
                  enter='transform transition ease-in-out duration-300'
                  enterFrom='translate-x-full'
                  enterTo='translate-x-0'
                  leave='transform transition ease-in-out duration-300'
                  leaveFrom='translate-x-0'
                  leaveTo='translate-x-full'
                >
                  <Dialog.Panel className='pointer-events-auto w-screen max-w-md'>
                    <div className='flex h-full flex-col overflow-y-scroll bg-perception-black-800 shadow-xl'>
                      <div className='flex items-start justify-between bg-perception-gray-800 p-4'>
                        <Dialog.Title className='text-base font-semibold leading-6 text-white'>
                          Notifications
                        </Dialog.Title>
                        <div className='flex items-center'>
                          <button
                            type='button'
                            className='relative rounded-md text-gray-400 hover:text-gray-500 focus:outline-none'
                            onClick={() => togglePanelIsOpen(false)}
                          >
                            <span className='absolute -inset-2.5' />
                            <span className='sr-only'>Close panel</span>
                            <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                      <div className='relative flex-1'>
                        {notifications.map((notification) => (
                          <div
                            key={notification.id}
                            className='relative p-4 hover:bg-perception-blue cursor-pointer text-white'
                          >
                            <a href={notification.link} className='text-sm'>
                              <h3 className='text-base font-semibold'>{notification.title}</h3>
                              <p className='text-sm'>{notification.content}</p>
                              <p className='text-xs text-gray-500'>
                                {moment(notification.creation_date * 1000).format('MMMM D, YYYY h:mm:ss A')}
                              </p>
                            </a>
                            {(notification.read_date === undefined || notification.read_date === null) && (
                              <div className='absolute z-10 top-[50%] right-4 rounded-full w-[12px] h-[12px] mt-[-6px] bg-perception-blue' />
                            )}
                          </div>
                        ))}
                        {notifications.length === 0 && (
                          <div className='text-perception-gray-600 text-md flex flex-col items-center justify-center h-full'>
                            <InboxIcon className='w-8 h-8' />
                            <span className='mt-2'>You do not have any notification</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};
