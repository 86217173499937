import { useMemo, useState } from 'react';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';
import { ExclamationCircleIcon, EyeIcon, PlusIcon } from '@heroicons/react/24/outline';
import { patchData } from '../../../../../../services/api/api-tools';
import { Menu } from '../../../../../../components/menu/Menu';
import { HoverTextButton } from '../../../../../../components/buttons/HoverTextButton';
import { ASSISTANCE_POPUP } from '../../../../../../temp/const';
import ReactDOM from 'react-dom';
import { useQueryClient } from 'react-query';
import { getAnnotationVideoTime } from '../../../../../../services/entity/annotation/annotation-service';

/* 
  In the app, we talk about segments, for the user, we talk about points of interest, they are the same thing. 
  In the database, for a segment, we talk about an annotation.
*/
export const SegmentPanel = ({ selectedSituation, isViewMode, handleDeleteItem, setIsCommentModalOpen }) => {
  const { annotations, setGuidePopup } = useAnnotation();

  const frames = selectedSituation?.specification_frames;

  // Only retrieves annotations of the segment type, an annotation is considered a segment type if it has x and y coordinates.
  const segmentAnnotations = annotations?.filter((annotation) => annotation?.position?.x);

  // Sort segments based on their video time
  segmentAnnotations?.sort((a, b) => getAnnotationVideoTime(a, frames) - getAnnotationVideoTime(b, frames));

  const renderedSegments = segmentAnnotations?.map((segment) => (
    <SegmentRow
      key={segment.id}
      segment={segment}
      frames={frames}
      handleDeleteItem={handleDeleteItem}
      isViewMode={isViewMode}
      setIsCommentModalOpen={setIsCommentModalOpen}
    />
  ));

  const openCreateSegmentGuidePopup = () => {
    setGuidePopup(ASSISTANCE_POPUP.CREATION_SEGMENT);
  };

  return (
    <div className='h-full group/btn'>
      <div className='flex flex-col gap-2'>{renderedSegments}</div>
      {!isViewMode ? (
        <div className='mt-4'>
          <HoverTextButton onClick={openCreateSegmentGuidePopup} disabled={false}>
            <PlusIcon className='w-4 h-4' />
            <span className='pl-2'>Create a new point of interest</span>
          </HoverTextButton>
        </div>
      ) : null}
    </div>
  );
};

/* Markers are the small blue circles annotated on the image that represent a point segmented by the user. */
const SegmentRow = ({ segment, frames, handleDeleteItem, isViewMode, setIsCommentModalOpen }) => {
  const { specification_frame: segmentFrame } = segment;

  const {
    markerHovered: selectedMarker,
    setMarkerHovered: setSegmentSelected,
    isMarkerSelected,
    setIsMarkerSelected,
    setSelectedFrame,
    isVideoSituation,
    setIsInitialSegmentationDone,
    annotationQueryKey,
  } = useAnnotation();

  const queryClient = useQueryClient();

  const { setClickedPixel, setMarkerHovered } = useAnnotation();

  const [menu, setMenu] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(segment.title);

  const segmentHasError = segment.specification_error?.is_error_reviewed === false;
  const segmentError = segment.specification_error;

  const isMenuEnabled = menu && !isViewMode;

  const handleResetModalOpened = () => {
    setIsCommentModalOpen(false);
    setClickedPixel(null);
    setMarkerHovered(null);
  };

  // If the segment belongs to a video, its horodating is displayed.
  const segmentTime = useMemo(() => {
    if (frames?.length) {
      const time = getAnnotationVideoTime(segment, frames);
      const wholeSeconds = Math.floor(time);
      const fractionalSeconds = time - wholeSeconds;
      const formattedSeconds = wholeSeconds.toString().padStart(2, '0');
      const formattedFraction = fractionalSeconds.toFixed(1).substring(1);
      return `00:${formattedSeconds}${formattedFraction}s`;
    }
  }, [frames]);

  const segmentStyle = useMemo(() => {
    const isActive = segment?.id === selectedMarker?.id && isMarkerSelected;
    if (isActive) {
      if (segmentHasError) {
        return 'text-perception-warn-500 font-semibold';
      } else {
        return 'text-perception-blue font-semibold';
      }
    } else if (segmentHasError) {
      return 'text-perception-warn-500';
    } else {
      return 'text-perception-gray-500';
    }
  }, [segmentHasError, isMarkerSelected, selectedMarker]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      alert,
    });
  };

  const handleCloseMenu = (event) => {
    event.stopPropagation();
    setMenu(null);
  };

  const handleClickSegment = (segment) => {
    if (!isEditing) {
      if (isVideoSituation) {
        console.log('[DEBUG] Update frame by clicking segment');
        setIsInitialSegmentationDone(false);
        setSelectedFrame(segmentFrame);
      }
      setSegmentSelected(segment);
      setIsMarkerSelected(true);
      setIsCommentModalOpen(false);
    }
  };

  const handleEdit = (event) => {
    event.stopPropagation();
    setSegmentSelected(segment);
    setIsMarkerSelected(true);
    setMenu(null);
  };

  const handleDelete = async (event) => {
    event.stopPropagation();
    handleResetModalOpened();
    handleDeleteItem('specification_annotations', segment.id);
    setMenu(null);
  };

  const handleRename = (event) => {
    event.stopPropagation();
    handleResetModalOpened();
    setIsEditing(true);
    setSegmentSelected(null);
    setMenu(null);
  };

  const handleNameChange = (event) => {
    setEditedTitle(event.target.value);
  };

  const handleNameSubmit = async () => {
    if (editedTitle.trim() === '') {
      setIsEditing(false);
      setEditedTitle(segment.title);
    } else if (editedTitle === segment.title) {
      setIsEditing(false);
    } else {
      const response = await patchData(`specification_annotations/${segment.id}`, { title: editedTitle });
      if (response) {
        queryClient.setQueryData(annotationQueryKey, (oldData) => {
          if (oldData && oldData.length > 0) {
            return oldData.map((annotation) => {
              if (annotation.id === segment.id) {
                return {
                  ...annotation,
                  title: editedTitle,
                };
              } else {
                return annotation;
              }
            });
          } else {
            return oldData;
          }
        });
      }
      setIsEditing(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleNameSubmit();
    } else if (event.key === 'Escape') {
      setIsEditing(false);
      setEditedTitle(segment.title);
    }
  };

  const handleBlur = () => {
    handleNameSubmit();
  };

  return (
    <div
      key={segment.id}
      className='flex items-end justify-between cursor-pointer'
      onClick={() => handleClickSegment(segment)}
      onContextMenu={handleContextMenu}
    >
      <div className='flex items-center justify-between w-full'>
        <div className='flex items-center gap-2'>
          <EyeIcon className={`${segmentStyle} w-5 h-5`} />
          {isEditing ? (
            <input
              type='text'
              value={editedTitle}
              onChange={handleNameChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              className='ml-4 text-xs font-semibold uppercase bg-transparent border outline-none text-perception-gray-500 border-perception-blue'
              autoFocus
            />
          ) : (
            <span className={`${segmentStyle} uppercase`}>{segment.title}</span>
          )}
          {isVideoSituation ? (
            <span className={`text-xs pl-4 ${segmentHasError && 'text-perception-warn-500'}`}>{segmentTime}</span>
          ) : null}
        </div>

        {segmentHasError ? (
          <Tooltip error={segmentError}>
            <ExclamationCircleIcon className='w-5 h-5 ml-4 text-perception-warn-500' />
          </Tooltip>
        ) : null}
      </div>

      {isMenuEnabled && (
        <Menu menu={menu} setMenu={setMenu} onClose={handleCloseMenu}>
          <span className='p-2 px-4 cursor-pointer text-start hover:bg-perception-blue' onClick={handleEdit}>
            Edit
          </span>
          <span className='p-2 px-4 cursor-pointer text-start hover:bg-perception-blue' onClick={handleRename}>
            Rename
          </span>
          <span className='p-2 px-4 cursor-pointer text-start hover:bg-perception-blue' onClick={handleDelete}>
            Delete
          </span>
        </Menu>
      )}
    </div>
  );
};

const Tooltip = ({ error, children }) => {
  const [visible, setVisible] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setCoords({ x: rect.left, y: rect.top });
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <div className='relative flex items-center' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {visible &&
        ReactDOM.createPortal(
          <div
            className='absolute flex flex-col p-2 text-xs font-semibold text-white border rounded bg-perception-black-800 border-perception-warn-500'
            style={{
              top: `${coords.y - 30}px`,
              left: `${coords.x + 30}px`,
              transform: 'translateY(-50%) ',
            }}
          >
            {error.title}
            <span>{error.content}</span>
          </div>,
          document.body,
        )}
    </div>
  );
};

export default Tooltip;
