import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { generateRandomString } from '../helpers/common/generateRandomString';
import { useUser } from './UserContext';
import { isFreeUser, isTeamUser } from '../services/entity/user/user-service';
import { useOrgData } from './OrgDataContext';
import { useSocket } from './SocketContext';

export const TrialContext = createContext();

export const TrialSetupProvider = ({ children }) => {
  const { user } = useUser();
  const { sendEvent } = useSocket();
  const { workshopSelected } = useOrgData();

  const videoRef = useRef();

  const [isAccessTimeExceeded, setIsAccessTimeExceeded] = useState(false);
  const [isQrCodeShown, setIsQrCodeShown] = useState(false);

  const [typeDevice, setTypeDevice] = useState('');
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [desktopId] = useState(generateRandomString(8));
  const [peer, setPeer] = useState();
  const [stream, setStream] = useState();

  const [isCameraReady, setIsCameraReady] = useState(false);

  const trialDuration = 3600;

  const streamId = workshopSelected?.devices?.length
    ? workshopSelected.devices.reduce(
        (min, current) => (current.id < min.id ? current : min),
        workshopSelected.devices[0],
      )?.stream_id
    : undefined;

  useEffect(() => {
    if (!user) return;

    let mediaRecorder;

    const isTriaLivePage = location.pathname.includes('demo/live');

    if (isCameraReady && streamId && isTriaLivePage) {
      sendEvent('initialize IA', streamId);

      mediaRecorder = new MediaRecorder(videoRef.current.srcObject);

      mediaRecorder.ondataavailable = function (e) {
        sendEvent('video', e.data);
      };

      mediaRecorder.onstop = function () {
        sendEvent('stop_recording');
      };

      mediaRecorder.start(100);
    }

    return () => {
      if (isCameraReady && streamId && mediaRecorder) {
        mediaRecorder.stop();
      }
    };
  }, [user, isCameraReady, streamId]);

  useEffect(() => {
    if (!isFreeUser(user) || isTeamUser(user)) return;

    let registrationDate = user.registration_date;
    if (registrationDate) {
      const intervalId = setInterval(() => {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeElapsed = currentTime - registrationDate;
        if (timeElapsed >= trialDuration) {
          setIsAccessTimeExceeded(true);
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [user]);

  const value = useMemo(
    () => ({
      desktopId,
      typeDevice,
      setTypeDevice,
      peer,
      setPeer,
      stream,
      setStream,
      isAccessTimeExceeded,
      selectedCamera,
      setSelectedCamera,
      isQrCodeShown,
      setIsQrCodeShown,
      isCameraReady,
      setIsCameraReady,
      videoRef,
    }),
    [
      desktopId,
      typeDevice,
      setTypeDevice,
      peer,
      setPeer,
      stream,
      setStream,
      isAccessTimeExceeded,
      selectedCamera,
      setSelectedCamera,
      isQrCodeShown,
      setIsQrCodeShown,
      isCameraReady,
      setIsCameraReady,
      videoRef,
    ],
  );

  return <TrialContext.Provider value={value}>{children}</TrialContext.Provider>;
};

export const useTrialSetupContext = () => {
  const context = useContext(TrialContext);
  if (!context) {
    throw new Error('useTrialSetupContext should be used on TrialProvider');
  }
  return context;
};
