import { Link, useLocation } from 'react-router-dom';
import { handleClassNames } from '../../helpers/common/handleClassNames';
import { useUser } from '../../contexts/UserContext';
import { isFreeUser } from '../../services/entity/user/user-service';
import { BeakerIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { getGeneralNavigationMenu } from '../../helpers/routing/routing-helper';

const clientLogoExists = async () => {
  const imageUrl = process.env.REACT_APP_ADDRESS + 'public/media/client-logo.png';

  try {
    const response = await fetch(imageUrl);
    if (response.ok) return imageUrl;
    else return null;
  } catch (err) {
    return null;
  }
};

export const Navbar = () => {
  const location = useLocation();

  if (location.pathname.includes('calibration')) return null;
  if (location.pathname === '/login') return null;
  if (location.pathname.includes('demo') && location.pathname !== '/demo/live') return null;
  if (location.pathname.includes('operator')) return null;

  return (
    <nav aria-label='Navbar' className='block bg-perception-black-500'>
      <div className='relative w-full'>
        <div>
          <NavbarMainMenu />
        </div>
      </div>
    </nav>
  );
};

export const NavbarMainMenu = () => {
  const { user } = useUser();

  const [trialTimeRemaining, setTrialTimeRemaining] = useState('');

  const generateMenuButtonClasses = (name, isActive, isEnabled) => {
    const baseClasses = 'inline-flex items-center justify-center ml-4';
    const textClass = isActive ? 'text-white' : 'text-perception-gray-400';
    const disabledClass = isEnabled ? 'hover:text-white' : 'pointer-events-none opacity-50';

    return handleClassNames(baseClasses, textClass, disabledClass);
  };

  const [clientLogoUrl, setClientLogoUrl] = useState(null);

  useEffect(() => {
    const fetchLogo = async () => {
      setClientLogoUrl(await clientLogoExists());
    };
    fetchLogo();
  }, []);

  useEffect(() => {
    if (!isFreeUser(user)) return;

    const trialDuration = 3600;
    let intervalId;

    const updateTimer = () => {
      const currentTime = Math.floor(Date.now() / 1000);
      const timeElapsed = currentTime - user.registration_date;
      let timeLeft = trialDuration - timeElapsed;

      if (timeLeft <= 0) {
        clearInterval(intervalId);
        setTrialTimeRemaining('No more time');
        return;
      }

      const hours = Math.floor(timeLeft / 3600);
      const minutes = Math.floor((timeLeft % 3600) / 60);
      const seconds = timeLeft % 60;

      setTrialTimeRemaining(`${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    };

    updateTimer();

    intervalId = setInterval(updateTimer, 1000);

    return () => clearInterval(intervalId);
  }, [user]);

  return (
    <div className='flex justify-between w-full px-2 py-4'>
      <div>
        {/* Left navigation menu*/}
        {getGeneralNavigationMenu(user)
          .filter((menu) => menu.isVisible)
          .map((menu) => (
            <Link
              key={menu.name}
              to={menu.href}
              className={generateMenuButtonClasses(menu.name, menu.isActive, menu.isEnabled)}
            >
              <div className='flex flex-col items-center justify-center w-full'>
                <div className='flex items-center justify-center w-20 h-10 px-2 py-1 bg-perception-black-800'>
                  <span className='w-6 h-6 material-icons' aria-hidden='true'>
                    {menu.icon}
                  </span>
                </div>
                <span className='pt-1 text-xs font-medium'>{menu.name}</span>
              </div>
            </Link>
          ))}
      </div>
      <div className='flex items-center'>
        {clientLogoUrl && (
          <div className='flex items-center justify-center w-auto h-full'>
            <img src={clientLogoUrl} alt='Client Logo' className='h-auto max-h-[60px] max-w-full mr-2' />
          </div>
        )}
        {/* Trial mode banner */}
        {isFreeUser(user) && (
          <div className='p-1 px-3 mr-2 bg-gradient-to-r from-perception-blue to-[#1a9b84] text-white flex justify-center h-full items-center select-none'>
            <div className='flex items-center'>
              <BeakerIcon className='w-5 h-5' />
              <span className='ml-2 mr-4 text-sm font-semibold uppercase'>Trial access</span>
            </div>
            <div className='p-1 px-2 text-xs bg-white/10'>
              <div>Free credits: 1 hour of data analysis</div>
              {user.registration_date !== null && user.registration_date !== undefined && (
                <div className=''>{trialTimeRemaining} remaining</div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
