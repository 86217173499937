import { Link } from 'react-router-dom';

const links = [
  { id: 1, name: 'How to setup a new camera' },
  { id: 2, name: 'How to do maintenance on my camera' },
  { id: 3, name: "Why don't I have data?" },
  { id: 4, name: 'How to personalize my dashboard' },
  { id: 5, name: 'Contact the Perception Manufacturing team' },
];

export const QuickLinks = () => {
  return (
    <div className='flex border-t border-perception-black-900 flex-col mt-6'>
      <div className='font-semibold border-b border-perception-black-900 p-4 mb-2'>Quick links</div>
      {links.map((link) => {
        return (
          <Link to='/' key={link.id} className='px-4 py-2'>
            <span className='text-md text-perception-blue'>{link.name} </span>
          </Link>
        );
      })}
    </div>
  );
};
