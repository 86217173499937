import { getImageDisplayCoordinates } from '../../../../../helpers/image/image-helper';

export const SelectPointer = ({ nativePoint, cameraDimensions, displayDimensions }) => {
  const clickedPoint = getImageDisplayCoordinates(nativePoint, cameraDimensions, displayDimensions);

  return (
    <div
      className='absolute w-2 h-2 bg-perception-blue rounded-full border border-1 border-black z-20'
      style={{
        top: clickedPoint.y,
        left: clickedPoint.x,
      }}
    />
  );
};
