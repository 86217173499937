import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTrialSetupContext } from '../../contexts/TrialSetupContext';
import { SetupSource } from './components/setup/SetupSource';
import { BuyDevice } from './components/setup/BuyDevice';
import { CardIcon } from './components/setup/CardIcon';
import { InfoMessage } from './components/setup/InfoMessage';
import { Button } from '../../components/buttons/Button';
import { VideoCameraIcon, DevicePhoneMobileIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo from '../../assets/images/logos/logo-perception.png';

export const TrialSetupPage = () => {
  const { typeDevice } = useTrialSetupContext();

  const [step, setStep] = useState(0);

  const renderStepView = () => {
    if (step === 1) {
      return <SetupSource step={step} setStep={setStep} typeDevice={typeDevice} />;
    } else if (step === 2) {
      return <BuyDevice step={step} setStep={setStep} />;
    } else {
      return <SelectTypeDevice step={step} setStep={setStep} />;
    }
  };

  return (
    <div id='autosetup' className='bg-white flex flex-col h-full w-full'>
      <div className='border-b-1 border-perception-blue flex justify-between items-center px-4 pt-4 w-full z-10'>
        <img src={Logo} className='w-8 h-8' />
        <a href='https://www.perception-manufacturing.com/contact' rel='noopener noreferrer' target='_blank'>
          <Button type='button' category='tertiary-btn' size='xs'>
            Contact us
          </Button>
        </a>
      </div>
      <div className='flex flex-col flex-grow justify-center w-full px-40 -translate-y-12'>
        <div className='flex flex-col h-1/8'>
          <h2 className='text-2xl xl:text-3xl font-bold text-perception-black-800'>Lineware Demo</h2>
          <h3 className='text-xl xl:text-2xl font-semibold text-perception-blue'>Start collecting data now</h3>
        </div>
        <div className='flex flex-row h-7/8 pt-6 xl:pt-12'>{renderStepView()}</div>
      </div>
      <div className='bg-perception-blue h-10 w-full' />
    </div>
  );
};

const SelectTypeDevice = ({ step, setStep }) => {
  const { setTypeDevice } = useTrialSetupContext();

  const handleNextStep = (typeDevice) => {
    setStep(1);
    setTypeDevice(typeDevice);
  };

  return (
    <div id='setup-autosetup' className='flex flex-col justify-start items-start'>
      <div className='flex justify-start item-center'>
        <CardIcon setter={() => handleNextStep('webcam')} iconComponent={VideoCameraIcon} text='I use my webcam' />
        <CardIcon setter={() => handleNextStep('mobile')} iconComponent={DevicePhoneMobileIcon} text='I use my phone' />
        <CardIcon setter={() => setStep(2)} iconComponent={XMarkIcon} text="I don't have any devices" />
      </div>
      <InfoMessage text='For optimal performance and data collection, Lineware requires a camera with a minimum resolution of 280px and an internet connection' />
      <div className='mt-6'>
        <Link to='/demo'>
          <Button type='button' category='secondary-btn' size='medium' onClick={() => setStep(step - 1)}>
            Back
          </Button>
        </Link>
      </div>
    </div>
  );
};
