import { useEffect, useRef } from 'react';

export const Menu = ({ menu, setMenu, children }) => {
  const menuRef = useRef(null);

  const style = {
    top: menu.mouseY,
    left: menu.mouseX,
    position: 'fixed',
    zIndex: 50,
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenu(null);
    }
  };

  // This function checks if a click event occurred outside the input element.
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={menuRef}
      style={style}
      className='bg-perception-gray-800 border border-perception-black-800 text-white text-xs rounded-xs flex flex-col z-30'
    >
      {children}
    </div>
  );
};
