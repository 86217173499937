import { useEffect, useMemo, useState } from 'react';
import { useDashboard } from '../../contexts/DashboardContext';
import { SummaryStats } from './components/SummaryStats';
import { FilterBar } from './components/FilterBar';
import { getChartsPromises } from '../../services/entity/chart/chart-api';
import { splitCharts } from '../../services/entity/chart/chart-service';
import { Loader } from '../../components/loading/Loader';
import { GenericChart } from './components/charts/GenericChart';

export const Overview = () => {
  const { charts, selectedDateRange } = useDashboard();

  const [isSpecificChartsLoading, setIsSpecificChartsLoading] = useState(false);

  // Contain the data (= InfluxDB points) for each chart
  const [chartsData, setChartsData] = useState([]);

  // Split charts into two arrays: 'summaryCharts' for those in the summary and 'specificCharts' for others
  const [summaryCharts, specificCharts] = useMemo(
    () =>
      splitCharts(
        charts.filter((chart) => chart.workshop === null && chart.workstation === null),
        'overview',
      ),
    [charts],
  );

  useEffect(() => {
    const fetchData = async () => {
      if (specificCharts.length === 0) return;

      setIsSpecificChartsLoading(true);

      if (specificCharts.length > 0 && specificCharts[0].type !== 'donut')
        console.warn('In overview mode, the specific chart should be of type "donut".');

      const resolvedData = await getChartsPromises(specificCharts, selectedDateRange, false);

      setChartsData(resolvedData);
      setIsSpecificChartsLoading(false);
    };

    fetchData();
  }, [specificCharts, selectedDateRange]);

  return (
    <>
      <FilterBar isSpecificChartsLoading={isSpecificChartsLoading} />
      <SummaryStats summaryCharts={summaryCharts} />
      <ChartsBlocks chartsData={chartsData} isLoading={isSpecificChartsLoading} />
    </>
  );
};

const ChartsBlocks = ({ chartsData, isLoading }) => {
  const MAX_CHARTS_NUMBER = 8;

  return (
    <div className='grid grid-cols-4 gap-4 h-full mt-2 relative auto-rows-fr'>
      {isLoading ? (
        <Loader category='transparent' />
      ) : (
        <>
          {chartsData.map((chartData) => (
            <div
              key={chartData.id}
              className='bg-perception-black-800 text-white flex flex-col items-center justify-center p-4'
            >
              <GenericChart type={chartData.type} value={chartData.value} />
              <div className='text-center mt-4 font-semibold'>{chartData.name}</div>
            </div>
          ))}
          {Array.from({ length: MAX_CHARTS_NUMBER - chartsData.length }, (_, index) => (
            <EmptyChartBlock key={-index} />
          ))}
        </>
      )}
    </div>
  );
};

const EmptyChartBlock = () => {
  return <div className='border-perception-black-800 border-2 border-dashed' />;
};
