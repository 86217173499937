import { useEffect, useState } from 'react';
import { Stage } from 'react-konva';
import { useTrialLiveContext } from '../../../../../contexts/TrialLiveContext';
import { segmentPoints } from '../../../../../services/ia/segmentation-api';
import {
  base64ToBlobURL,
  getImageCoordinates,
  getImageNativeCoordinates,
  drawMask,
  getTargetMaskIndex,
  createMaskImageURL,
  getMasksNumber,
  createImageCopy,
  createKonvaLayerFromImage,
  getImageData,
} from '../../../../../helpers/image/image-helper';

export const MaskLayer = ({ cameraDimensions, displayDimensions }) => {
  const {
    snapshot,
    masksLayer,
    setClickedPoints,
    clickedNativePoints,
    setClickedNativePoints,
    setMasksLayer,
    isViewLoadingData,
  } = useTrialLiveContext();

  const [masksImagesUrls, setMasksImagesUrls] = useState([]);
  const [currentMaskIndex, setCurrentMaskIndex] = useState(null);

  // Save points positions when clicking on the layer
  const handleClick = async (e) => {
    if (snapshot && !isViewLoadingData) {
      const clickCoordinates = getImageCoordinates(e);
      setClickedPoints((prevPoints) => [...prevPoints, clickCoordinates]);

      const nativeCoordinates = getImageNativeCoordinates(clickCoordinates, cameraDimensions, displayDimensions);
      setClickedNativePoints((prevPoints) => [...prevPoints, nativeCoordinates]);
    }
  };

  // When the using points his mouse on a mask, show it
  const handleMouseMove = (e) => {
    if (clickedNativePoints.length) return null;
    if (isViewLoadingData) return null;

    const stage = e.target.getStage();
    const pointerPosition = getImageNativeCoordinates(stage.getPointerPosition(), cameraDimensions, displayDimensions);

    if (masksLayer && pointerPosition) {
      const masksImageData = getImageData(masksLayer, cameraDimensions);
      setCurrentMaskIndex(getTargetMaskIndex(masksImageData, pointerPosition));
    }
  };

  const handleMouseLeave = () => {
    if (!clickedNativePoints.length) {
      setCurrentMaskIndex(null);
    }
  };

  // Action to perform when the user clicks a point
  useEffect(() => {
    if (clickedNativePoints.length) {
      const getSegmentPoints = async () => {
        try {
          // API call to segment points
          const maskData = await segmentPoints(
            snapshot.object_id,
            clickedNativePoints.map((point) => [point.x, point.y]),
          );
          const maskImageUrl = base64ToBlobURL(maskData);
          const maskImage = new Image();

          maskImage.onload = () => {
            setMasksLayer(createKonvaLayerFromImage(maskImage, maskImage.width, maskImage.height));
          };

          maskImage.src = maskImageUrl;
        } catch (error) {
          console.error('An error occurred while segmenting points: ', error.message);
        }
      };
      getSegmentPoints();
    }
  }, [clickedNativePoints]);

  // When updating masksLayer (one or all masks), create a separate image for each mask
  useEffect(() => {
    let imagesUrls = [];

    const masksColor = clickedNativePoints.length ? [255, 100, 253] : [65, 100, 253];

    const masksImageData = getImageData(masksLayer, cameraDimensions);
    const masksNumber = getMasksNumber(masksImageData);

    console.log('[DEBUG] Masks number: ' + masksNumber);

    for (let maskIndex = 0; maskIndex <= masksNumber; maskIndex++) {
      const maskImageData = createImageCopy(masksImageData);
      drawMask(maskImageData, maskIndex, masksColor);
      imagesUrls.push(createMaskImageURL(maskImageData));
    }

    setMasksImagesUrls(imagesUrls);

    if (clickedNativePoints.length) {
      setCurrentMaskIndex(getMasksNumber(getImageData(masksLayer, cameraDimensions)));
    }
  }, [masksLayer]);

  useEffect(() => {
    if (!snapshot) {
      setCurrentMaskIndex(null);
    }
  }, [snapshot]);

  return (
    <div className='absolute z-10 top-0 left-0' onClick={(e) => handleClick(e)}>
      {currentMaskIndex !== null && (
        <div className='absolute h-full w-full z-20 opacity-50 pointer-events-none blur-[2px]'>
          <img src={masksImagesUrls[currentMaskIndex]} alt='current_mask' className='w-full h-full' />
        </div>
      )}

      <Stage
        width={displayDimensions.width}
        height={displayDimensions.height}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};
