import { Square3Stack3DIcon } from '@heroicons/react/24/solid';
import { memo, useState } from 'react';
import { Menu } from '../menu/Menu';
import { usePatternSetup } from '../../contexts/PatternSetupContext';

export const Pattern = memo(function Pattern({ pattern, setIsWarnModalOpen, setPatternIdToDelete }) {
  const { setIsPatternPanelOpened, loadEditingPattern } = usePatternSetup();
  const [menu, setMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      alert,
    });
  };

  const handleClose = () => {
    setMenu(null);
  };

  const handleEdit = () => {
    loadEditingPattern(pattern);
    setIsPatternPanelOpened(true);
    handleClose();
  };

  const handleDelete = () => {
    setIsWarnModalOpen(true);
    setPatternIdToDelete(pattern.id);
  };

  return (
    <div className='flex items-center mt-2 cursor-pointer' onContextMenu={handleContextMenu}>
      <Square3Stack3DIcon
        className={`h-5 w-5 ${pattern.is_active ? 'text-perception-blue' : 'text-perception-gray-500'} `}
      />
      <span className='pl-4 text-xs font-semibold uppercase text-perception-gray-500'>{pattern.name}</span>
      {menu && (
        <Menu menu={menu} setMenu={setMenu} onClose={handleClose}>
          <span className='p-2 px-4 cursor-pointer text-start hover:bg-perception-blue' onClick={handleEdit}>
            Edit
          </span>
          <span className='p-2 px-4 cursor-pointer text-start hover:bg-perception-blue' onClick={handleDelete}>
            Delete
          </span>
        </Menu>
      )}
    </div>
  );
});
