import { useEffect, useState } from 'react';
import { useUser } from '../../contexts/UserContext';
import { generateRandomString } from '../../helpers/common/generateRandomString';
import { VerificationCode } from './components/VerificationCode';
import { LoginForm } from './components/LoginForm';
import { SignupForm } from './components/SignupForm';
import { Loader } from '../loading/Loader';
import { useNavigate } from 'react-router';
import { PasswordForgot } from './components/PasswordForgot';
import '../../styles/common/auth/auth-form.scss';

export const AuthForm = ({ organization = null }) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [isAuth, setIsAuth] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isOnVerification, setIsOnVerification] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const randomStreamId = generateRandomString(5);

  useEffect(() => {
    if (user && user.confirmed === false) {
      setIsOnVerification(true);
    } else if (user?.confirmed) {
      navigate('/production');
    } else {
      setIsOnVerification(false);
    }
  }, [user, navigate]);

  const renderForm = () => {
    if (isForgotPassword) {
      return <PasswordForgot setIsFormLoading={setIsFormLoading} setIsForgotPassword={setIsForgotPassword} />;
    } else if (isOnVerification) {
      return <VerificationCode />;
    } else if (isAuth) {
      return (
        <LoginForm
          setIsFormLoading={setIsFormLoading}
          setIsAuth={setIsAuth}
          setIsForgotPassword={setIsForgotPassword}
        />
      );
    } else {
      return (
        <SignupForm
          setIsAuth={setIsAuth}
          setIsFormLoading={setIsFormLoading}
          randomStreamId={randomStreamId}
          organization={organization}
        />
      );
    }
  };

  return (
    <div
      id='form-signup'
      className='flex flex-col items-center absolute p-12 z-50 rounded bg-white w-[500px] h-[600px]'
    >
      {isFormLoading && <Loader />}
      {renderForm()}
    </div>
  );
};

export const HeadForm = ({ title, text }) => {
  return (
    <div className='w-full flex flex-col justify-center items-center'>
      <h1 className='text-4xl font-bold'>{title}</h1>
      <p className='text-lg text-perception-gray-500 text-center pt-2'>{text}</p>
    </div>
  );
};
