import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from '../../../../../../components/buttons/Button';
import { Loader } from '../../../../../../components/loading/Loader';
import { useVideoDeviceStream } from '../../../../../../hooks/useVideoDeviceStream';
import { generateRandomString } from '../../../../../../helpers/common/generateRandomString';
import { useResizeDetector } from 'react-resize-detector';
import { calculateDisplayStreamSizes } from '../../../../../../services/engine/engine-service';
import { useQueryClient } from 'react-query';

export const CreationVideo = ({ specification, closeSituationModal, setSelectedSituation, specificationQueryKey }) => {
  const {
    videoRef,
    frameSizes,
    isReceivingFrames,
    setIsUserRecording,
    originalFramesRecorded,
    setOriginalFramesRecorded,
  } = useVideoDeviceStream(specification.device.stream_id);

  const [recordedBlob, setRecordedBlob] = useState(null);

  const { width, height, ref: imageContainerRef } = useResizeDetector();

  const displaySizes = useMemo(() => {
    const { width: frameWidth, height: frameHeight, ratio: frameRatio } = frameSizes;
    return calculateDisplayStreamSizes(frameWidth, frameHeight, frameRatio, width, height);
  }, [width, height, frameSizes, videoRef.current]);

  return (
    <Fragment>
      <div className='relative h-[70vh] w-[90vw] max-w-full max-h-full mx-auto my-4'>
        {/* RECORDED VIDEO */}
        {recordedBlob && (
          <div className='flex flex-col items-center justify-center w-full h-full'>
            <div style={displaySizes} className='object-cover'>
              <video controls className='w-full h-full'>
                <source src={URL.createObjectURL(recordedBlob)} type='video/webm' />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        )}
        {/* STREAM CAMERA */}
        <div className={`h-full w-full flex flex-col ${recordedBlob ? 'invisible' : 'visible'}`}>
          <div className='flex flex-col items-center justify-center flex-auto overflow-hidden' ref={imageContainerRef}>
            {!isReceivingFrames ? <Loader category='transparent' /> : null}
            <div style={displaySizes} className='relative'>
              <div className='relative w-full h-full'>
                <canvas className='w-full h-full' ref={videoRef}></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MENU */}
      <div className={`flex justify-between ${isReceivingFrames ? 'visible' : 'hidden'} bg-perception-black-800 p-2`}>
        <Button size='small' category='variant-btn' onClick={closeSituationModal}>
          PREVIOUS STEP
        </Button>
        <MenuRecord
          specification={specification}
          videoRef={videoRef}
          recordedBlob={recordedBlob}
          setRecordedBlob={setRecordedBlob}
          closeSituationModal={closeSituationModal}
          setSelectedSituation={setSelectedSituation}
          specificationQueryKey={specificationQueryKey}
          setIsUserRecording={setIsUserRecording}
          originalFramesRecorded={originalFramesRecorded}
          setOriginalFramesRecorded={setOriginalFramesRecorded}
        />
      </div>
    </Fragment>
  );
};

const MenuRecord = ({
  specification,
  videoRef,
  recordedBlob,
  setRecordedBlob,
  closeSituationModal,
  setSelectedSituation,
  specificationQueryKey,
  setIsUserRecording,
  originalFramesRecorded,
  setOriginalFramesRecorded,
}) => {
  const queryClient = useQueryClient();

  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [recordedTime, setRecordedTime] = useState(0);

  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);

  const handleContinueWithFrames = async () => {
    if (originalFramesRecorded.length === 0) return;

    setIsProcessing(true);

    const formData = new FormData();
    for (const [index, { img, boundingBox }] of originalFramesRecorded.entries()) {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = img.naturalWidth;
      canvas.height = img.naturalHeight;
      context.drawImage(img, 0, 0);

      const blob = await new Promise((resolve) => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg');
      });

      const uniqueFilename = `frame_${index}_${generateRandomString(10)}.jpg`;
      formData.append(`frame_${index}`, blob, uniqueFilename);

      formData.append(`boundingBox_${index}`, JSON.stringify(boundingBox));
    }

    if (!specification || !specification.id) {
      console.error('Specification ID is missing');
      setIsProcessing(false);
      return;
    }

    formData.append('specification_id', specification.id);

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}specification_situations`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const situationCreated = await response.json();

      queryClient.setQueryData(specificationQueryKey, (oldData) => {
        if (oldData && oldData.length > 0) {
          const updatedData = {
            ...oldData[0],
            specification_situations: [...oldData[0].specification_situations, situationCreated],
          };
          return [updatedData];
        }
        return oldData;
      });

      setSelectedSituation(situationCreated);
      closeSituationModal();
    } catch (error) {
      console.error('Error while uploading frames:', error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  // Once the recording is complete, the video is split into frames
  const extractFrames = async (videoBlob) => {
    const videoUrl = URL.createObjectURL(videoBlob);
    const videoElement = document.createElement('video');
    videoElement.src = videoUrl;

    videoElement.onloadedmetadata = async () => {
      const frames = [];
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;

      for (let i = 0; i < videoElement.duration; i += 0.5) {
        videoElement.currentTime = i;
        await new Promise((resolve) => {
          videoElement.onseeked = () => {
            ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
              frames.push(blob);
              resolve();
            }, 'image/jpeg');
          };
        });
      }
    };
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
    setIsUserRecording(false);
  };

  const startRecording = () => {
    setIsUserRecording(true);
    recordedChunksRef.current = [];
    const stream = videoRef.current.captureStream();
    mediaRecorderRef.current = new MediaRecorder(stream, {
      mimeType: 'video/webm; codecs=vp8',
    });

    mediaRecorderRef.current.ondataavailable = (e) => {
      if (e.data.size > 0) {
        recordedChunksRef.current.push(e.data);
      }
    };

    mediaRecorderRef.current.onstop = () => {
      const blob = new Blob(recordedChunksRef.current, { type: 'video/webm' });
      setRecordedBlob(blob);
      extractFrames(blob);
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);
    setRecordedTime(0);
  };

  const handleResetRecording = () => {
    setRecordedBlob(null);
    setOriginalFramesRecorded([]);
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    let timer;
    if (isRecording) {
      timer = setInterval(() => {
        setRecordedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(timer);
      setRecordedTime(0);
    }

    return () => clearInterval(timer);
  }, [isRecording]);

  if (recordedBlob) {
    return (
      <div className='flex gap-2'>
        <Button size='small' category='variant-btn' onClick={handleResetRecording}>
          TAKE A NEW VIDEO
        </Button>
        <Button size='small' category='tertiary-btn' onClick={handleContinueWithFrames}>
          {isProcessing ? 'Processing...' : 'CONTINUE WITH FRAMES'}
        </Button>
      </div>
    );
  } else if (isRecording) {
    return (
      <div className='flex items-center gap-2'>
        <Button size='small' category='tertiary-btn' onClick={stopRecording}>
          STOP RECORD
        </Button>
        <div className='flex items-center gap-2 p-2 border border-perception-gray-100'>
          {formatTime(recordedTime)}
          <div className='w-4 h-4 rounded-full bg-perception-error-800' />
        </div>
      </div>
    );
  } else {
    return (
      <Button size='small' category='tertiary-btn' onClick={startRecording}>
        START RECORD
      </Button>
    );
  }
};
