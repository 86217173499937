import io from 'socket.io-client';
import { createContext, useState, useEffect, useContext, useMemo } from 'react';

const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [isSocketConnected, setIsSocketConnected] = useState(false);

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_NODE_EVENTS_PROTOCOL}://${process.env.REACT_APP_NODE_EVENTS_URL}`, {
      reconnection: false,
      path: '/socket',
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Connected to Lineware Socket');
      setIsSocketConnected(true);
    });

    newSocket.on('connect_error', () => {
      newSocket.disconnect();
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from Lineware Socket');
      setIsSocketConnected(false);
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const subscribeToEvent = (eventName, callback) => {
    if (socket) {
      socket.on(eventName, callback);
    }
  };

  const unsubscribeFromEvent = (eventName, callback) => {
    if (socket) {
      socket.off(eventName, callback);
    }
  };

  const sendEvent = (eventName, data) => {
    if (socket) {
      socket.emit(eventName, data);
    }
  };

  const contextValue = useMemo(
    () => ({
      socket,
      subscribeToEvent,
      unsubscribeFromEvent,
      sendEvent,
      isSocketConnected,
    }),
    [socket, subscribeToEvent, unsubscribeFromEvent, sendEvent, isSocketConnected],
  );

  return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>;
};

export const useSocket = () => {
  return useContext(SocketContext);
};
