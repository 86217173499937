import { useState } from 'react';
import { Modal } from '../../../components/modals/Modal';
import { CSVLink } from 'react-csv';
import { InputSelect } from '../../../components/forms/inputs/InputSelect';
import { optionDateRange, useDashboard } from '../../../contexts/DashboardContext';
import { useOrgData } from '../../../contexts/OrgDataContext';
import { Calendar } from '../../../components/others/Calendar';
import { Button } from '../../../components/buttons/Button';
import { InputFile } from '../../../components/forms/inputs/InputFile';
import { useAlert } from '../../../contexts/AlertContext';
import { cleanParsedData, parseCSV } from '../../../helpers/file/file-helper';

const headers = [
  { label: 'comment', key: 'comment' },
  { label: 'startdate', key: 'startdate' },
  { label: 'enddate', key: 'enddate' },
];

const data = [{ comment: 'example', startdate: 'dd/mm/YYYY hh:mm', enddate: 'dd/mm/YYYY hh:mm' }];

export const FilterBar = ({
  contexts,
  contextSelected,
  setContextSelected,
  isChartLoading,
  graphsData,
  isWorkstation = false,
}) => {
  const { showAlert } = useAlert();
  const { workshops } = useOrgData();
  const {
    selectedDateRange,
    setSelectedDateRange,
    workshopSelected,
    setWorkshopSelected,
    setCustomData,
    customDataLoadCount,
    setCustomDataCount,
  } = useDashboard();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredDateOptions = optionDateRange.filter((item) => item.name !== 'CUSTOM');

  const handleFileChange = (inputEvent) => {
    const file = inputEvent.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const csvData = event.target.result;

      if (validateCSVFormat(csvData)) {
        const parsedData = parseCSV(csvData);
        const cleanedData = cleanParsedData(parsedData);

        setCustomData(cleanedData);
        setCustomDataCount(cleanedData.length);

        localStorage.setItem('custom-csv-data', JSON.stringify(parsedData));
        inputEvent.target.value = '';
      } else {
        showAlert('error', 'Oops! The CSV file format is not correct. Please use the provided template. ');
        inputEvent.target.value = '';
      }
    };

    reader.readAsText(file);
  };

  const validateCSVFormat = (csvData) => {
    const lines = csvData.split('\n');
    const headers = lines[0].split(',');
    const cleanedHeaders = headers.map((header) => header.replace(/"/g, '').trim());
    const expectedHeaders = ['comment', 'startdate', 'enddate'];

    const headersValid = cleanedHeaders.every((header) => expectedHeaders.includes(header));

    if (!headersValid) {
      return false;
    }

    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4} (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])$/;

    for (let i = 1; i < lines.length; i++) {
      const cells = lines[i].split(',');
      let startdate = cells[cleanedHeaders.indexOf('startdate')].trim();
      let enddate = cells[cleanedHeaders.indexOf('enddate')].trim();

      startdate = startdate.replace(/"/g, '');
      enddate = enddate.replace(/"/g, '');

      if (!dateRegex.test(startdate) || !dateRegex.test(enddate)) {
        return false;
      }
    }

    return true;
  };

  function formatItemToString(item) {
    if (item && item.name) {
      if (item.name === 'CUSTOM') {
        return item.name;
      } else {
        return 'LAST ' + item.name;
      }
    } else {
      return '';
    }
  }

  const handleDeleteCustomData = () => {
    localStorage.removeItem('custom-csv-data');
    setCustomData([]);
    setCustomDataCount(0);
    showAlert('success', 'The custom data has been deleted.');
    setIsModalOpen(false);
  };

  return (
    <div className='flex items-center justify-between py-2'>
      {contexts && graphsData ? (
        <div className='flex items-center'>
          <InputFile onChange={handleFileChange} buttonText='Upload periods' />
          <CSVLink data={data} headers={headers} filename={'template-lineware.csv'}>
            <Button size='xs' category='secondary-btn' className='ml-2'>
              Download template
            </Button>
          </CSVLink>
          <Button size='xs' className='ml-2 bg-perception-error-500 text-white' onClick={() => setIsModalOpen(true)}>
            Delete custom data
          </Button>
          {isModalOpen && (
            <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
              <div className='p-4 flex flex-col items-center'>
                <h3 className='text-lg text-start font-medium leading-6 text-gray-900'>Confirm deletion</h3>
                <div className='mt-2'>
                  <p className='text-sm text-start text-gray-500'>Are you sure you want to delete this?</p>
                </div>
                <div className='mt-4 flex justify-start'>
                  <button
                    className='mr-2 inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700'
                    onClick={handleDeleteCustomData}
                  >
                    Delete
                  </button>
                  <button
                    className='inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
                    onClick={() => setIsModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          )}
          <p className='text-sm mt-2 ml-4'>
            {customDataLoadCount} period{customDataLoadCount > 1 ? 's' : ''} loaded
          </p>
        </div>
      ) : (
        <div className='flex items-center' />
      )}
      <div className='flex items-center'>
        <Calendar useContext={useDashboard} contextType='dashboard' disabled={isChartLoading} />
        {isWorkstation ? (
          <div className='mr-2'>
            <InputSelect
              items={workshops}
              onChange={(item) => setWorkshopSelected(item)}
              itemToString={(item) => item?.name}
              defaultValue={workshopSelected}
              disabled={isChartLoading}
            />
          </div>
        ) : (
          ''
        )}
        {contexts ? (
          <div className='mr-2'>
            <InputSelect
              items={contexts}
              onChange={(item) => setContextSelected(item)}
              itemToString={(item) => item?.name}
              defaultValue={contextSelected}
              disabled={isChartLoading}
            />
          </div>
        ) : (
          ''
        )}

        <InputSelect
          items={filteredDateOptions}
          onChange={(item) => setSelectedDateRange(item)}
          itemToString={(item) => formatItemToString(item)}
          defaultValue={selectedDateRange}
          disabled={isChartLoading}
        />
      </div>
    </div>
  );
};
