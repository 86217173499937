import Konva from 'konva';

export const base64ToBlobURL = (base64String) => {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpg' });

  return URL.createObjectURL(blob);
};

export const captureSnapshot = (videoRef) => {
  const canvas = document.createElement('canvas');
  canvas.width = videoRef.current.videoWidth;
  canvas.height = videoRef.current.videoHeight;
  const context = canvas.getContext('2d');
  context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL('image/png');
};

export const getImageCoordinates = (e) => {
  const rect = e.target.getBoundingClientRect();
  const x = e.clientX - rect.left;
  const y = e.clientY - rect.top;
  return { x, y };
};

export const getImageNativeCoordinates = (displayPoint, cameraDimensions, displayDimensions) => {
  const displayRatio = cameraDimensions.width / displayDimensions.width;
  return { x: displayPoint.x * displayRatio, y: displayPoint.y * displayRatio };
};

export const getImageDisplayCoordinates = (nativePoint, cameraDimensions, displayDimensions) => {
  const displayRatio = cameraDimensions.width / displayDimensions.width;
  return { x: nativePoint.x / displayRatio, y: nativePoint.y / displayRatio };
};

export const getImageData = (layer, imageDimensions) => {
  const context = layer.getContext();
  return context.getImageData(0, 0, imageDimensions.width, imageDimensions.height);
};

export const createImageCopy = (originalImageData) => {
  return new ImageData(
    new Uint8ClampedArray(originalImageData.data),
    originalImageData.width,
    originalImageData.height,
  );
};

export const createKonvaLayerFromImage = (image, width, height) => {
  const stage = new Konva.Stage({
    container: document.createElement('div'),
    width: width,
    height: height,
  });

  const layer = new Konva.Layer();
  layer.getCanvas().setPixelRatio(1);

  const imageKonva = new Konva.Image({
    image: image,
    width: width,
    height: height,
  });

  layer.add(imageKonva);
  stage.add(layer);
  layer.draw();

  return layer;
};

export const getMasksNumber = (imageData) => {
  const data = imageData.data;
  let maxValue = 0;
  for (let i = 0; i < data.length; i += 4) {
    if (data[i] > maxValue) maxValue = data[i];
  }
  return maxValue;
};

export const getTargetMaskIndex = (imageData, pointerPosition) => {
  const { x, y } = pointerPosition;
  const pixelIndex = (Math.floor(y) * imageData.width + Math.floor(x)) * 4;
  return imageData.data[pixelIndex];
};

export const drawMask = (maskImageData, maskIndex, color) => {
  const data = maskImageData.data;

  for (let i = 0; i < data.length; i += 4) {
    const currentMaskIndex = data[i];

    // Mask 0 = no object detected
    if (currentMaskIndex !== 0 && currentMaskIndex === maskIndex) {
      data[i] = color[0];
      data[i + 1] = color[1];
      data[i + 2] = color[2];
    } else {
      data[i + 3] = 0;
    }
  }
};

export const createMaskImageURL = (maskImageData) => {
  const newCanvas = document.createElement('canvas');
  newCanvas.width = maskImageData.width;
  newCanvas.height = maskImageData.height;
  const newContext = newCanvas.getContext('2d');
  newContext.putImageData(maskImageData, 0, 0);
  return newCanvas.toDataURL();
};

export const applyBlur = (context, x, y, width, height, ref) => {
  context.save();

  context.filter = 'blur(10px)';

  context.drawImage(ref.current, x, y, width, height, x, y, width, height);

  context.restore();
};

export const getImageDataUrl = async (imgUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = imgUrl;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };

    img.onerror = (error) => {
      reject(error);
    };
  });
};
