import moment from 'moment';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Panel } from '../../../../components/panels/Panel';
import { Tabs } from '../../../../components/tabs/Tabs';
import { getLastUpdateDateFromEventTypes } from '../../../../services/entity/event-type/event-type-service';
import { DocumentTextIcon } from '@heroicons/react/20/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData } from '../../../../services/api/api-tools';
import { InformationCircleIcon, ViewfinderCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Modal } from '../../../../components/modals/Modal';
import { EventsTable } from './EventsTable';
import { Loader } from '../../../../components/loading/Loader';
import { isTeamUser } from '../../../../services/entity/user/user-service';
import { useUser } from '../../../../contexts/UserContext';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';

export const MainSetupPanel = ({ eventTypes, devices, handleOpenImportModal }) => {
  const { subtype } = useParams();

  const [activeTab, setActiveTab] = useState(null);

  const [specificationVersions, setSpecificationVersions] = useState(null);
  const [isModalVersionOpen, setIsModalVersionOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalVersionOpen(true);
  };

  // When the camera tab changes, we retrieve the versions of the specifications sheet associated with it.
  useEffect(() => {
    const getSpecificationVersion = async () => {
      const deviceId = devices.find((device) => device.name === activeTab).id;
      const versions = await fetchData(`devices/${deviceId}/versions`);
      if (versions) {
        setSpecificationVersions(versions);
      }
    };

    if (activeTab) {
      getSpecificationVersion();
    }
  }, [activeTab]);

  // Allows the user to be redirected to the camera tab that the event clicked on in the log panel belongs to.
  useEffect(() => {
    if (subtype) {
      setActiveTab(eventTypes.filter((eventType) => parseInt(eventType.subtype) === parseInt(subtype))[0].device.name);
    }
  }, [subtype]);

  useEffect(() => {
    if (devices?.length) {
      setActiveTab(devices[0].name);
    }
  }, [devices]);

  return (
    <div className='relative flex-1 h-full transition-all duration-500'>
      <div className='flex flex-col w-full h-full overflow-hidden'>
        <Panel category='main' title='VIEW'>
          {devices ? (
            devices.length ? (
              <CameraEventMenu
                eventTypes={eventTypes}
                devices={devices}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                specificationVersions={specificationVersions}
                handleOpenModal={handleOpenModal}
                handleOpenImportModal={handleOpenImportModal}
              />
            ) : (
              <div className='flex flex-col items-center justify-center h-full text-perception-gray-200 text-md'>
                <ViewfinderCircleIcon className='w-10 h-10 text-perception-gray-500' />
                <span className='mt-2 text-xl text-perception-gray-500'>No devices found</span>
              </div>
            )
          ) : (
            <Loader category='transparent' />
          )}
        </Panel>
      </div>
      {isModalVersionOpen ? (
        <ModalSpecificationVersion
          isModalOpen={isModalVersionOpen}
          setIsModalOpen={setIsModalVersionOpen}
          specificationVersions={specificationVersions}
        />
      ) : null}
    </div>
  );
};

const CameraEventMenu = ({
  eventTypes,
  devices,
  activeTab,
  setActiveTab,
  specificationVersions,
  handleOpenModal,
  handleOpenImportModal,
}) => {
  const navigate = useNavigate();
  const { user } = useUser();

  const activeEventTypes = eventTypes.filter((eventType) => eventType.device?.name === activeTab);

  const lastUpdateEventsDate = getLastUpdateDateFromEventTypes(activeEventTypes);

  const lastSpecificationVersion = useMemo(() => {
    if (specificationVersions?.length) {
      return specificationVersions[specificationVersions.length - 1];
    }
  }, [specificationVersions]);

  const redirectionToSpecification = () => {
    const activeDeviceId = devices.find((device) => device.name === activeTab).id;
    navigate(`/specification/${activeDeviceId}`);
  };

  return (
    <Fragment>
      <Tabs tabs={devices} activeTab={activeTab} setActiveTab={setActiveTab} />
      <div className='flex flex-col w-full h-full'>
        {eventTypes.length > 0 ? (
          <>
            <div className='flex justify-between w-full mb-4'>
              <div className='flex flex-col items-start'>
                <h3 className='mt-5 text-base font-bold'>{activeEventTypes?.length} Events</h3>
                <h3 className='mt-2 text-sm'>Last events update : {lastUpdateEventsDate.toLocaleDateString()}</h3>
              </div>
              <div className='flex items-center gap-4 mt-2'>
                {isTeamUser(user) ? (
                  <button
                    className='flex items-center p-2 text-sm font-bold border border-dashed cursor-pointer text-perception-blue border-1 border-perception-blue'
                    onClick={handleOpenImportModal}
                  >
                    <ArrowDownTrayIcon className='w-5 h-5 mr-2 text-perception-blue' />
                    Import
                  </button>
                ) : null}

                <button
                  className='flex items-center p-2 text-sm font-bold text-white cursor-pointer bg-perception-blue'
                  onClick={redirectionToSpecification}
                >
                  <DocumentTextIcon className='w-5 h-5 mr-2 text-white' />
                  Specifications
                </button>
                {specificationVersions && (
                  <div className='flex flex-col'>
                    <div className='flex items-start justify-between gap-2'>
                      <span className='text-xs'>
                        {moment.unix(lastSpecificationVersion?.date).format('YYYY-MM-DD')}
                      </span>
                      <InformationCircleIcon
                        className='w-4 h-4 cursor-pointer text-perception-gray-500'
                        onClick={handleOpenModal}
                      />
                    </div>
                    <span className='text-xs'>version {lastSpecificationVersion?.version}</span>
                  </div>
                )}
              </div>
            </div>
            <EventsTable activeEventTypes={activeEventTypes} />
          </>
        ) : (
          <div className='m-5 text-base text-center text-perception-gray-500'>
            No data found, please select a workstation
          </div>
        )}
      </div>
    </Fragment>
  );
};

const ModalSpecificationVersion = ({ isModalOpen, setIsModalOpen, specificationVersions }) => {
  return (
    <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen} category='secondary' size='large'>
      <div className='p-6 bg-white'>
        <h2 className='mb-4 text-xl font-bold text-gray-800'>Versions History</h2>
        <div className='overflow-x-auto'>
          <table className='min-w-full'>
            <thead>
              <tr className='bg-gray-100'>
                <th className='px-4 py-2 text-sm font-semibold text-gray-600 border-b border-gray-200'>Date</th>
                <th className='px-4 py-2 text-sm font-semibold text-gray-600 border-b border-gray-200'>Version</th>
              </tr>
            </thead>
            <tbody>
              {specificationVersions.map(({ id, date, version }) => (
                <tr key={id} className='border-b border-gray-200'>
                  <td className='px-4 py-3 text-gray-800'>{moment.unix(date).format('YYYY-MM-DD')}</td>
                  <td className='px-4 py-3 text-gray-800'>{version}.0</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <XMarkIcon
          className='absolute w-6 h-6 text-black cursor-pointer right-5 top-5'
          onClick={() => setIsModalOpen(false)}
        />
      </div>
    </Modal>
  );
};
