import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUser } from '../../../contexts/UserContext';
import { Button } from '../../buttons/Button';
import { InputWithError } from '../../forms/inputs/InputWithError';
import { postUserRegister } from '../../../services/entity/user/user-api';
import { postUserLogin } from '../../../services/entity/user/user-api';
import { HeadForm } from '../AuthForm';
import { InputSelect } from '../../forms/inputs/InputSelect';
import { USER_FUNCTIONS } from '../../../temp/const';
import { useLocation } from 'react-router-dom';
import { generateRandomString } from '../../../helpers/common/generateRandomString';
import { GoogleLoginButton } from '../../buttons/GoogleLoginButton';

export const SignupForm = ({ setIsAuth, setIsFormLoading, organization }) => {
  const url = useLocation();

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors,
    watch,
    setError,
  } = useForm();

  const { updateUser } = useUser();

  const [selectedFunction, setSelectedFunction] = useState(null);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const password = watch('password', '');

  const randomStreamId = generateRandomString(5);

  const validateEmail = (value) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(value) || 'Invalid email format';
  };

  const validatePassword = (value) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return (
      regex.test(value) ||
      'Password must contain at least 8 characters, including a number, an uppercase and a lowercase letter'
    );
  };

  const handleSignUp = async (data) => {
    if (!isTermsAccepted) {
      setError('terms', {
        type: 'manual',
        message: 'You must accept the terms and conditions',
      });
      return false;
    }

    try {
      setIsFormLoading(true);
      const dataUser = {
        email: data.email,
        password: data.password,
        stream_id: randomStreamId,
        job: selectedFunction.name,
        organization_id: organization?.id,
      };
      const response = await postUserRegister(dataUser);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setError('email', {
          type: 'manual',
          message: 'There is already an account with this email',
        });
      }
      return false;
    } finally {
      setIsFormLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const response = await handleSignUp(data);
    if (response) {
      let userData = await postUserLogin({
        email: data.email,
        password: data.password,
      });
      updateUser(userData);
    }
  };

  const getHeadFormText = () => {
    if (url.pathname.includes('register')) {
      return `Create an account to join ${organization?.name} and use Lineware AI.`;
    } else {
      return 'Create an account to get the free trial version of Lineware AI and create something new';
    }
  };

  return (
    <div className='w-full'>
      <HeadForm title='Lineware Free Account' text={getHeadFormText()} />
      <form className='flex flex-col justify-start mt-4 item-center' onSubmit={handleSubmit(onSubmit)}>
        <InputWithError
          name='email'
          type='email'
          placeholder='email'
          register={register}
          errors={errors}
          fieldName='email'
          autoComplete='email'
          validate={validateEmail}
        />
        <InputWithError
          name='password'
          type='password'
          placeholder='password'
          register={register}
          errors={errors}
          fieldName='password'
          autoComplete='new-password'
          validate={validatePassword}
        />
        <InputWithError
          name='confirmPassword'
          type='password'
          placeholder='confirm password'
          register={register}
          errors={errors}
          fieldName='confirmPassword'
          autoComplete='off'
          validate={(value) => value === password || 'The passwords do not match'}
        />
        <div id='select-signup-form'>
          <InputSelect
            items={USER_FUNCTIONS}
            onChange={setSelectedFunction}
            itemToString={(item) => (item ? item.name : '')}
            placeholder='Select a function'
            readOnly
          />
        </div>
        <TermsAndConditions
          termsAccepted={isTermsAccepted}
          setTermsAccepted={setIsTermsAccepted}
          clearErrors={clearErrors}
          errors={errors}
        />
        <span
          className='w-full mt-6 text-xs text-center cursor-pointer text-perception-blue'
          onClick={() => setIsAuth(true)}
        >
          Already have an account?
        </span>
        <div className='flex flex-col items-center justify-center mt-2'>
          <Button type='submit' category='primary-btn' size='medium'>
            Sign Up
          </Button>
          <GoogleLoginButton
            streamId={randomStreamId}
            setIsFormLoading={setIsFormLoading}
            organization={organization}
          />
        </div>
      </form>
    </div>
  );
};

const TermsAndConditions = ({ termsAccepted, setTermsAccepted, clearErrors, errors }) => (
  <div className='flex flex-col justify-start m-2'>
    {errors.terms && <span className='text-sm text-perception-error-800'>please accept privacy policy</span>}
    <label className='flex items-center mt-1' htmlFor='terms'>
      <input
        id='terms'
        type='checkbox'
        className='cursor-pointer'
        checked={termsAccepted}
        onChange={() => {
          clearErrors('terms');
          setTermsAccepted(!termsAccepted);
        }}
      />
      <p className='pl-2 text-sm'>I agree with the Terms & Conditions of Lineware AI</p>
    </label>
  </div>
);
