import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalPrivacy } from './ModalPrivacy';
import { SourceVideo } from './SourceVideo';
import { SourcePanel } from './SourcePanel';
import { useTrialSetupContext } from '../../../../contexts/TrialSetupContext';

export const SetupSource = ({ setStep }) => {
  const { setIsCameraReady } = useTrialSetupContext();

  const [isVideoAnalysis, setIsVideoAnalysis] = useState(false);

  const [isPrivacyPolicyOpened, setIsPrivacyPolicyOpened] = useState(true);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showCheckLogo, setShowCheckLogo] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (isVideoAnalysis) {
      setShowSpinner(true);
      setTimeout(() => {
        setShowSpinner(false);
        setShowCheckLogo(true);
        setIsCameraReady(false);
        setTimeout(() => {
          setShowCheckLogo(false);
          navigate('/demo/live');
        }, 2000);
      }, 5000);
    }
  }, [navigate, isVideoAnalysis, setStep]);

  return (
    <div id='setup-source-autosetup' className='flex flex-col justify-start items-start w-full'>
      <div className='flex w-full justify-start'>
        <SourceVideo isVideoAnalysis={isVideoAnalysis} showSpinner={showSpinner} showCheckLogo={showCheckLogo} />
        <SourcePanel
          isVideoAnalysis={isVideoAnalysis}
          setIsVideoAnalysis={setIsVideoAnalysis}
          setStep={setStep}
          isPrivacyPolicyAccepted={isPrivacyPolicyAccepted}
        />
      </div>
      <div className='flex justify-start relative w-full mt-4'>
        {isPrivacyPolicyOpened && (
          <ModalPrivacy
            isPrivacyPolicyOpened={isPrivacyPolicyOpened}
            setIsPrivacyPolicyOpened={setIsPrivacyPolicyOpened}
            setIsPrivacyPolicyAccepted={setIsPrivacyPolicyAccepted}
            setStep={setStep}
          />
        )}
      </div>
    </div>
  );
};
