import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { Loader } from '../loading/Loader';

export const ProtectedRoute = ({ children, requiredRole }) => {
  const { user, isUserDataLoading } = useUser();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(null);

  useEffect(() => {
    if (!isUserDataLoading) {
      const hasRequiredRole = user?.roles.includes(requiredRole);
      setIsAuthorized(hasRequiredRole);
    }
  }, [user, isUserDataLoading, requiredRole]);

  if (isUserDataLoading) {
    return <Loader />;
  }

  if (isAuthorized === false) {
    return <Navigate to='/prod' state={{ from: location }} />;
  }

  if (isAuthorized) {
    return children;
  }

  return null;
};
