export const HoverTextButton = ({ onClick, disabled, children }) => {
  if (disabled) return null;
  return (
    <div className={`flex justify-start items-center cursor-pointer invisible group-hover/btn:visible`}>
      <div
        className='flex item-center text-xs font-semibold uppercase cursor-pointer text-perception-gray-500'
        onClick={onClick}
      >
        {children}
      </div>
    </div>
  );
};
