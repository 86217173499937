import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { useUser } from '../../../contexts/UserContext';
import { getSettingsNavigationMenu } from '../../../helpers/routing/routing-helper';
import { handleClassNames } from '../../../helpers/common/handleClassNames';

export const ProfileMenuDropdown = () => {
  const { logoutUser } = useUser();

  return (
    <Transition
      enter='transition ease-out duration-200'
      enterFrom='transform opacity-0 scale-95'
      enterTo='transform opacity-100 scale-100'
      leave='transition ease-in duration-75'
      leaveFrom='transform opacity-100 scale-100'
      leaveTo='transform opacity-0 scale-95'
      className='z-10 relative'
    >
      <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 z-20 shadow-lg py-1 bg-perception-black-800 focus:outline-none'>
        {getSettingsNavigationMenu().map((item) => (
          <Menu.Item key={item.name}>
            {({ active }) => (
              <Link
                to={item.href}
                className={handleClassNames(
                  active ? 'bg-perception-black-500' : '',
                  'px-4 py-2 text-sm text-perception-gray-500 flex items-center',
                )}
              >
                <span className='material-icons mr-2'>{item.icon}</span>
                <span>{item.name}</span>
              </Link>
            )}
          </Menu.Item>
        ))}
        {/* Logout button */}
        <Menu.Item key='logout'>
          {({ active }) => (
            <span
              onClick={() => logoutUser()}
              className={handleClassNames(
                active ? 'bg-perception-black-500' : '',
                'px-4 py-2 text-sm text-perception-gray-500 flex items-center cursor-pointer',
              )}
            >
              <span className='material-icons mr-2'>logout</span>
              <span>Logout</span>
            </span>
          )}
        </Menu.Item>
      </Menu.Items>
    </Transition>
  );
};
