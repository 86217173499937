import moment from 'moment';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useOrgData } from './OrgDataContext';
import { useSocket } from './SocketContext';
import {
  getAggregatedLogs,
  sortLogsByTime,
  filterArrayWithoutHumanLogs,
  filterArrayOnHumanLogs,
  extractAndFilterSubtypes,
} from '../services/log/log-service';
import { getLogs } from '../services/log/log-api';
import { useGetData } from '../services/api/api-tools';
import { detectPatternsInLogs } from '../services/entity/pattern/pattern-service';
import { convertCalibrationsToLogs, groupCalibrationsByDate } from '../services/entity/calibration/calibration-service';

export const optionAggregationDuration = {
  NONE: { id: 0, name: 'MINIMUM AGGREGATION', value: 600 },
  ONE_HOUR: { id: 1, name: '1 HOUR AGGREGATION', value: 3600 },
  ONE_DAY: { id: 2, name: '1 DAY AGGREGATION', value: 24 * 3600 },
};

export const optionFilterView = {
  DISPLAY_EVENTS_PATTERNS: { id: 0, name: 'DISPLAY EVENTS AND PATTERNS' },
  DISPLAY_PATTERNS: { id: 1, name: 'DISPLAY ONLY PATTERNS' },
  DISPLAY_ALL: { id: 2, name: 'DISPLAY ALL' },
};

export const DEFAULT_HOURS_TO_LOOK_BACK = 24;

const LogContext = createContext();

export const LogProvider = ({ children }) => {
  const { workstationSelected, eventTypes, calibrations, isWorkstationDataLoading } = useOrgData();
  const { socket, subscribeToEvent, unsubscribeFromEvent } = useSocket();

  // State flags
  const [isLogsProcessing, setIsLogsProcessing] = useState(false);
  const [isInitialLogsRequested, setIsInitialLogsRequested] = useState(false);
  const [isClientDateSetupExceeded, setIsClientDateSetupExceeded] = useState(false);
  const [isLogPanelFilled, setIsLogPanelFilled] = useState(false);

  // Logs cycle state
  const [rawLogs, setRawLogs] = useState([]);
  const [detectedPatterns, setDetectedPatterns] = useState(null);
  const [hoursToLookBack, setHoursToLookBack] = useState(DEFAULT_HOURS_TO_LOOK_BACK);

  // Filters state
  const [logsAggregationDuration, setLogsAggregationDuration] = useState(Object.keys(optionAggregationDuration)[0]);
  const [filterView, setFilterView] = useState(Object.keys(optionFilterView)[0]);
  const [dateRange, setDateRange] = useState({
    start: Date.now() - 24 * 60 * 60 * 1000,
    stop: Date.now(),
  });

  // Keep the reference to the last log date retrieved
  const [lastLogRetrievalDate, setLastLogRetrievalDate] = useState(Date.now());

  // Retrieve the available patterns for the selected machine
  const patternsQueryKey = `patterns_detection_workstation_${workstationSelected.id}`;
  const { data: patterns, refetch: refetchPatternsDetection } = useGetData(
    patternsQueryKey,
    `patterns?workstation.id=${workstationSelected.id}`,
    {},
    !!workstationSelected.id,
  );

  const getLogsByDateRange = async (startDate, stopDate, keepLogs = true) => {
    if (isWorkstationDataLoading) return;
    const subtypes = extractAndFilterSubtypes(eventTypes, workstationSelected);
    try {
      setIsLogsProcessing(true);
      const logs = await getLogs(subtypes, startDate, stopDate);
      setLastLogRetrievalDate(startDate);
      if (keepLogs) {
        setRawLogs((prevLogs) => [...prevLogs, ...logs]);
      } else {
        setRawLogs(logs);
      }
      if (logs?.length === 0) setIsLogsProcessing(false);
    } catch (error) {
      console.error('Error fetching logs:', error);
    } finally {
      setIsInitialLogsRequested(true);
    }
  };

  const updateRawLogs = (logs) => {
    setRawLogs((prevRawLogs) => [...prevRawLogs, ...logs]);
  };

  const handleResetFlags = () => {
    setHoursToLookBack(DEFAULT_HOURS_TO_LOOK_BACK);
    setIsClientDateSetupExceeded(false);
    setIsInitialLogsRequested(false);
  };

  // Retrieve the raw data when a machine or a date is selected / modified
  useEffect(() => {
    if (!dateRange?.start || !dateRange?.stop) return;
    if (!eventTypes?.length) return;

    getLogsByDateRange(dateRange.start, dateRange.stop, false);
  }, [dateRange, eventTypes, workstationSelected, isWorkstationDataLoading]);

  // Process the raw data by applying various filters (aggregation, view) and add high-level logs (calibrations, patterns)
  const aggregatedLogs = useMemo(() => {
    if (!rawLogs || !patterns || !isInitialLogsRequested) return [];

    setIsLogsProcessing(true);
    const rawLogsWithoutHumanLogs = filterArrayWithoutHumanLogs(rawLogs);

    const aggregatedLogsForPatterns = getAggregatedLogs(rawLogsWithoutHumanLogs, optionAggregationDuration.NONE.value);
    const sortedAggregatedLogs = [
      [
        ...getAggregatedLogs(rawLogsWithoutHumanLogs, optionAggregationDuration[logsAggregationDuration].value),
        ...filterArrayOnHumanLogs(rawLogs, filterView),
      ],
    ];

    const detectedPatterns = detectPatternsInLogs(aggregatedLogsForPatterns, patterns);
    setDetectedPatterns(detectedPatterns);

    const calibrationsLogsGroupedByDate = groupCalibrationsByDate(calibrations);
    const calibrationsLogs = convertCalibrationsToLogs(calibrationsLogsGroupedByDate);
    const combinedLogs = [...sortedAggregatedLogs, ...detectedPatterns, ...calibrationsLogs].flat();

    if (filterView === 'DISPLAY_PATTERNS') {
      setIsLogsProcessing(false);
      return sortLogsByTime(detectedPatterns);
    } else {
      setIsLogsProcessing(false);
      return sortLogsByTime(combinedLogs);
    }
  }, [rawLogs, filterView, logsAggregationDuration, patterns, calibrations, isInitialLogsRequested]);

  // Handle the reception of socket events (like receiving live logs)
  useEffect(() => {
    if (!socket || workstationSelected?.id === 0) return;

    const handleEvent = (data) => {
      console.info('[DEBUG] New event received:', data);
      data.time = moment.unix(data.time).toISOString();
      const subtypes = extractAndFilterSubtypes(eventTypes, workstationSelected);
      const isOperatorLog = data.log_id;
      if (subtypes.some((subtype) => parseInt(subtype) === parseInt(data.subtype))) {
        if (!isOperatorLog) {
          setIsLogsProcessing(true);
          setRawLogs((allLogs) => {
            return [...allLogs, data];
          });
        }
      }
    };

    console.info('[DEBUG] Subscribe to events');
    subscribeToEvent('event', handleEvent);

    return () => {
      unsubscribeFromEvent('event', handleEvent);
    };
  }, [socket, workstationSelected, eventTypes]);

  const contextValue = useMemo(
    () => ({
      hoursToLookBack,
      setHoursToLookBack,
      aggregatedLogs,
      logsAggregationDuration,
      setLogsAggregationDuration,
      filterView,
      setFilterView,
      detectedPatterns,
      isLogsProcessing,
      setIsLogsProcessing,
      lastLogRetrievalDate,
      setLastLogRetrievalDate,
      dateRange,
      setDateRange,
      isInitialLogsRequested,
      isClientDateSetupExceeded,
      setIsClientDateSetupExceeded,
      refetchPatternsDetection,
      getLogsByDateRange,
      isLogPanelFilled,
      setIsLogPanelFilled,
      updateRawLogs,
      handleResetFlags,
    }),
    [
      hoursToLookBack,
      setHoursToLookBack,
      aggregatedLogs,
      logsAggregationDuration,
      setLogsAggregationDuration,
      filterView,
      setFilterView,
      detectedPatterns,
      isLogsProcessing,
      setIsLogsProcessing,
      lastLogRetrievalDate,
      setLastLogRetrievalDate,
      dateRange,
      setDateRange,
      isInitialLogsRequested,
      isClientDateSetupExceeded,
      setIsClientDateSetupExceeded,
      refetchPatternsDetection,
      getLogsByDateRange,
      isLogPanelFilled,
      setIsLogPanelFilled,
      updateRawLogs,
      handleResetFlags,
    ],
  );

  return <LogContext.Provider value={contextValue}>{children}</LogContext.Provider>;
};

export const useLog = () => {
  return useContext(LogContext);
};
