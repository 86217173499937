import React from 'react';
import clsx from 'clsx';
import {
  InformationCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
  Cog8ToothIcon,
  ArrowPathIcon,
} from '@heroicons/react/24/outline';

export const Notification = ({ step, highestScorePattern, stepCategory, text }) => {
  // For upcoming logs, return their position in the total number of steps
  const getStepCount = (step, highestScorePattern) => {
    const patternEventTypes = highestScorePattern.pattern_event_types;
    const patternEventTypeStep = patternEventTypes.findIndex((patternEventType) => patternEventType.id === step.id);
    return `${patternEventTypeStep + 1}/${patternEventTypes.length}`;
  };

  const stepCount = highestScorePattern ? getStepCount(step, highestScorePattern) : null;

  const getNotificationStyle = () => {
    switch (stepCategory) {
      case 'in progress':
        return {
          backgroundColor: 'bg-perception-black-800',
          border: 'border-4 border-perception-success-300',
          textColor: 'text-white',
          iconColor: 'animate-spin text-perception-success-300',
          Icon: Cog8ToothIcon,
        };
      case 'on list':
        return {
          backgroundColor: 'bg-perception-black-500',
          textColor: 'text-white',
          iconColor: 'text-blue-500',
          Icon: () => (
            <div className='flex items-center mr-6 justify-center h-10 w-10 rounded-full border border-perception-blue text-perception-blue'>
              <span className='text-lg'>{stepCount}</span>
            </div>
          ),
        };
      case 'on waiting':
        return {
          backgroundColor: 'bg-transparent',
          border: 'border border-perception-warn-500',
          textColor: 'text-perception-warn-500',
          iconColor: 'animate-spin text-perception-warn-500',
          Icon: ArrowPathIcon,
        };
      case 'error':
        return {
          backgroundColor: 'bg-perception-black-800',
          border: 'border border-perception-error-800 border-2',
          textColor: 'text-white text-3xl',
          iconColor: 'text-red-500',
          Icon: XCircleIcon,
        };
      case 'warn':
        return {
          backgroundColor: 'bg-perception-black-800',
          border: 'border-orange-500',
          textColor: 'text-white text-3xl',
          iconColor: 'text-orange-500',
          Icon: ExclamationCircleIcon,
        };
      default:
        return {
          backgroundColor: 'bg-perception-black-800',
          textColor: 'text-white',
          iconColor: 'text-gray-500',
          Icon: InformationCircleIcon,
        };
    }
  };

  const { backgroundColor, border, textColor, iconColor, Icon } = getNotificationStyle();

  return (
    <div
      className={clsx(
        'p-4 h-full rounded-custom flex text-3xl tracking-wide font-semibold items-center',
        backgroundColor,
        border,
        textColor,
      )}
    >
      <Icon className={clsx('h-12 w-12 mr-6', iconColor)} />
      <p>{text}</p>
    </div>
  );
};
