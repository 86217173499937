import { Fragment, useEffect, useMemo, useState } from 'react';
import { Panel } from '../../../../../components/panels/Panel.js';
import { useVideoDeviceStream } from '../../../../../hooks/useVideoDeviceStream.js';
import { Checkbox } from '../../../../../components/forms/Checkbox.js';
import { Button } from '../../../../../components/buttons/Button.js';
import { useAlert } from '../../../../../contexts/AlertContext.js';
import { useNavigate } from 'react-router-dom';
import { patchData } from '../../../../../services/api/api-tools.js';
import { useResizeDetector } from 'react-resize-detector';
import { calculateDisplayStreamSizes } from '../../../../../services/engine/engine-service.js';
import { Loader } from '../../../../../components/loading/Loader.js';

const checkboxData = [
  {
    id: 'video-check',
    name: 'video-check',
    label: 'Video Functionality Check',
    description:
      'To ensure that the video functionality is working as expected, please tick this checkbox. Verify that the video stream displays the scene accurately and without any interruptions or glitches.',
  },
  {
    id: 'image-quality',
    name: 'image-quality',
    label: 'Image Quality Verification',
    description:
      'Ensure that the displayed image is clear, sharp, and free from any distortion or artifacts. Its important to guarantee that the camera captures high-quality images for optimal performance.',
  },
  {
    id: 'image-obstruction',
    name: 'image-obstruction',
    label: 'Obstruction Check',
    description:
      'Ensure that there are no objects such as carts or obstacles blocking the view of the target machine. Its crucial to have a clear line of sight for proper observation and monitoring.',
  },
  {
    id: 'scene-relevancy',
    name: 'scene-relevancy',
    label: 'Scene Highlighting Validation',
    description:
      'Verify that the video effectively distinguishes all elements necessary for detecting the desired action. Its important that the video provides clear visibility and differentiation of all relevant elements for accurate detection and analysis.',
  },
];

export const CameraSetup = ({ specification, refetchSpecification }) => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const { videoRef, frameSizes, isReceivingFrames } = useVideoDeviceStream(specification.device.stream_id);

  const { width, height, ref: imageContainerRef } = useResizeDetector();

  const [checkboxes, setCheckboxes] = useState(
    checkboxData.reduce((acc, item) => {
      acc[item.id] = false;
      return acc;
    }, {}),
  );

  const [allChecked, setAllChecked] = useState(false);

  // Allows to adjust the display size to keep the stream responsive.
  const displaySizes = useMemo(() => {
    const { width: frameWidth, height: frameHeight, ratio: frameRatio } = frameSizes;
    return calculateDisplayStreamSizes(frameWidth, frameHeight, frameRatio, width, height);
  }, [width, height, frameSizes, videoRef.current]);

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleClickNext = async () => {
    if (allChecked) {
      let response = await patchData(`specifications/${specification.id}`, { state: 2 });
      if (response.state) {
        refetchSpecification();
      } else {
        showAlert('warning', 'Oops! Something went wrong... ');
      }
    } else {
      showAlert(
        'warning',
        'Oops! You need to check all the items on the list to ensure your camera is properly set up. ',
      );
    }
  };

  const handleClickBack = () => {
    navigate('/setup');
  };

  useEffect(() => {
    setAllChecked(Object.values(checkboxes).every(Boolean));
  }, [checkboxes]);

  return (
    <Fragment>
      <div className='flex h-full w-2/6'>
        <div className='flex flex-col w-full h-full'>
          <div className='flex flex-col h-full border border-perception-blue'>
            <Panel title='GUIDELINE'>
              <div className='h-full flex flex-col justify-between px-4'>
                <fieldset className='mt-1 text-md font-semibold'>
                  <legend className='text-white'>
                    To proceed and ensure that the camera is properly set up, check all these checkboxes.
                  </legend>
                  <div className='space-y-8 mt-8'>
                    {checkboxData.map((item) => (
                      <Checkbox
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        label={item.label}
                        description={item.description}
                        checked={checkboxes[item.id]}
                        onChange={handleCheckboxChange}
                      />
                    ))}
                  </div>
                </fieldset>
                <div className='flex gap-4'>
                  <Button size='medium' category='tertiary-btn' onClick={handleClickNext}>
                    Next
                  </Button>
                  <Button size='medium' category='variant-btn' onClick={handleClickBack}>
                    Back to events
                  </Button>
                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
      <div className='flex-1'>
        <div className='flex flex-col w-full h-full overflow-hidden'>
          <Panel category='main' title='VIEW'>
            <div
              className='flex-auto flex flex-col justify-center items-center overflow-hidden'
              ref={imageContainerRef}
            >
              {!isReceivingFrames ? <Loader category='transparent' /> : null}
              <div style={displaySizes} className='relative'>
                <div className='h-full w-full relative'>
                  <canvas className='h-full w-full' ref={videoRef}></canvas>
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    </Fragment>
  );
};
