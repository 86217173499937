import { Chart, registerables } from 'chart.js';
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { useEffect, useRef, useState } from 'react';
import { getDefaultOptions } from '../../../../services/entity/chart/chart-service';
import { Loader } from '../../../../components/loading/Loader';
import { useNavigate } from 'react-router-dom';
import { optionDateRange } from '../../../../contexts/DashboardContext';
import { useOrgData } from '../../../../contexts/OrgDataContext';

Chart.register(...registerables);

function showCustomTooltip(text, x, y) {
  const tooltip = document.getElementById('tooltip') || createTooltip();
  tooltip.style.display = 'block';
  tooltip.style.left = `${x}px`;
  tooltip.style.top = `${y}px`;
  tooltip.textContent = text;

  function createTooltip() {
    const tooltipDiv = document.createElement('div');
    tooltipDiv.id = 'tooltip';
    tooltipDiv.style.position = 'absolute';
    tooltipDiv.style.padding = '5px';
    tooltipDiv.style.background = 'rgba(0, 0, 0, 0.75)';
    tooltipDiv.style.color = 'white';
    tooltipDiv.style.borderRadius = '4px';
    tooltipDiv.style.pointerEvents = 'none';
    document.body.appendChild(tooltipDiv);
    return tooltipDiv;
  }
}

// Plugin consumed by the graphs option that allows drawing on the graphs areas representing the custom data
const customDrawingOfPlugin = {
  id: 'drawOf',
  afterDraw: (chart) => {
    const ctx = chart.ctx;
    const xAxis = chart.scales['x'];
    const yAxis = chart.scales['y'];

    if (chart?.data?.datasets[0]?.extraData) {
      const minX = xAxis.getValueForPixel(xAxis.left);
      const maxX = xAxis.getValueForPixel(xAxis.right);

      chart.data.datasets[0].extraData.forEach((of) => {
        const start = of.date * 1000;
        const end = start + of.duration * 1000;

        // Checks if the custom data is within the time range of the chart.
        if (start >= minX || start <= maxX) {
          let xStart = xAxis.getPixelForValue(start);
          let xEnd = xAxis.getPixelForValue(end);

          xStart = Math.max(xStart, xAxis.left);
          xEnd = Math.min(xEnd, xAxis.right);

          if (xEnd < xStart) {
            xEnd = xStart;
          }

          const yTop = yAxis.getPixelForValue(yAxis.max);
          const yBottom = yAxis.getPixelForValue(yAxis.min);

          ctx.fillStyle = 'rgba(253, 253, 253, 0.1)';
          ctx.fillRect(xStart, yTop, xEnd - xStart, yBottom - yTop);

          // Check if the text can fit within the gray area
          const textWidth = ctx.measureText(of.name).width;
          const textX = xStart + 5;
          const textY = yTop + 10;

          if (textWidth + 5 < xEnd - xStart) {
            ctx.fillStyle = '#929292';
            ctx.font = '12px Arial';
            ctx.fillText(of.name, textX, textY);
          } else {
            chart.canvas.addEventListener('mousemove', (event) => {
              const rect = chart.canvas.getBoundingClientRect();
              const mouseX = event.clientX - rect.left;
              const mouseY = event.clientY - rect.top;

              if (mouseX >= xStart && mouseX <= xEnd && mouseY >= yTop && mouseY <= yBottom) {
                showCustomTooltip(of.name, mouseX + rect.left, mouseY + rect.top);
              } else {
                const tooltip = document.getElementById('tooltip');
                if (tooltip) tooltip.style.display = 'none';
              }
            });
          }
        }
      });
    }
  },
};

Chart.register(customDrawingOfPlugin);

// If 'minify' is true, it means we expect a minimized version of MainLineChartBlock with less information,
// such as the legend and additional functional aspects.
export const MainLineChartBlock = ({
  charts,
  graphsData,
  isLoading,
  selectedDateRange,
  setSelectedDateRange,
  minify = false,
}) => {
  const navigate = useNavigate();
  const chartRef = useRef();

  const { workstationSelected, workshopSelected } = useOrgData();

  // Options for the graph, depending on the selected date range
  const [options, setOptions] = useState(getDefaultOptions(selectedDateRange, minify));

  // Redirect to main Dashboard with right settings
  const handleClickMiniDashboard = () => {
    if (minify) {
      navigate(
        `/dashboard/workstation/${workstationSelected.id}/${workshopSelected.id}/${selectedDateRange.start}/${selectedDateRange.stop}`,
      );
    }
  };

  // This function is temporary; it enables temporal zoom upon clicking on the graph.
  const handleClickChart = (event) => {
    if (!minify) {
      const clickedPointOnChart = getElementAtEvent(chartRef.current, event);
      if (clickedPointOnChart.length) {
        const x = clickedPointOnChart[0].element.$context.parsed.x;
        const y = clickedPointOnChart[0].element.$context.parsed.y;
        if (y > 0) {
          const start = x;
          let stop;

          switch (selectedDateRange.id) {
            case 1:
              stop = start + 3 * 60 * 60 * 1000;
              break;
            case 2:
              stop = start + 24 * 60 * 60 * 1000;
              break;
            case 3:
              stop = start + 8 * 24 * 3600 * 1000;
              break;
            case 4:
              stop = start + 30 * 24 * 3600 * 1000;
              break;
            default:
              stop = start + 3 * 60 * 60 * 1000;
              break;
          }

          const customRange = optionDateRange.find((range) => range.name === 'CUSTOM');
          const startDateString = new Date(start).toLocaleString();
          const stopDateString = new Date(stop).toLocaleString();
          setSelectedDateRange({
            ...customRange,
            vs_string: `${startDateString} - ${stopDateString}`,
            start: start,
            stop: stop,
          });
        }
      }
    }
  };

  // Update graph options when 'selectedDateRange' changes
  useEffect(() => {
    if (selectedDateRange) {
      setOptions(getDefaultOptions(selectedDateRange, minify));
    }
  }, [selectedDateRange]);

  return (
    <div
      className={`flex items-center bg-perception-black-800 h-full ${minify ? 'px-2 pt-2' : 'px-4 pt-4'} relative ${minify && 'cursor-pointer'}`}
      onClick={handleClickMiniDashboard}
    >
      {isLoading ? (
        <Loader category='transparent' />
      ) : (
        <div className='w-full'>
          {charts.length ? (
            <Line
              ref={chartRef}
              onClick={handleClickChart}
              data={graphsData}
              height={minify ? 200 : 400}
              options={options}
            />
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};
