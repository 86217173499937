import { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { SideSetupPanel } from './components/setup/SideSetupPanel';
import { MainSetupPanel } from './components/setup/MainSetupPanel';
import { useOrgData } from '../../contexts/OrgDataContext';
import { ImportModal } from './components/setup/ImportModal';
import { useGetData } from '../../services/api/api-tools';

export const SetupPage = () => {
  const { workshopSelected, setWorkshopSelected, eventTypes } = useOrgData();

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const handleOpenImportModal = () => {
    setIsImportModalOpen(true);
  };

  const { data: devices, refetch } = useGetData(
    'devices',
    'devices',
    workshopSelected.id ? { 'workshop.id': workshopSelected.id } : {},
    !!workshopSelected.id,
  );

  useEffect(() => {
    if (workshopSelected.id) {
      refetch();
    }
  }, [workshopSelected, refetch]);

  return (
    <Fragment>
      <div className='flex h-full'>
        <div className='w-1/6'>
          <SideSetupPanel workshopSelected={workshopSelected} setWorkshopSelected={setWorkshopSelected} />
        </div>
        <div className='relative flex-1'>
          <MainSetupPanel eventTypes={eventTypes} devices={devices} handleOpenImportModal={handleOpenImportModal} />
        </div>
        {isImportModalOpen && (
          <ImportModal isOpen={isImportModalOpen} setIsOpen={setIsImportModalOpen} refetch={refetch} />
        )}
      </div>
    </Fragment>
  );
};
