import { useEffect, useState } from 'react';
import getNetInfo from 'netinfo';
import { SignalIcon, SignalSlashIcon } from '@heroicons/react/20/solid';

export const NetworkStatusIndicator = () => {
  const [networkStatusColor, setNetworkStatusColor] = useState('#777777');
  const [networkStatusSuccess, setNetworkStatusSuccess] = useState(true);
  const [networkStatusText, setNetworkStatusText] = useState('Analyzing internet connection...');

  const handleNetInfoChange = (newNetInfo) => {
    if (newNetInfo.rtt <= 100) {
      setNetworkStatusColor('#2CEAA8');
      setNetworkStatusSuccess(true);
      setNetworkStatusText('Good internet connection');
    } else if (newNetInfo.rtt <= 200) {
      setNetworkStatusColor('#ec6b22');
      setNetworkStatusSuccess(true);
      setNetworkStatusText('Average internet connection');
    } else if (newNetInfo.rtt <= 250) {
      setNetworkStatusColor('#F9224B');
      setNetworkStatusSuccess(true);
      setNetworkStatusText('Bad internet connection');
    } else {
      setNetworkStatusColor('#F9224B');
      setNetworkStatusSuccess(false);
      setNetworkStatusText('No internet connection');
    }
  };

  useEffect(() => {
    const currentNetworkInfo = getNetInfo();
    currentNetworkInfo.addChangeListener(handleNetInfoChange);

    setTimeout(() => handleNetInfoChange(currentNetworkInfo), 3000);

    return () => currentNetworkInfo.removeChangeListener(handleNetInfoChange);
  }, []);

  return (
    <div className='flex items-center group cursor-pointer relative mr-3'>
      {networkStatusSuccess ? (
        <SignalIcon className='h-5 w-5 transition-all duration-300' style={{ color: networkStatusColor }} />
      ) : (
        <SignalSlashIcon className='h-5 w-5 transition-all duration-300' style={{ color: networkStatusColor }} />
      )}
      <span className='absolute right-0 top-100 mb-1 w-max p-2 text-xs text-white bg-black rounded hidden group-hover:block'>
        {networkStatusText}
      </span>
    </div>
  );
};
