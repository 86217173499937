import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getImageDisplayCoordinates } from '../../../../../../helpers/image/image-helper';
import { patchData, postData } from '../../../../../../services/api/api-tools';
import { Input } from '../../../../../../components/forms/inputs/Input';
import { Button } from '../../../../../../components/buttons/Button';
import { ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';
import { useQueryClient } from 'react-query';
import Tooltip from '../panels/SegmentPanel';

export const AnnotationModal = ({ frameSizes, displaySizes, isViewMode, handleFixError }) => {
  const {
    clickedPixel,
    setClickedPixel,
    markerHovered,
    setIsMarkerSelected,
    setMarkerHovered,
    selectedFrame,
    activeMarker,
    annotationQueryKey,
  } = useAnnotation();

  let coordinates;

  const queryClient = useQueryClient();

  if (clickedPixel) {
    coordinates = getImageDisplayCoordinates(clickedPixel.position, frameSizes, displaySizes);
  } else if (markerHovered) {
    coordinates = getImageDisplayCoordinates(markerHovered.position, frameSizes, displaySizes);
  }

  const modalRef = useRef(null);

  const [modalTop, setModalTop] = useState(0);
  const [modalLeft, setModalLeft] = useState(0);

  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');

  const [isReadOnly, setIsReadOnly] = useState(!!activeMarker);

  const isMarkerOnError = markerHovered?.specification_error?.is_error_reviewed === false;
  const error = markerHovered?.specification_error;
  const isAnnotationOnEdition = markerHovered;

  const closeModal = () => {
    setClickedPixel(null);
    setIsMarkerSelected(false);
    setMarkerHovered(null);
    setIsReadOnly(!!activeMarker);
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsReadOnly(false);
  };

  // SUBMIT MODAL
  const handleSubmit = async (e) => {
    e.preventDefault();

    const clearFormAndCloseModal = () => {
      setTitle('');
      setComment('');
      closeModal();
    };

    const payload = {
      title,
      content: comment,
      ...(isAnnotationOnEdition
        ? {}
        : {
            position: clickedPixel.position,
            specificationFrame: `/api/specification_frames/${selectedFrame.id}`,
          }),
    };

    try {
      if (isAnnotationOnEdition) {
        const response = await patchData(`specification_annotations/${markerHovered.id}`, payload);
        if (response) {
          queryClient.setQueryData(annotationQueryKey, (oldData) => {
            if (oldData && oldData.length > 0) {
              return oldData.map((annotation) => {
                if (annotation.id === markerHovered.id) {
                  return {
                    ...annotation,
                    title: title,
                    content: comment,
                    specification_error: isMarkerOnError
                      ? { ...annotation.specification_error, is_error_reviewed: true }
                      : annotation.specification_error,
                  };
                } else {
                  return annotation;
                }
              });
            } else {
              return oldData;
            }
          });

          if (isMarkerOnError) {
            const response = await patchData(`specification_errors/${error.id}`, { is_error_reviewed: true });
            if (response) {
              handleFixError(markerHovered.specification_error.id);
            }
          }
        }
      } else {
        const response = await postData('specification_annotations', payload);
        if (!response) {
          throw new Error('Erreur lors de la soumission du commentaire');
        }
        queryClient.setQueryData(annotationQueryKey, (oldData) => {
          if (oldData && oldData.length > 0) {
            return [...oldData, response];
          } else {
            return [response];
          }
        });
      }
      clearFormAndCloseModal();
    } catch (error) {
      console.error('Une erreur est survenue :', error);
    }
  };

  // Properly place the modal by avoiding it touching the edge of the parent.
  useLayoutEffect(() => {
    if (modalRef.current) {
      const height = modalRef.current.offsetHeight;

      let top = coordinates.y - height;
      let left = coordinates.x + 10;
      let alternativeTop = coordinates.y + 10;
      let alternativeLeft = coordinates.x - 500 - 10;

      // Check if the modal touches the right edge
      if (left + 500 > displaySizes.width) {
        left = alternativeLeft;
      }

      // Check if the modal touches both the right and top edges
      if (left + 500 > displaySizes.width && top < 0) {
        left = alternativeLeft;
        top = alternativeTop;
      }

      setModalTop(top < 0 ? alternativeTop : top);
      setModalLeft(left);
    }
  }, [coordinates, displaySizes.width]);

  useEffect(() => {
    if (markerHovered) {
      setTitle(markerHovered.title);
      setComment(markerHovered.content);
    }
  }, [markerHovered]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div
      ref={modalRef}
      className='w-[500px] absolute z-50'
      style={{
        top: modalTop,
        left: modalLeft,
      }}
    >
      <form onSubmit={handleSubmit}>
        <div
          className={`relative overflow-hidden shadow-sm ring-1 ring-inset ${isMarkerOnError ? 'ring-perception-warn-500' : 'ring-perception-blue'}  bg-perception-black-800`}
        >
          <div className='flex flex-col w-full h-full p-4'>
            <div className='w-1/2'>
              <Input
                category='secondary'
                placeholder='EX: COMPUTER'
                className={`${isReadOnly ? 'pointer-events-none' : ''} uppercase`}
                style={{ margin: 0 }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                readOnly={isReadOnly}
              />
            </div>

            <label htmlFor='comment' className='sr-only'>
              Comments
            </label>
            <textarea
              rows={3}
              name='comment'
              id='comment'
              className={`block py-2 px-2 mt-2 h-32 resize-none ${isReadOnly ? 'pointer-events-none' : ''} border-0 ring-1 ${isMarkerOnError ? 'ring-perception-warn-500/50' : 'ring-perception-blue/50'} focus:border-transparent focus:outline-none bg-transparent text-white placeholder:text-gray-400 text-sm sm:leading-6`}
              placeholder='Ex: This computer allows for the launch of the machine production.'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              readOnly={isReadOnly}
            />
            <div className='flex justify-end pt-2'>
              {!isViewMode ? (
                <div className='flex-shrink-0'>
                  {isReadOnly ? (
                    <Button category='variant-btn' size='xs' onClick={handleEditClick} type='button'>
                      EDIT
                    </Button>
                  ) : (
                    <Button category='tertiary-btn' size='xs' type='submit'>
                      SAVE
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className='absolute flex items-center gap-2 top-4 right-4'>
            {error ? (
              <Tooltip error={error}>
                <ExclamationCircleIcon className='w-6 h-6 ml-4 cursor-pointer text-perception-warn-500' />
              </Tooltip>
            ) : null}
            <XMarkIcon className='w-6 h-6 text-white cursor-pointer ' onClick={closeModal} />
          </div>
        </div>
      </form>
    </div>
  );
};
