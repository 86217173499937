import { useState } from 'react';

export const Tooltip = ({ text, textHover, icon, position = 'left' }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  let tooltipStyles = {
    top: '-100%',
    left: '0',
  };

  switch (position) {
    case 'right':
      tooltipStyles = {
        ...tooltipStyles,
        left: 'auto',
        right: '0',
      };
      break;
    case 'middle':
      tooltipStyles = {
        ...tooltipStyles,
        left: '50%',
        transform: 'translateX(-50%)',
      };
      break;
    case 'left':
      tooltipStyles = {
        ...tooltipStyles,
        left: '0',
        right: 'auto',
      };
      break;
    default:
      break;
  }

  return (
    <div className='relative inline-block'>
      <span
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
        className='cursor-pointer'
      >
        {textHover ? <p className='inline-block text-xs'>{textHover}</p> : null}
        {icon ? <img src={icon} alt='Tooltip Icon' className='w-5 h-5 inline-block mr-2' /> : null}
      </span>
      {isTooltipVisible && (
        <div
          className='absolute z-90 p-2 -mt-8 text-xs bg-white border border-gray-300 rounded shadow-md w-[fit-content] min-w-[150px]'
          style={tooltipStyles}
        >
          {text}
        </div>
      )}
    </div>
  );
};
