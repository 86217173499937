import { useEffect, useState } from 'react';
import { useDashboard } from '../../../contexts/DashboardContext';
import { getChartsPromises } from '../../../services/entity/chart/chart-api';
import { Loader } from '../../../components/loading/Loader';
import { GenericChart } from './charts/GenericChart';

export const SummaryStats = ({ summaryCharts }) => {
  const { selectedDateRange } = useDashboard();

  const [chartsData, setChartsData] = useState([]);
  const [isDataProcessing, setIsDataProcessing] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (summaryCharts.length === 0) {
        setChartsData([]);
        setIsDataProcessing(false);
        return;
      }

      setIsDataProcessing(true);

      // Warnings about chart data
      if (summaryCharts.length > 0 && !summaryCharts[0].type.startsWith('value'))
        console.warn('The summary chart should be of type "value".');

      const resolvedData = await getChartsPromises(summaryCharts, selectedDateRange, true);

      setChartsData(resolvedData);
      setIsDataProcessing(false);
    };

    fetchData();
  }, [summaryCharts, selectedDateRange]);

  return (
    <div className='bg-perception-black-800 text-white flex flex-col relative'>
      <div className='border-b border-perception-black-900 p-4'>
        <span className='font-semibold'>Summary /</span>
        <span className='text-perception-gray-500 ml-1'>{selectedDateRange.vs_string}</span>
      </div>
      <div className='flex items-center relative h-[76px]'>
        {isDataProcessing ? (
          <Loader category='transparent' />
        ) : (
          <>
            {chartsData.map((chartData) => {
              return <GenericChart key={chartData.id} type='summary' chartData={chartData} />;
            })}
            {chartsData.length === 0 && <p className='ml-4 text-perception-gray-600'>No data registered yet</p>}
          </>
        )}
      </div>
    </div>
  );
};
