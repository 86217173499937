export const calculateDisplayStreamSizes = (frameWidth, frameHeight, frameRatio, width, height) => {
  const MARGINS = 0.1;

  if (frameWidth > 0 && frameHeight > 0 && width && height) {
    const maxDisplayDimensions = {
      width: width * (1 - MARGINS * 2),
      height: height * (1 - MARGINS * 2),
    };

    // Adjust dimensions to maintain the aspect ratio of the frame
    if (maxDisplayDimensions.width / frameWidth < maxDisplayDimensions.height / frameHeight) {
      // If width-constrained height is less than height-constrained width, adjust the height
      maxDisplayDimensions.height = maxDisplayDimensions.width / frameRatio;
    } else {
      // Otherwise, adjust the width
      maxDisplayDimensions.width = maxDisplayDimensions.height * frameRatio;
    }

    return maxDisplayDimensions;
  } else {
    return {
      width: 0,
      height: 0,
    };
  }
};
