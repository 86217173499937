import { useTrialLiveContext } from '../../../../../contexts/TrialLiveContext';
import { Panel } from '../../../../../components/panels/Panel';
import { ViewSnapshot } from './ViewSnapshot';
import { ViewCamera } from './ViewCamera';
import { ServiceStatusIndicator } from '../../../../../components/others/ServiceStatusIndicator';
import { useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { NetworkStatusIndicator } from '../../../../../components/others/NetworkStatusIndicator';
import { useTrialSetupContext } from '../../../../../contexts/TrialSetupContext';
import { useOrgData } from '../../../../../contexts/OrgDataContext';

export const MainPanel = () => {
  const { workshops, setWorkshopSelected } = useOrgData();
  const { videoRef, isCameraReady } = useTrialSetupContext();
  const { setIsDefaultWorkshopSelected } = useTrialLiveContext();

  const { setCameras } = useTrialLiveContext();

  const [cameraDimensions, setCameraDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [displayDimensions, setDisplayDimensions] = useState({
    width: 0,
    height: 0,
  });
  const { width, height, ref: imageContainerRef } = useResizeDetector();

  useEffect(() => {
    if (!isCameraReady || !videoRef.current || !videoRef?.current?.srcObject) return;
    const {
      width: camWidth,
      height: camHeight,
      aspectRatio: camRatio,
    } = videoRef.current.srcObject.getVideoTracks()[0].getSettings();

    if (camWidth === undefined || camHeight === undefined || width === undefined || height === undefined) return;

    const MARGINS = 0.1;
    const maxDisplayDimensions = {
      width: width * (1 - MARGINS * 2),
      height: height * (1 - MARGINS * 2),
    };

    if (maxDisplayDimensions.width / camWidth < maxDisplayDimensions.height / camHeight)
      maxDisplayDimensions.height = maxDisplayDimensions.width / camRatio;
    else maxDisplayDimensions.width = maxDisplayDimensions.height * camRatio;

    setCameraDimensions({ width: camWidth, height: camHeight });
    setDisplayDimensions(maxDisplayDimensions);
  }, [width, height, isCameraReady, videoRef.current, videoRef?.current?.srcObject]);

  useEffect(() => {
    const getCameras = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        const cameraOptions = videoDevices.map((device) => ({
          id: device.deviceId,
          label: device.label,
        }));
        cameraOptions.push({ id: 'mobile-camera', label: 'Mobile camera' });
        setCameras(cameraOptions);
      } catch (error) {
        console.error('Error fetching video devices:', error);
      }
    };

    getCameras();
  }, []);

  useEffect(() => {
    if (workshops?.length) {
      console.log('[DEBUG] Workshops', workshops);
      const workshopWithMinId = workshops.reduce((min, current) => (current.id < min.id ? current : min), workshops[0]);
      console.log('[DEBUG] Selected workshop', workshopWithMinId);
      setWorkshopSelected(workshopWithMinId);
      setIsDefaultWorkshopSelected(true);
    }
  }, [workshops]);

  return (
    <Panel category='main' title='VIEW'>
      <div className='flex justify-between flex-none'>
        <div className='flex items-center'>
          <NetworkStatusIndicator />
          <ServiceStatusIndicator view='setup' />
        </div>
      </div>
      <div className='flex items-center justify-center flex-auto' ref={imageContainerRef}>
        <div
          style={{
            width: displayDimensions.width,
            height: displayDimensions.height,
          }}
        >
          <ViewSnapshot cameraDimensions={cameraDimensions} displayDimensions={displayDimensions} />
          <ViewCamera videoRef={videoRef} />
        </div>
      </div>
    </Panel>
  );
};
