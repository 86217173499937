import axios from 'axios';
import { fetchData, patchData, postData } from '../../api/api-tools';

export const createPattern = async (patterns, workstation) => {
  try {
    const body = {
      name: `NEW PATTERN ${patterns.length + 1}`,
      is_active: false,
      last_modified: new Date().toISOString(),
      commentary: '',
      pattern_event_types: [],
      workstation_id: workstation.id,
    };

    const response = await postData('patterns', body);
    if (response) return response;
  } catch (error) {
    console.error('Error creating new pattern on database', error);
    return null;
  }
};

export const updatePattern = async (pattern, patternId) => {
  try {
    const response = await patchData(`patterns/${patternId}`, pattern);
    if (response) return response;
  } catch (error) {
    console.error('Error saving pattern on database', error);
    return null;
  }
};

export const getAllPatterns = async () => {
  try {
    const response = await fetchData('patterns');
    if (response) return response;
  } catch (error) {
    console.error('Error fetching patterns from database', error);
    return null;
  }
};

// Find patterns with associated scores by sending a request to the pattern scoring service
// and returns the patterns with their scores.
export const findLogPatternsWithScore = async (logs, possiblePatterns) => {
  try {
    const body = {
      logs: logs,
      patterns: possiblePatterns,
    };

    const response = await axios.post(
      `${process.env.REACT_APP_ADDRESS}${process.env.REACT_APP_PATTERNS_COMPUTE_SCORES_URI} `,
      body,
    );

    return response.data.data;
  } catch (error) {
    throw new Error('Error contacting the pattern score service: ' + error.message);
  }
};
