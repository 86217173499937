import { GoogleLogin } from '@react-oauth/google';
import { useUser } from '../../contexts/UserContext';
import { googleAuthUser } from '../../services/entity/user/user-api';

export const GoogleLoginButton = ({ streamId, setIsFormLoading, organization }) => {
  const { updateUser } = useUser();

  const organizationId = organization?.id;

  return (
    <div className='mt-2'>
      <GoogleLogin
        onSuccess={(credentialResponse) =>
          googleAuthUser(credentialResponse.credential, streamId, setIsFormLoading, organizationId).then((response) => {
            if (response) {
              updateUser(response);
            }
          })
        }
        type='standard'
        text='signup_with'
        size='large'
      />
    </div>
  );
};

export const GoogleLoginButtonTemplate = () => {
  return (
    <div className='mt-2'>
      <GoogleLogin type='standard' text='signup_with' size='large' />
    </div>
  );
};
