import { useState, useRef } from 'react';
import { useAlert } from '../../contexts/AlertContext';
import { CalendarDaysIcon } from '@heroicons/react/20/solid';
import { Modal } from '../modals/Modal';
import { Button } from '../buttons/Button';
import { optionDateRange } from '../../contexts/DashboardContext';

export const Calendar = ({ useContext, contextType, disabled = false }) => {
  const context = useContext();
  const { showAlert } = useAlert();
  const [calendarStartDate, SetCalendarStartDate] = useState('');
  const [calendarStopDate, SetCalendarStopDate] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const calendarRef = useRef();

  let setDateRange, setSelectedDateRange;

  if (contextType === 'engine') {
    ({ setDateRange } = context);
  } else if (contextType === 'dashboard') {
    ({ setSelectedDateRange } = context);
  }

  const handleOpenModal = () => {
    if (!disabled) {
      setIsModalOpen(true);
    }
  };

  const handleStartDateChange = (event) => {
    SetCalendarStartDate(event.target.value);
  };

  const handleStopDateChange = (event) => {
    SetCalendarStopDate(event.target.value);
  };

  const handleDateSubmit = (event) => {
    event.preventDefault();
    const currentDate = new Date();
    const startDate = new Date(calendarStartDate);
    const midnightStartDate = new Date(startDate);
    midnightStartDate.setHours(0, 0, 0, 0);

    const stopDate = new Date(calendarStopDate);
    const endOfDayStopDate = new Date(stopDate);
    endOfDayStopDate.setHours(23, 59, 59, 999);

    if (startDate > stopDate) {
      showAlert('error', 'Oops! The start date cannot be later than the end date.');
      return;
    }

    if (startDate > currentDate || stopDate > currentDate) {
      showAlert('error', 'Oops! Please select a date range that is not in the future.');
      return;
    }

    if (contextType === 'engine') {
      setDateRange({ start: midnightStartDate, stop: endOfDayStopDate });
    } else if (contextType === 'dashboard') {
      const customRange = optionDateRange.find((range) => range.name === 'CUSTOM');
      const startDateString = new Date(midnightStartDate).toLocaleString();
      const stopDateString = new Date(endOfDayStopDate).toLocaleString();

      const startTimestamp = new Date(midnightStartDate).getTime() / 1000;
      const endTimestamp = new Date(endOfDayStopDate).getTime() / 1000;
      const durationInMilliseconds = ((endTimestamp - startTimestamp) * 1000 * 2) / 3;

      const newSelectedDateRange = {
        ...customRange,
        vs_string: `${startDateString} - ${stopDateString}`,
        start: midnightStartDate,
        stop: endOfDayStopDate,
        value: durationInMilliseconds,
      };
      setSelectedDateRange(newSelectedDateRange);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <button onClick={handleOpenModal} className={disabled ? 'pointer-events-none' : ''}>
        <CalendarDaysIcon
          className={`w-6 h-6 ml-2 mr-2 ${disabled ? 'text-perception-gray-800' : 'text-perception-gray-500'}   hover:text-perception-gray-200`}
        />
      </button>
      {isModalOpen ? (
        <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
          <div className='p-4 text-center'>
            <h3 className='mb-5 text-lg font-medium leading-6 text-gray-900'>Select Date Range</h3>
            <form onSubmit={handleDateSubmit} ref={calendarRef} className='mt-1 text-center'>
              <label className='block'>
                <span className='mr-3 text-sm text-start font-medium text-gray-700'>Start Date</span>
                <input
                  type='date'
                  value={calendarStartDate}
                  onChange={handleStartDateChange}
                  required
                  className='p-1.5 border-2 border-gray-300 rounded mt-1 bg-gray-100'
                />
              </label>
              <label className='block mt-4'>
                <span className='mr-3 text-sm text-start font-medium text-gray-700'>End Date</span>
                <input
                  type='date'
                  value={calendarStopDate}
                  onChange={handleStopDateChange}
                  required
                  className='p-1.5 border-2 border-gray-300 rounded mt-1 bg-gray-100'
                />
              </label>
              <div className='mt-7 flex justify-center'>
                <Button type='submit' category='tertiary-btn'>
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </Modal>
      ) : null}
    </>
  );
};
