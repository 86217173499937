import { useRef, useMemo, useEffect } from 'react';
import { useTrialLiveContext } from '../../../../../contexts/TrialLiveContext';
import { SelectPointer } from './SelectPointer';
import { MaskLayer } from './MaskLayer';
import { segmentEverything } from '../../../../../services/ia/segmentation-api';
import { Loader } from '../../../../../components/loading/Loader';
import { createKonvaLayerFromImage, base64ToBlobURL } from '../../../../../helpers/image/image-helper';

export const ViewSnapshot = ({ cameraDimensions, displayDimensions }) => {
  const { snapshot, clickedNativePoints, masksLayer, setMasksLayer, isViewLoadingData, setIsViewLoadingData } =
    useTrialLiveContext();

  const imgRef = useRef();

  // Points clicked by user UI
  const clickedPointsElements = useMemo(() => {
    return clickedNativePoints.length
      ? clickedNativePoints.map((point, index) => {
          return (
            <SelectPointer
              key={index}
              nativePoint={point}
              cameraDimensions={cameraDimensions}
              displayDimensions={displayDimensions}
            />
          );
        })
      : null;
  }, [clickedNativePoints]);

  // After getting a snapshot, segment it
  useEffect(() => {
    if (masksLayer === null && snapshot && cameraDimensions.width > 0 && cameraDimensions.height > 0) {
      const getSegmentEverything = async () => {
        console.log('[DEBUG] Segment everything');

        setIsViewLoadingData(true);

        try {
          // API call to segment everything
          const masksData = await segmentEverything(snapshot.object_id, snapshot.img);
          const masksImageUrl = base64ToBlobURL(masksData);
          const masksImage = new Image();

          masksImage.onload = () => {
            setMasksLayer(createKonvaLayerFromImage(masksImage, cameraDimensions.width, cameraDimensions.height));
          };

          masksImage.src = masksImageUrl;
        } catch (error) {
          console.error('An error occurred while segmenting everything: ', error);
        } finally {
          setIsViewLoadingData(false);
        }
      };

      getSegmentEverything();
    }
  }, [snapshot, cameraDimensions]);

  if (!snapshot) return null;

  return (
    <div className='h-full w-full relative'>
      <img ref={imgRef} src={snapshot.img} alt='Snapshot' className='h-full w-full' />
      {isViewLoadingData && <Loader />}
      {clickedPointsElements}
      {masksLayer && <MaskLayer cameraDimensions={cameraDimensions} displayDimensions={displayDimensions} />}
    </div>
  );
};
