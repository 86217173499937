import { XMarkIcon } from '@heroicons/react/24/outline';
import { patchData, postData } from '../../../../../../services/api/api-tools';
import { useEffect, useState } from 'react';
import { Button } from '../../../../../../components/buttons/Button';
import { Input } from '../../../../../../components/forms/inputs/Input';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';
import { useQueryClient } from 'react-query';

export const CommentModal = ({ selectedComment, closeCommentModal, isViewMode }) => {
  const { selectedFrame, annotationQueryKey } = useAnnotation();

  const queryClient = useQueryClient();

  const [title, setTitle] = useState('');
  const [comment, setComment] = useState('');
  const [isReadOnly, setIsReadOnly] = useState(false);

  const handleCreateComment = async () => {
    const payload = {
      title,
      content: comment,
      specificationFrame: `/api/specification_frames/${selectedFrame.id}`,
    };
    try {
      const response = await postData('specification_annotations', payload);

      if (!response) {
        throw new Error('Erreur lors de la soumission du commentaire');
      }

      queryClient.setQueryData(annotationQueryKey, (oldData) => {
        if (oldData && oldData.length > 0) {
          return [...oldData, response];
        } else {
          return [response];
        }
      });

      closeCommentModal();
    } catch (error) {
      console.error('Une erreur est survenue :', error);
    }
  };

  const handleEditComment = async () => {
    const payload = {
      title,
      content: comment,
    };
    try {
      const response = await patchData(`specification_annotations/${selectedComment.id}`, payload);
      if (!response) {
        throw new Error('Erreur lors de la soumission du commentaire');
      }

      queryClient.setQueryData(annotationQueryKey, (oldData) => {
        if (oldData && oldData.length > 0) {
          return oldData.map((annotation) => {
            if (annotation.id === selectedComment.id) {
              return {
                ...annotation,
                title: title,
                content: comment,
              };
            } else {
              return annotation;
            }
          });
        } else {
          return oldData;
        }
      });

      closeCommentModal();
    } catch (error) {
      console.error('Une erreur est survenue :', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedComment) {
      handleEditComment();
    } else {
      handleCreateComment();
    }
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsReadOnly(false);
  };

  useEffect(() => {
    if (selectedComment) {
      setTitle(selectedComment.title);
      setComment(selectedComment.content);
      setIsReadOnly(true);
    }
  }, [selectedComment]);

  return (
    <div className='w-[500px] left-1/2 top-1/2 fixed -translate-y-2/4 -translate-x-2/4 z-50'>
      <form onSubmit={handleSubmit}>
        <div className='relative overflow-hidden shadow-sm ring-1 ring-inset ring-perception-blue bg-perception-black-800'>
          <div className='flex flex-col h-full w-full p-4'>
            <div className='w-1/2'>
              <Input
                category='secondary'
                placeholder='EX: MACHINE MAINTENANCE'
                className='uppercase'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                readOnly={isReadOnly}
              />
            </div>

            <label htmlFor='comment' className='sr-only'>
              Comments
            </label>
            <textarea
              rows={3}
              name='comment'
              id='comment'
              className='block mx-2 py-2 px-2 mt-2 h-32 resize-none border-0 ring-1 ring-perception-blue/50 focus:border-transparent focus:outline-none bg-transparent text-white placeholder:text-gray-400 text-sm sm:leading-6'
              placeholder='Ex: In this situation, I want to capture information when the operator performs machine maintenance.'
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              readOnly={isReadOnly}
            />
            <div className='flex justify-end pt-2'>
              {!isViewMode ? (
                <div className='flex-shrink-0 mr-2'>
                  {isReadOnly ? (
                    <Button category='variant-btn' size='xs' onClick={handleEditClick} type='button'>
                      EDIT
                    </Button>
                  ) : (
                    <Button category='tertiary-btn' size='xs' type='submit'>
                      SAVE
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <XMarkIcon className='w-6 h-6 text-white absolute top-4 right-4 cursor-pointer' onClick={closeCommentModal} />
        </div>
      </form>
    </div>
  );
};
