import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { patchData } from '../../api/api-tools';

const API_URL = process.env.REACT_APP_API_ADDRESS;

// Handles user login, fetching user data and a token upon successful authentication.
export const postUserLogin = async (data) => {
  const response = await axios.post(process.env.REACT_APP_API_TOKEN_AUTH, data);
  if (response) {
    let token = response.data.token;
    let payload = jwtDecode(token);
    const userData = await getUserDataByEmail(token, payload.username);
    if (userData) {
      return {
        user: userData,
        token: token,
      };
    }
  }
};

// Retrieves a user from the API based on their Google ID, facilitating user lookup by Google ID.
export const getUserByGoogleId = async (googleId) => {
  try {
    const response = await axios.get(`${API_URL}users?google_id=${googleId}`);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de l'utilisateur :", error);
    throw error;
  }
};

// Handling the Google authentication process, fetching user data, and initiating login.
export const googleAuthUser = async (credential, streamId, setIsLoading, organizationId) => {
  const decodedCredential = jwtDecode(credential);
  const { sub, email, family_name, given_name } = decodedCredential;

  try {
    setIsLoading(true);
    let userData = await postUserLogin({
      googleAuth: true,
      googleId: sub,
      email: email,
      family_name: family_name,
      given_name: given_name,
      stream_id: streamId,
      organization_id: organizationId,
    });

    if (userData) {
      return userData;
    }
  } catch (error) {
    console.error("Erreur lors de l'authentification Google :", error);
    throw error;
  } finally {
    setIsLoading(false);
  }
};

export const getUserDataByEmail = async (token, email) => {
  if (!token || !email) {
    console.error('Token et email sont requis');
    return null;
  }

  const apiUrl = `${process.env.REACT_APP_API_ADDRESS}users?email=${email}`;

  const response = await axios.get(apiUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status === 200 && response.data && response.data[0]) {
    return response.data[0];
  }
};

export const getUserByToken = async (token) => {
  const apiUrl = `${process.env.REACT_APP_API_ADDRESS}users?token=${token}`;
  const response = await axios.get(apiUrl);
  if (response) {
    return response.data[0];
  }
};

/**
 * Update user's password
 */
export const changeUserPassword = async (dataPassword) => {
  let path = `users/${dataPassword.id}/password`; // id = user id

  return patchData(path, dataPassword);
};

/**
 * User register
 */
export const postUserRegister = async (dataUser) => {
  return await axios.post(process.env.REACT_APP_API_ADDRESS + 'register', dataUser);
};

export const postUserConfirmRegister = async (dataUser) => {
  return await axios.patch(process.env.REACT_APP_API_ADDRESS + 'confirm_register', dataUser, {
    headers: {
      'Content-Type': 'application/merge-patch+json',
    },
  });
};
