import { createContext, useContext, useMemo, useRef, useState } from 'react';
import { useGetData } from '../services/api/api-tools';

const AnnotationContext = createContext();

export const AnnotationProvider = ({ children }) => {
  const canvasRef = useRef(null);

  const [selectedFrame, setSelectedFrame] = useState(null);
  const [markerHovered, setMarkerHovered] = useState(null);
  const [clickedPixel, setClickedPixel] = useState(null);

  const [isVideoSituation, setIsVideoSituation] = useState(false);
  const [isMarkerSelected, setIsMarkerSelected] = useState(false);
  const [isUserDragVideo, setIsUserDragVideo] = useState(false);
  const [isInitialSegmentationDone, setIsInitialSegmentationDone] = useState(false);

  const [guidePopup, setGuidePopup] = useState(null);

  const frameId = selectedFrame?.id;
  let annotationQueryKey;
  let query;

  const situationId =
    selectedFrame?.specification_situation?.id ?? selectedFrame?.specification_situation?.split('/').pop();

  if (isVideoSituation) {
    annotationQueryKey = `specification_frame.specification_situation_${situationId}`;
    query = `specification_annotations?specification_frame.specification_situation.id=${situationId}`;
  } else {
    annotationQueryKey = `specification_frame_${frameId}`;
    query = `specification_annotations?specification_frame.id=${frameId}`;
  }

  const { data: annotations } = useGetData(annotationQueryKey, query, {}, !!frameId);

  const activeMarker = useMemo(() => {
    if (isMarkerSelected && markerHovered) {
      return markerHovered;
    } else {
      return null;
    }
  }, [markerHovered, isMarkerSelected]);

  const value = useMemo(
    () => ({
      canvasRef,
      clickedPixel,
      setClickedPixel,
      markerHovered,
      setMarkerHovered,
      isMarkerSelected,
      setIsMarkerSelected,
      selectedFrame,
      setSelectedFrame,
      annotations,
      activeMarker,
      isUserDragVideo,
      setIsUserDragVideo,
      isVideoSituation,
      setIsVideoSituation,
      guidePopup,
      setGuidePopup,
      isInitialSegmentationDone,
      setIsInitialSegmentationDone,
      annotationQueryKey,
    }),
    [
      canvasRef,
      clickedPixel,
      setClickedPixel,
      markerHovered,
      setMarkerHovered,
      isMarkerSelected,
      setIsMarkerSelected,
      selectedFrame,
      setSelectedFrame,
      annotations,
      activeMarker,
      isUserDragVideo,
      setIsUserDragVideo,
      isVideoSituation,
      setIsVideoSituation,
      guidePopup,
      setGuidePopup,
      isInitialSegmentationDone,
      setIsInitialSegmentationDone,
      annotationQueryKey,
    ],
  );

  return <AnnotationContext.Provider value={value}>{children}</AnnotationContext.Provider>;
};

export const useAnnotation = () => {
  return useContext(AnnotationContext);
};
