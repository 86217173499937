import { ClockIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Panel } from '../../../../../components/panels/Panel';
import { Menu } from '../../../../../components/menu/Menu';
import { useEffect, useState } from 'react';
import { HoverTextButton } from '../../../../../components/buttons/HoverTextButton';
import { ModalManageAlert } from './ModalManageAlert';
import { fetchData } from '../../../../../services/api/api-tools';
import { useUser } from '../../../../../contexts/UserContext';
import { useOrgData } from '../../../../../contexts/OrgDataContext';

export const AlertPanel = ({ alerts, setAlerts, setIsWarnModalOpen, setAlertIdToDelete }) => {
  const { user } = useUser();
  const { workstationSelected } = useOrgData();

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);
  const [alertOnEdit, setAlertOnEdit] = useState(null);

  const handleEditAlert = (alert) => {
    setAlertOnEdit(alert);
    setIsAlertModalOpen(true);
  };

  const handleCreateNewAlert = () => {
    setAlertOnEdit(null);
    setIsAlertModalOpen(true);
  };

  useEffect(() => {
    const getAlerts = async () => {
      try {
        const path = `alerts?user=${user?.id}`;
        let alerts = await fetchData(path);
        alerts = alerts.filter(
          (alert) => alert.workstation === undefined || alert.workstation.id === workstationSelected.id,
        );
        if (alerts) {
          setAlerts(alerts);
        }
      } catch (err) {
        console.error('Something wrong happened:', err);
      }
    };

    if (workstationSelected?.id > 0) {
      getAlerts();
    }
  }, [workstationSelected]);

  return (
    <Panel title='ALARMS'>
      <div className='h-full group/btn'>
        {alerts
          .filter((alert) => !alert.target || alert?.target?.workstation?.id === workstationSelected.id)
          .map((alert) => {
            return (
              <Alert
                key={alert.id}
                alert={alert}
                setIsWarnModalOpen={setIsWarnModalOpen}
                handleEditAlert={handleEditAlert}
                setAlertIdToDelete={setAlertIdToDelete}
              />
            );
          })}
        <div className='mt-4'>
          <HoverTextButton onClick={handleCreateNewAlert} disabled={false}>
            <PlusIcon className='w-4 h-4' />
            <span className='pl-2'>Create a new alert</span>
          </HoverTextButton>
        </div>
      </div>
      {isAlertModalOpen && (
        <ModalManageAlert
          alert={alertOnEdit}
          alerts={alerts}
          setAlerts={setAlerts}
          isAlertModalOpen={isAlertModalOpen}
          setIsAlertModalOpen={setIsAlertModalOpen}
          setAlertOnEdit={setAlertOnEdit}
        />
      )}
    </Panel>
  );
};

const Alert = ({ alert, setIsWarnModalOpen, handleEditAlert, setAlertIdToDelete }) => {
  const { name, active = true } = alert;

  const [menu, setMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      alert,
    });
  };

  const handleClose = () => {
    setMenu(null);
  };

  const handleEdit = () => {
    handleEditAlert(alert);
    handleClose();
  };

  const handleDelete = () => {
    setIsWarnModalOpen(true);
    setAlertIdToDelete(alert.id);
  };

  return (
    <div className='flex items-center my-2 cursor-pointer relative' onContextMenu={handleContextMenu}>
      <ClockIcon className={`h-5 w-5 ${active ? 'text-perception-blue' : 'text-perception-gray-500'}`} />
      <span className='text-xs text-perception-gray-500 pl-4 font-semibold uppercase'>{name}</span>
      {menu && (
        <Menu menu={menu} setMenu={setMenu} onClose={handleClose}>
          <span className='p-2 px-4 text-start cursor-pointer hover:bg-perception-blue' onClick={handleEdit}>
            Edit
          </span>
          <span className='p-2 px-4 text-start cursor-pointer hover:bg-perception-blue' onClick={handleDelete}>
            Delete
          </span>
        </Menu>
      )}
    </div>
  );
};
