import { Panel } from '../../../../../components/panels/Panel';
import { LogPanelFactory } from '../../../../../components/logs/LogPanelFactory';
import { SetupSettings } from './SetupSettings';

export const RightPanel = () => {
  return (
    <div className='flex flex-col w-full h-full'>
      <div className='flex flex-col h-2/3'>
        <Panel title='ACTIVITY LOG'>
          <div className='h-full w-full pt-2 pb-4 overflow-hidden'>
            <LogPanelFactory view='mini' />
          </div>
        </Panel>
      </div>
      <div className='flex flex-col flex-1'>
        <Panel title='SETTINGS'>
          <SetupSettings />
        </Panel>
      </div>
    </div>
  );
};
