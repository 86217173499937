import { Modal } from '../../../Modal';
import { Button } from '../../../../../../components/buttons/Button';
import { Fragment, useState } from 'react';
import { CreationVideo } from '../components/CreationVideo';
import { CreationSnapshot } from '../components/CreationSnapshot';
import { XMarkIcon } from '@heroicons/react/24/outline';

export const NewSituationModal = ({
  specification,
  closeSituationModal,
  setSelectedSituation,
  specificationQueryKey,
  situations,
}) => {
  const [situationType, setSituationType] = useState(null);

  const hasSituations = situations.length > 0;

  return (
    <Modal closeModal={closeSituationModal} className={situationType && 'p-0'}>
      {situationType === 'record' && (
        <CreationVideo
          specification={specification}
          closeSituationModal={closeSituationModal}
          setSelectedSituation={setSelectedSituation}
          specificationQueryKey={specificationQueryKey}
        />
      )}
      {situationType === 'snapshot' && (
        <CreationSnapshot
          specification={specification}
          closeSituationModal={closeSituationModal}
          setSelectedSituation={setSelectedSituation}
          specificationQueryKey={specificationQueryKey}
        />
      )}
      {!situationType && (
        <MenuModal
          setSituationType={setSituationType}
          closeSituationModal={closeSituationModal}
          hasSituations={hasSituations}
        />
      )}
    </Modal>
  );
};

const MenuModal = ({ setSituationType, closeSituationModal, hasSituations }) => {
  const selectSituationType = (type) => {
    setSituationType(type);
  };

  return (
    <Fragment>
      <h2 className='text-2xl font-semibold'>Create a new situation</h2>
      {hasSituations && (
        <XMarkIcon className='absolute w-6 h-6 text-white cursor-pointer right-6 top-6' onClick={closeSituationModal} />
      )}

      <p className='pt-6 w-[600px]'>
        To create a scenario, you can decide to annotate and comment on either a still image or a video. This allows you
        to provide detailed feedback.
      </p>
      <div className='flex justify-center gap-20 pt-6'>
        <div className='flex flex-col items-center gap-2'>
          <span className='font-semibold'>Make a video</span>
          <Button category='tertiary-btn' size='small' onClick={() => selectSituationType('record')}>
            Record
          </Button>
        </div>
        <hr className='h-20 border border-perception-gray-500' />
        <div className='flex flex-col items-center gap-2'>
          <span className='font-semibold'>Make a snapshot</span>
          <Button category='secondary-btn' size='small' onClick={() => selectSituationType('snapshot')}>
            Snapshot
          </Button>
        </div>
      </div>
    </Fragment>
  );
};
