import { useTrialLiveContext } from '../../../../../contexts/TrialLiveContext';
import { useTrialSetupContext } from '../../../../../contexts/TrialSetupContext';
import { VideoFromComputer } from '../../../../trial/components/setup/VideoFromComputer';
import { VideoFromMobile } from '../../../../trial/components/setup/VideoFromMobile';

export const ViewCamera = ({ videoRef }) => {
  const { snapshot } = useTrialLiveContext();
  const { typeDevice, isQrCodeShown, isCameraReady, setIsCameraReady } = useTrialSetupContext();

  const hidden = !!snapshot || !!isQrCodeShown;

  if (typeDevice === 'mobile') {
    return (
      <VideoFromMobile
        videoRef={videoRef}
        isCameraReady={isCameraReady}
        setIsCameraReady={setIsCameraReady}
        hidden={hidden}
      />
    );
  } else {
    return <VideoFromComputer videoRef={videoRef} setIsCameraReady={setIsCameraReady} hidden={hidden} />;
  }
};
