import { Outlet } from 'react-router-dom';
import { TrialSetupProvider } from '../../contexts/TrialSetupContext';

export const TrialLayout = () => {
  return (
    <TrialSetupProvider>
      <Outlet />
    </TrialSetupProvider>
  );
};
