export const getLastUpdateDateFromEventTypes = (eventTypes) => {
  const getDates = (eventTypes) => {
    let dates = [];
    eventTypes.forEach((eventType) => {
      if (eventType.event_types_confidences) {
        eventType.event_types_confidences.forEach((confidence) => {
          dates.push(confidence.date);
        });
      }
    });
    return dates;
  };

  const allDates = getDates(eventTypes);

  const mostRecentDate = Math.max(...allDates);

  return new Date(mostRecentDate * 1000);
};

export const getEventTypesByWorkstationId = (workstationId, data) => {
  const eventTypes = [];
  const eventTypesSet = {};

  data.forEach((eventType) => {
    if (eventType.workstation && eventType.workstation.id === workstationId && eventType.device) {
      eventTypesSet[eventType.id] = eventType;
    }
  });

  for (const id in eventTypesSet) {
    eventTypes.push(eventTypesSet[id]);
  }

  return eventTypes;
};

export const getLearningStateName = (eventType) => {
  switch (eventType.learning_state) {
    case 0:
      return 'Error';
    case 1:
      return 'Learning in progress';
    case 2:
      return 'Validated';
    default:
      return 'Error';
  }
};
