import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { useMemo } from 'react';

export const ProgressBar = ({ specification, specificationErrors }) => {
  const { state } = specification;

  const hasError = useMemo(() => {
    return specificationErrors.filter((error) => !error.is_fixed);
  }, [specificationErrors]);

  const steps = [
    {
      name: 'CAMERA SETUP',
      description: 'Setting up camera parameters',
      filled: state === 1 ? 'current' : state > 1 ? 'complete' : 'upcoming',
    },
    {
      name: 'ANNOTATION',
      description: 'Annotating images',
      filled: state === 2 ? 'current' : state > 2 ? 'complete' : 'upcoming',
    },
    {
      name: 'LEARNING',
      description: 'Machine learning process',
      filled: state === 3 ? 'current' : state > 3 ? 'complete' : 'upcoming',
    },
    { name: 'APPROVAL', description: 'Final approval', filled: state === 4 ? 'complete' : 'upcoming' },
  ];

  return (
    <div className='self-start'>
      <nav aria-label='Progress' className='flex justify-center'>
        <ol role='list' className='space-y-6 pointer-events-none'>
          {steps.map((step) => (
            <li key={step.name}>
              {step.filled === 'complete' ? (
                <a className='group'>
                  <span className='flex items-start'>
                    <span className='relative flex items-center justify-center flex-shrink-0 w-5 h-5'>
                      <CheckCircleIcon aria-hidden='true' className='w-full h-full text-perception-blue' />
                    </span>
                    <span className='ml-3 text-sm font-medium text-perception-gray-500'>{step.name}</span>
                  </span>
                </a>
              ) : step.filled === 'current' ? (
                <a aria-current='step' className='flex items-start'>
                  <span aria-hidden='true' className='relative flex items-center justify-center flex-shrink-0 w-5 h-5'>
                    <span
                      className={`absolute h-4 w-4 rounded-full ${hasError.length ? 'bg-perception-warn-500/50' : 'bg-perception-blue/50'} animate-pulse`}
                    />
                    <span
                      className={`relative block h-2 w-2 rounded-full ${hasError.length ? 'bg-perception-warn-500' : 'bg-perception-blue'}`}
                    />
                  </span>
                  <span
                    className={`ml-3 text-sm font-medium ${hasError.length ? 'text-perception-warn-500' : 'text-perception-blue'}`}
                  >
                    {step.name}
                  </span>
                </a>
              ) : (
                <a className='group'>
                  <div className='flex items-start'>
                    <div aria-hidden='true' className='relative flex items-center justify-center flex-shrink-0 w-5 h-5'>
                      <div className='w-2 h-2 bg-gray-300 rounded-full group-hover:bg-gray-400' />
                    </div>
                    <p className='ml-3 text-sm font-medium text-perception-gray-400'>{step.name}</p>
                  </div>
                </a>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
