import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useUser } from './UserContext';
import { getChartsByOrganizationId } from '../services/entity/chart/chart-api';
import { useOrgData } from './OrgDataContext';

const DashboardContext = createContext();

export const useDashboard = () => {
  return useContext(DashboardContext);
};

export const DATE_RANGES = {
  DAY: 1,
  WEEK: 2,
  MONTH: 3,
  YEAR: 4,
};

const today = new Date();
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);

const week = new Date(today);
week.setDate(week.getDate() - 7);

const month = new Date(today);
month.setMonth(month.getMonth() - 1);

const year = new Date(today);
year.setFullYear(year.getFullYear() - 1);

export const optionDateRange = [
  {
    id: DATE_RANGES.DAY,
    name: 'DAY',
    value: 16 * 3600 * 1000,
    vs_string: 'Today vs. yesterday',
    start: yesterday.getTime(),
    stop: today.getTime(),
  },
  {
    id: DATE_RANGES.WEEK,
    name: 'WEEK',
    value: 7 * 16 * 3600 * 1000,
    vs_string: 'This week vs. previous week',
    start: week.getTime(),
    stop: today.getTime(),
  },
  {
    id: DATE_RANGES.MONTH,
    name: 'MONTH',
    value: 30 * 16 * 3600 * 1000,
    vs_string: 'This month vs. previous month',
    start: month.getTime(),
    stop: today.getTime(),
  },
  {
    id: DATE_RANGES.YEAR,
    name: 'YEAR',
    value: 365 * 16 * 3600 * 1000,
    vs_string: 'This year vs. previous year',
    start: year.getTime(),
    stop: today.getTime(),
  },
  {
    id: DATE_RANGES.DAY,
    name: 'CUSTOM',
    vs_string: 'Custom dates',
  },
];

export const DashboardProvider = ({ children }) => {
  const { user } = useUser();
  const { workshops } = useOrgData();

  const [charts, setCharts] = useState([]);
  const [workstations, setWorkstations] = useState([]);

  const [selectedDateRange, setSelectedDateRange] = useState(optionDateRange[0]);

  const [workshopSelected, setWorkshopSelected] = useState(null);
  const [workstationSelected, setWorkstationSelected] = useState(null);

  const [customData, setCustomData] = useState([]);
  const [customDataLoadCount, setCustomDataCount] = useState(0);

  useEffect(() => {
    const getOrganizationCharts = async () => {
      try {
        const charts = await getChartsByOrganizationId(user.organization.id);
        setCharts(charts);
      } catch (err) {
        console.error(err);
      }
    };

    if (user) {
      getOrganizationCharts();
    }
  }, [user]);

  useEffect(() => {
    if (workshops !== undefined && workshops.length) {
      setWorkshopSelected(workshops[0]);
    }
  }, [workshops]);

  useEffect(() => {
    if (workshopSelected) {
      setWorkstationSelected(workshopSelected.workstations[0]);
      setWorkstations(workshopSelected.workstations);
    }
  }, [workshopSelected]);

  const value = useMemo(
    () => ({
      charts,
      setCharts,
      selectedDateRange,
      setSelectedDateRange,
      workshopSelected,
      setWorkshopSelected,
      workstationSelected,
      setWorkstationSelected,
      workstations,
      customData,
      setCustomData,
      customDataLoadCount,
      setCustomDataCount,
    }),
    [
      charts,
      setCharts,
      selectedDateRange,
      setSelectedDateRange,
      workshopSelected,
      setWorkshopSelected,
      workstationSelected,
      setWorkstationSelected,
      workstations,
      customData,
      setCustomData,
      customDataLoadCount,
      setCustomDataCount,
    ],
  );

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
};
