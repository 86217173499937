import { useEffect, useRef, useState } from 'react';
import { generateRandomString } from '../helpers/common/generateRandomString';
import { io } from 'socket.io-client';
import { applyBlur } from '../helpers/image/image-helper';

export const useVideoDeviceStream = (clientId) => {
  const videoRef = useRef(null);
  const socketRef = useRef(null);

  const [isReceivingFrames, setIsReceivingFrames] = useState(false);

  const [isUserRecording, setIsUserRecording] = useState(false);
  const [originalFramesRecorded, setOriginalFramesRecorded] = useState([]);

  const [frameSizes, setFrameSizes] = useState({
    width: 0,
    height: 0,
    ratio: 0,
  });

  useEffect(() => {
    console.log('[DEBUG] Socket connection', clientId);
    const socket = io(`${process.env.REACT_APP_NODE_EVENTS_PROTOCOL}://${process.env.REACT_APP_NODE_STREAM_URL}`, {
      reconnection: false,
      path: '/stream-device',
      query: { id: generateRandomString(5), exchange: clientId },
    });

    socketRef.current = socket;

    socket.on('frame', (frame) => {
      const { image, boundingBox } = frame;
      const base64String = image.replace(/[^A-Za-z0-9+/=]/g, '');
      const dataUrl = `data:image/jpeg;base64,${base64String}`;
      const img = new Image();
      img.src = dataUrl;

      const context = videoRef.current.getContext('2d');

      img.onload = () => {
        const { width, height } = img;

        if (isUserRecording) {
          setOriginalFramesRecorded((prevFrames) => [...prevFrames, { img: img, boundingBox: boundingBox }]);
        }

        const ratio = width / height;

        videoRef.current.width = width;
        videoRef.current.height = height;

        setFrameSizes({ width: img.width, height: img.height, ratio: ratio });

        context.clearRect(0, 0, videoRef.current.width, videoRef.current.height);
        context.drawImage(img, 0, 0, videoRef.current.width, videoRef.current.height);

        if (boundingBox && boundingBox.length > 0) {
          boundingBox.forEach(([x1, y1, x2, y2]) => {
            applyBlur(context, x1, y1, x2 - x1, y2 - y1, videoRef);
          });
        }
      };

      setIsReceivingFrames(true);
    });

    socket.on('connect_error', () => {
      socket.disconnect();
    });

    socket.on('disconnect', () => {
      console.log('Disconnected from Stream Device Socket');
      setIsReceivingFrames(false);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [clientId, isUserRecording]);

  return {
    videoRef,
    isReceivingFrames,
    frameSizes,
    setIsUserRecording,
    originalFramesRecorded,
    setOriginalFramesRecorded,
  };
};
