import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { useAlert } from '../../../../contexts/AlertContext';
import { CardIcon } from './CardIcon';
import { InfoMessage } from './InfoMessage';
import { Button } from '../../../../components/buttons/Button';

export const BuyDevice = ({ setStep }) => {
  const { showAlert } = useAlert();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        showAlert('success', 'Email address successfully copied to your clipboard.');
      },
      () => {
        showAlert('error', 'Oops! Something went wrong while attempting to copied the email.');
      },
    );
  };

  return (
    <div id='setup-autosetup' className='flex flex-col justify-start items-start'>
      <div className='flex justify-start item-center'>
        <CardIcon
          iconComponent={EnvelopeIcon}
          text='Contact by mail'
          resume='copy'
          setter={() => copyToClipboard('administration@perception-manufacturing.com')}
        />
        <CardIcon iconComponent={PhoneIcon} text='Call us' resume='+336 37 76 15 21' />
      </div>
      <InfoMessage text='Perception Manufacturing can provide a trial kit including a camera and a mount, a stand and the necessary cables for 90€' />
      <div className='mt-6'>
        <Button type='button' category='secondary-btn' size='medium' onClick={() => setStep(0)}>
          Back
        </Button>
      </div>
    </div>
  );
};
