import moment from 'moment/moment';
import { _areClose } from '../../helpers/date/date-helper';
import { logsText } from '../../temp/mock';
import {
  BellIcon,
  EllipsisHorizontalIcon,
  QuestionMarkCircleIcon,
  Square3Stack3DIcon,
  ViewfinderCircleIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';

const icons = {
  LOG1: ViewfinderCircleIcon,
  LOG2: ViewfinderCircleIcon,
  PATTERN: Square3Stack3DIcon,
  ALERT: BellIcon,
  CALIBRATION: Cog6ToothIcon,
  INACTIVITY: EllipsisHorizontalIcon,
  UNKNOWN: QuestionMarkCircleIcon,
};

/* Logs constants */
export const NO_OBJECT = -1;

export const LOGS_TYPES = {
  LOG1: 1,
  LOG2: 2,
  PATTERN: 3,
  ALERT: 4,
  CALIBRATION: 5,
};

export const LOGS_COMMON_SUBTYPES = {
  INTERACTION_WITH: 1,
  NO_HUMAN_IN_AREA: 2,
  HUMAN_IN_AREA: 3,
  INTERACTION_WITH_NO_TRIAL: 4,
};

// TODO Remove the following constant to use int instead of string for checks
export const logsType = [
  {
    id: 1,
    type: 'LOG1',
  },
  {
    id: 2,
    type: 'LOG2',
  },
  {
    id: 3,
    type: 'PATTERN',
  },
  {
    id: 4,
    type: 'ALERT',
  },
  {
    id: 5,
    type: 'CALIBRATION',
  },
];

/**
 * Filters and returns logs based on specified targets.
 */
export const getTargetsLogs = (logs, targets) => {
  if (targets === null) return logs;
  return logs.filter(
    (log) => targets.some((target) => target.id === parseInt(log.object_id)) || parseInt(log.object_id) === NO_OBJECT,
  );
};

/**
 * Groups logs by their date and returns an object containing the grouped logs and a list of dates.
 * Each log is assigned to a group based on its date (formatted as 'YYYY-MM-DD').
 */
export const getGroupedLogs = (logs) => {
  const groupedLogs = logs.reduce((acc, log) => {
    const date = moment(log.time).format('YYYY-MM-DD');
    if (!acc[date]) acc[date] = [];
    acc[date].push(log);
    return acc;
  }, {});

  const dates = Object.keys(groupedLogs);

  return { groupedLogs, dates };
};

/**
 * Filters logs based on the specified targets and then groups them by date.
 */
export const getFilteredAndGroupedLogs = (logs, targets) => {
  return getGroupedLogs(getTargetsLogs(logs, targets));
};

/**
 * Aggregates logs based on subtype, aggregation duration, and filter date.
 */
export const getAggregatedLogs = (sortedLogs, aggregationDuration) => {
  const logsBySubtype = groupLogsBySubtype(sortedLogs);
  const aggregatedLogs = [];

  for (const logs of Object.values(logsBySubtype)) {
    aggregateSubtypeLogs(logs, aggregatedLogs, aggregationDuration);
  }

  return aggregatedLogs.sort((a, b) => moment(b.time).diff(moment(a.time)));
};

/**
 * Groups logs by their subtype.
 */
const groupLogsBySubtype = (sortedLogs) => {
  const logsBySubtype = {};
  for (const log of sortedLogs) {
    const key = log.subtype;
    if (!logsBySubtype[key]) logsBySubtype[key] = [];
    logsBySubtype[key].push(log);
  }
  return logsBySubtype;
};

/**
 * Aggregates logs for a specific subtype.
 */
const aggregateSubtypeLogs = (logs, aggregatedLogs, aggregationDuration) => {
  let prevLog = null;
  for (const log of logs.reverse()) {
    if (!prevLog) {
      prevLog = { ...log };
    } else {
      if (_areClose(prevLog, log, aggregationDuration)) {
        prevLog.duration = (prevLog.duration || 0) + (log.duration || 0);
      } else {
        aggregatedLogs.push(prevLog);
        prevLog = { ...log };
      }
    }
  }

  if (prevLog) aggregatedLogs.push(prevLog);
};

// Checks if a log entry represents a human activity by verifying if its subtype is 3.
// TODO : verify that subtype 3 is right affiliated to human logs.
export const isHumanLog = (log) => {
  return log.subtype === 3 || log.subtype === 2;
};

// Determines if a log matches a specified event pattern by comparing the subtype and object ID with those in the pattern.
export const isLogMatchEventPattern = (patternEvent, log) => {
  return (
    (parseInt(log.subtype) === parseInt(patternEvent.event_type.subtype) &&
      parseInt(log.object_id) === parseInt(patternEvent.target?.id)) ||
    (parseInt(log.object_id) === null && parseInt(log.subtype) === parseInt(patternEvent.event_type.subtype))
  );
};

// This function sorts an array of log objects by their time property in descending order
export const sortLogsByTime = (logs) => {
  return logs.sort((a, b) => {
    const timeDifference = moment(b.time).diff(moment(a.time));

    if (timeDifference === 0) {
      // If log.time is the same for both logs, check the log.type and give priority to patterns
      return b.type === LOGS_TYPES.PATTERN ? 1 : -1;
    }

    return timeDifference;
  });
};

/**
 * Retrieves the type of a log based on its 'type' field.
 */
export const getType = (log) => {
  const typeIndex = log.type || LOGS_TYPES.LOG1;
  const type = logsType.find((element) => element.id === typeIndex)?.type;
  return type || 'UNKNOWN';
};

/**
 * Constructs a descriptive text for a log with placeholders and optional duration.
 * Finds a text template based on log's subtype and fills in placeholders.
 * Optionally appends the duration of the event.
 */
export const getText = (log, placeholders = {}, includeDuration = true, eventTypes = null) => {
  /* Find base text */
  let text = undefined;

  // Case 1: an event has a subtype, get subtype name
  const eventType = eventTypes.find((eventType) => eventType.subtype === log.subtype);
  text = eventType?.name;

  // Case 2: the following line still uses mock sentences for demo video
  if (text === undefined) text = logsText.find((element) => element.id === log.subtype)?.text;

  // Case 3: not found anywhere, default text
  if (!text) return 'An unknown event occurred (' + log?.subtype + ')';

  /* Fill placeholder with values */

  const logPlaceholders = Object.fromEntries(
    Object.entries(log)
      .filter(([key]) => key.startsWith('data_'))
      .map(([key, value]) => [key.replace('data_', ''), value]),
  );

  if (Object.entries(logPlaceholders).length) placeholders = Object.assign({}, placeholders, logPlaceholders);

  const matches = [...text.matchAll(/\{\{([^}]+)\}\}/g)];

  for (const match of matches) {
    if (placeholders[match[1]] !== undefined) text = text.replace(match[0], placeholders[match[1]]);
  }

  /* Add duration if needed */
  if (includeDuration && log.duration > 1) {
    text += ' (' + Math.round(parseInt(log.duration)) + 's)';
  }
  return text;
};

/**
 * Checks if there is a subtext associated with a log's 'sub' field.
 */
export const hasSubText = (log) => {
  const subText = logsText.find((element) => element.id === log.sub)?.text;
  return !!subText;
};

/**
 * Retrieves the subtext of a log based on its 'sub' field.
 */
export const getSubText = (log) => {
  const subText = logsText.find((element) => element.id === log.sub)?.text;
  return subText || '-';
};

/**
 * Generates an icon component for a log, optionally with a specified size.
 */
export const getIcon = (log, size = null) => {
  const logType = log.type;
  const iconClasses = `${size === 'small' ? 'h-3 w-3' : 'h-5 w-5'} ${
    logType === 3 ? 'text-perception-blue' : 'text-perception-gray-500'
  }`;
  const IconComponent = icons[getType(log)] || QuestionMarkCircleIcon;
  return <IconComponent className={iconClasses} />;
};

/*
 * Extracts and returns the subtypes from the given array of event types based on the provided workstationId.
 * Additionally, return subtypes from deviceId without workstation.
 * Returns an array containing the extracted subtypes.
 */
export const extractAndFilterSubtypes = (eventTypes, workstation, considerUsage = false) => {
  const deviceId = workstation?.device?.id;
  const workstationId = workstation?.id;

  const eventTypesFilteredByWorkstation = eventTypes?.filter(({ workstation }) => {
    return parseInt(workstation?.id) === workstationId;
  });

  const eventTypesFilteredByDeviceWithoutWorkstation = eventTypes.filter(({ device, workstation }) => {
    return parseInt(device?.id) === deviceId && !workstation;
  });

  let filteredEventTypes = [...eventTypesFilteredByWorkstation, ...eventTypesFilteredByDeviceWithoutWorkstation];

  if (considerUsage) {
    filteredEventTypes = filteredEventTypes.filter((eventType) => eventType.is_usage === true);
  }

  return filteredEventTypes.map((eventType) => parseInt(eventType.subtype));
};

/*
 * Filters an array of logs to remove logs related to human presence in an area.
 * Returns the filtered array of logs.
 */
export const filterArrayWithoutHumanLogs = (logs) => {
  return logs.filter(
    (log) =>
      log.subtype !== LOGS_COMMON_SUBTYPES.HUMAN_IN_AREA && log.subtype !== LOGS_COMMON_SUBTYPES.NO_HUMAN_IN_AREA,
  );
};

/*
 * Filters an array of logs to include only logs related to human presence in an area based on the provided view.
 * Returns the filtered array of logs.
 */
export const filterArrayOnHumanLogs = (logs, view) => {
  return logs.filter(
    (log) =>
      view === 'DISPLAY_ALL' &&
      (log.subtype === LOGS_COMMON_SUBTYPES.HUMAN_IN_AREA || log.subtype === LOGS_COMMON_SUBTYPES.NO_HUMAN_IN_AREA),
  );
};
