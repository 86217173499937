import clsx from 'clsx';
import { forwardRef } from 'react';

export const Input = forwardRef(function InputHiddenLabel(
  { category = 'primary', className, name, placeholder, label, ...props },
  ref,
) {
  const baseClass = 'block w-full border-0 outline-none shadow-sm py-2 px-2 placeholder:text-gray-400 m-2 sm:text-sm';

  const getInputStyle = () => {
    if (category === 'primary') {
      return 'rounded-md  text-perception-black-800 ring-1 ring-inset ring-gray-300 sm:leading-6';
    } else if (category === 'secondary') {
      return 'bg-perception-gray-800 text-white';
    }
  };
  return (
    <div>
      <label htmlFor={name} className={clsx(label ? '' : 'sr-only', 'text-white ml-2 capitalize')}>
        {label}
      </label>
      <input
        id={name}
        ref={ref}
        name={name}
        className={clsx(baseClass, getInputStyle(), className)}
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
});

Input.displayName = 'Input';
