import { useMemo } from 'react';
import { useOrgData } from '../../../../contexts/OrgDataContext';
import { addItemToCache } from '../../../../services/api/api-tools';
import { Pattern } from '../../../../components/logs/Pattern';
import { HoverTextButton } from '../../../../components/buttons/HoverTextButton';
import { PlusIcon } from '@heroicons/react/20/solid';
import { usePatternSetup } from '../../../../contexts/PatternSetupContext';
import { createPattern } from '../../../../services/entity/pattern/pattern-api';
import { useQueryClient } from 'react-query';

export const PatternList = ({ setIsWarnModalOpen, setPatternIdToDelete }) => {
  const { workstationSelected } = useOrgData();
  const {
    patterns,
    patternsQueryKey,
    isPatternPanelOpened,
    setIsPatternPanelOpened,
    loadEditingPattern,
    cleanEditingPattern,
  } = usePatternSetup();

  const queryClient = useQueryClient();

  const patternsWorkstation = useMemo(() => {
    if (patterns?.length) {
      return patterns.filter((pattern) => pattern.workstation?.id === workstationSelected?.id);
    } else {
      return [];
    }
  }, [patterns, workstationSelected]);

  const handlePatternCreation = async () => {
    if (isPatternPanelOpened) {
      setIsPatternPanelOpened(false);
      cleanEditingPattern();
    } else {
      const newPattern = await createPattern(patterns, workstationSelected);
      loadEditingPattern(newPattern);
      addItemToCache(queryClient, patternsQueryKey, newPattern);
      setIsPatternPanelOpened(true);
    }
  };

  return (
    <div className='flex flex-col w-full h-full group/btn'>
      <div className='flex flex-col'>
        {patternsWorkstation.map((pattern) => {
          return (
            <Pattern
              key={pattern.id}
              pattern={pattern}
              setIsWarnModalOpen={setIsWarnModalOpen}
              setPatternIdToDelete={setPatternIdToDelete}
            />
          );
        })}
        <div className='mt-4'>
          <HoverTextButton onClick={handlePatternCreation} disabled={isPatternPanelOpened}>
            <PlusIcon className='w-4 h-4' />
            <span className='pl-2'>Create a new pattern</span>
          </HoverTextButton>
        </div>
      </div>
    </div>
  );
};
