import jwtDecode from 'jwt-decode';
import { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { getUserDataByEmail } from '../services/entity/user/user-api';
import { isTokenExpired, setCurrentTokenInBrowserStorage } from '../helpers/token/token-helper';
import { useNavigate } from 'react-router-dom';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);

  const getUserData = async () => {
    setIsUserDataLoading(true);
    const token = localStorage.getItem('token');
    try {
      if (token) {
        if (!isTokenExpired(token)) {
          const payload = jwtDecode(token);
          const userData = await getUserDataByEmail(token, payload.username);
          setUser(userData);
        } else {
          localStorage.removeItem('token');
        }
      }
    } catch (err) {
      localStorage.removeItem('token');
    } finally {
      setIsUserDataLoading(false);
    }
  };

  const updateUser = (newUser) => {
    setUser(newUser.user);
    setCurrentTokenInBrowserStorage(newUser.token);
  };

  const logoutUser = () => {
    navigate('/production');
    setUser(null);
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    window.location.reload();
  };

  useEffect(() => {
    getUserData();
  }, []);

  const value = useMemo(
    () => ({ user, updateUser, logoutUser, setUser, isUserDataLoading }),
    [user, updateUser, logoutUser, setUser, isUserDataLoading],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser doit être utilisé au sein d'un UserProvider");
  }
  return context;
};
