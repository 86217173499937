import { Controller, useForm } from 'react-hook-form';
import { ProfileForm } from './ProfileForm';
import { useState } from 'react';
import { changeUserPassword } from '../../../services/entity/user/user-api';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Input } from '../../../components/forms/inputs/Input';
import { useAlert } from '../../../contexts/AlertContext';
import { Loader } from '../../../components/loading/Loader';

export const UpdateUserPassword = (props) => {
  const { user } = props;

  const { showAlert } = useAlert();

  const { handleSubmit, control, reset } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isFormLoading, setIsFormLoading] = useState(false);

  /* Form submission */
  const onSubmit = async (data) => {
    if (data.new_password !== data.confirm_password) {
      setErrorMessage('New passwords are not identical');
    } else {
      setIsFormLoading(true);
      await changeUserPassword(data)
        .then((newData) => {
          if (newData.title && newData.title === 'An error occurred') {
            setErrorMessage(newData.detail);
          } else {
            showAlert('success', 'Your password has been updated with success');
            setErrorMessage(null);
            reset();
          }
          setIsFormLoading(false);
        })
        .catch(() => {
          showAlert('error', 'An error occurred while updating your password');
          setIsFormLoading(false);
        });
    }
  };

  return (
    <ProfileForm
      title='Change password'
      isLoading={false}
      isError={false}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      buttonIcon={CheckIcon}
      buttonLabel='Update my password'
    >
      {isFormLoading ? <Loader /> : ''}
      <Controller
        control={control}
        name='id'
        defaultValue={user.id}
        render={({ field }) => <input type='hidden' {...field} />}
      />
      <Controller
        control={control}
        name='old_password'
        rules={{ required: true }}
        defaultValue=''
        render={({ field }) => (
          <Input
            label='Old password'
            htmlFor='old password'
            type='password'
            id='old password'
            category='secondary'
            placeholder='Your old password'
            isLabelHidden={false}
            {...field}
          />
        )}
      />
      <div />
      <Controller
        control={control}
        name='new_password'
        rules={{ required: true }}
        defaultValue=''
        render={({ field }) => (
          <Input
            label='New password'
            htmlFor='new password'
            type='password'
            id='new password'
            category='secondary'
            placeholder='Your new password'
            isLabelHidden={false}
            {...field}
          />
        )}
      />
      <div />
      <Controller
        control={control}
        name='confirm_password'
        rules={{ required: true }}
        defaultValue=''
        render={({ field }) => (
          <Input
            label='Confirm password'
            htmlFor=''
            type='password'
            id='confirm password'
            category='secondary'
            placeholder='Confirm your new password'
            isLabelHidden={false}
            {...field}
          />
        )}
      />
      {errorMessage && <p className='text-error text-sm'>{errorMessage}</p>}
    </ProfileForm>
  );
};
