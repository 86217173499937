import clsx from 'clsx';

export const Button = ({ size = 'medium', category, disabled, className, children, ...props }) => {
  const disabledClass = disabled ? 'disabled' : '';

  return (
    <button
      className={clsx('perception-btn', category, size, 'text-lg', disabledClass, className)}
      {...props}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
