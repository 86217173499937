import { useRef } from 'react';
import { usePatternSetup } from '../../../../../contexts/PatternSetupContext';

export const CommentaryPattern = () => {
  const { patternCommentary, setPatternCommentary, setIsPatternTextOnEdit } = usePatternSetup();

  const textAreaRef = useRef(null);

  const handleCommentaryChange = (event) => {
    setIsPatternTextOnEdit(true);
    setPatternCommentary(event.target.value);
  };

  const handleCommentaryBlur = () => {
    setIsPatternTextOnEdit(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      textAreaRef.current.blur();
    }
  };

  return (
    <div className='mt-12 text-perception-gray-500'>
      <span className='text-md font-semibold mt-4'>COMMENTARY</span>
      <div className='mt-4'>
        <textarea
          name='pattern commentary area'
          className='w-full bg-perception-gray-800 p-2 focus:outline-none'
          placeholder='Write your commentary here...'
          value={patternCommentary}
          onChange={handleCommentaryChange}
          onBlur={handleCommentaryBlur}
          onKeyDown={handleKeyDown}
          ref={textAreaRef}
        ></textarea>
      </div>
    </div>
  );
};
