import { ArrowPathIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../../../../components/buttons/Button';
import { Modal } from '../../../Modal';
import { useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const StatusModal = ({ specification, setIsEditingError, setIsViewMode }) => {
  const { state } = specification;

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/setup');
  };

  const handleClickEdit = async () => {
    setIsViewMode(true);
  };

  const downloadImage = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
  };

  const isPrivateNetwork = process.env.REACT_APP_CLIENT_PRIVATE_NETWORK === '1';

  const exportSpecification = async () => {
    const zip = new JSZip();
    const imagesFolder = zip.folder('images');

    const situationsCsvContent = ['id_situation,id_specification,name'];
    const framesCsvContent = ['frame_id,situation_id,filename, bbox'];
    const annotationsCsvContent = ['annotation_id,frame_id,title,content,position_x,position_y'];

    const situationsPromises = specification.specification_situations.map((situation) => {
      const row = [situation.id, specification.id, situation.name].join(',');
      situationsCsvContent.push(row);
    });

    await Promise.all(situationsPromises);

    const framesPromises = specification.specification_situations.flatMap((situation) =>
      situation.specification_frames.map(async (frame) => {
        // Add image to the zip
        const imageUrl = `${process.env.REACT_APP_PROXY_FILE_URL}${frame.filename}`;
        const imageBlob = await downloadImage(imageUrl);
        imagesFolder.file(frame.filename, imageBlob);

        const bboxString = JSON.stringify(frame.bbox);
        const quotedBboxString = `"${bboxString}"`;

        const row = [frame.id, situation.id, frame.filename, quotedBboxString].join(',');
        framesCsvContent.push(row);
      }),
    );

    await Promise.all(framesPromises);

    const annotationsPromises = specification.specification_situations.flatMap((situation) =>
      situation.specification_frames.flatMap((frame) =>
        frame.specification_annotations.map(async (annotation) => {
          const position = annotation.position || {};
          const positionX = position.x || '';
          const positionY = position.y || '';
          const row = [annotation.id, frame.id, annotation.title, annotation.content, positionX, positionY].join(',');
          annotationsCsvContent.push(row);
        }),
      ),
    );

    await Promise.all(annotationsPromises);

    zip.file('situations.csv', situationsCsvContent.join('\n'));
    zip.file('frames.csv', framesCsvContent.join('\n'));
    zip.file('annotations.csv', annotationsCsvContent.join('\n'));

    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `specification_${specification.id}.zip`);
    });
  };

  if (specification.error) {
    /* ERROR MODAL */
    return (
      <Modal type='error' className='gap-2'>
        <h2 className='text-2xl font-semibold'>AI Training : Process Failed</h2>
        <p className='pt-4 text-md'>
          Below, youll find the details of the errors. You can retry annotating with your previous settings, and well
          display these errors directly within the annotation space.
        </p>
        <ul className='pl-6 list-disc text-perception-gray-300'>
          <li>Several images in your dataset were too blurry, making it difficult for the AI to learn effectively.</li>
          <li>
            Some images contained objects that were partially obstructed, preventing the AI from correctly identifying
            them.
          </li>
          <li>There were inconsistencies in the annotations, with some objects mislabeled or not labeled at all.</li>
          <li>The dataset lacked sufficient variety, leading to overfitting and poor generalization.</li>
        </ul>
        <div className='flex gap-2 pt-6'>
          <Button size='small' category='tertiary-btn' onClick={() => setIsEditingError(true)}>
            Edit specification
          </Button>
          <Button size='small' category='secondary-btn' onClick={handleBack}>
            Back to app
          </Button>
        </div>
        <ExclamationCircleIcon className='absolute w-10 h-10 text-perception-error-500 right-6 top-6' />
      </Modal>
    );
  } else if (state === 3) {
    /* WAITING MODAL */
    return (
      <Modal type='info' className='gap-2 w-[750px]'>
        <h2 className='text-2xl font-semibold'>AI Training : Learning in Progress</h2>
        <p className='pt-4 text-md'>
          {isPrivateNetwork
            ? 'In order for us to study your new specifications, please download the data below and send us the generated file by email.'
            : '   Please wait while our AI learns from your annotations. You will receive a notification once the model is ready.'}
        </p>
        <div className='flex justify-end gap-20 pt-6'>
          <div className='flex flex-col items-center'>
            {isPrivateNetwork ? (
              <div className='flex items-center gap-2'>
                <Button category='tertiary-btn' size='small' onClick={exportSpecification}>
                  Export data
                </Button>
                <Button category='secondary-btn' size='small' onClick={handleBack}>
                  Back to app
                </Button>
              </div>
            ) : (
              <Button category='secondary-btn' size='small' onClick={handleBack}>
                Back to app
              </Button>
            )}
          </div>
        </div>
        <ArrowPathIcon className='absolute w-8 h-8 text-perception-blue right-6 top-6' />
      </Modal>
    );
  } else if (state === 4) {
    /* SUCCESS MODAL */
    return (
      <Modal type='success' className='gap-2'>
        <h2 className='text-2xl font-semibold'>AI Training : Successful</h2>
        <p className='pt-4 text-md'>
          The AI has successfully completed its training and can now efficiently locate the necessary information.
        </p>
        <ul className='pl-6 list-disc'>
          <li>Track the progress of specific training sessions on the setup page.</li>
          <li>Request new devices from our team.</li>
          <li>For any additional support or inquiries, please contact us directly.</li>
        </ul>
        <div className='flex w-full gap-2 pt-6'>
          <Button size='small' category='tertiary-btn' onClick={handleBack}>
            Back to app
          </Button>
          <Button size='small' category='secondary-btn' onClick={handleClickEdit}>
            See specification
          </Button>
          <div className='self-end ml-auto'>
            {isPrivateNetwork && (
              <Button category='tertiary-btn' size='small' onClick={exportSpecification}>
                Export data
              </Button>
            )}
          </div>
        </div>
        <CheckCircleIcon className='absolute w-10 h-10 text-perception-success-600 right-6 top-6' />
      </Modal>
    );
  }
};
