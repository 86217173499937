import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import { useDashboard } from '../../../../contexts/DashboardContext';
import { formatSecondsDuration } from '../../../../helpers/date/date-helper';

export const SummaryBlock = ({ chartData }) => {
  const { name, value, trend, type } = chartData;
  const { selectedDateRange } = useDashboard();

  const isPositive = trend >= 0;
  const colorClass = isPositive ? 'text-green-500' : 'text-red-500';

  const renderValue = () => {
    if (!value) {
      return 0;
    }

    if (type === 'value_duration') {
      return formatSecondsDuration(value);
    } else {
      return value;
    }
  };

  return (
    <div className='flex flex-col justify-start flex-grow py-3 px-4 border-perception-black-900 border-r last:border-none'>
      <span className='text-md'>{name}</span>
      <div className='flex justify-start text-start cursor-default'>
        <span className='text-xl font-semibold'>{renderValue()}</span>
        {trend && (
          <div className={`group/evolution relative flex items-center ml-4 ${colorClass}`}>
            {trend}%{isPositive ? <ArrowUpIcon className='h-4 w-4 ml-1' /> : <ArrowDownIcon className='h-4 w-4 ml-1' />}
            <span className='absolute top-full whitespace-nowrap left-1/2 transform -translate-x-1/2 invisible group-hover/evolution:visible bg-perception-black-500 text-xs text-white py-2 px-4 rounded-sm shadow-lg'>
              Compared to previous {selectedDateRange.name.toLowerCase()}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
