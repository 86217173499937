import { QuickLinks } from './QuickLinks';

export const ActionCenter = () => {
  return (
    <div className='grow bg-perception-black-800 h-full text-white'>
      <div className='font-semibold border-b border-perception-black-900 p-4'>Action center</div>
      <div className='flex flex-col'>
        <ActionItem title='Maintenance operation detected' sub='10 minutes ago' count={1} />
        <ActionItem title='Camera obstructed' sub='20 minutes ago' count={null} />
        <ActionItem title='Human detected' sub='30 minutes ago' count={6} />
      </div>
      <QuickLinks />
    </div>
  );
};

const ActionItem = ({ title, sub, count }) => {
  return (
    // <div className="flex w-full border-b border-perception-black-900 justify-between items-center px-4 py-4">
    <div className='flex justify-between items-center p-4'>
      <div className='flex flex-col'>
        <span className='text-white text-md'>{title}</span>
        <span className='text-perception-gray-500 text-sm'>{sub}</span>
      </div>
      {count && (
        <div className='bg-perception-blue w-6 h-6 flex justify-center items-center'>
          <span className='text-white'>{count}</span>
        </div>
      )}
    </div>
  );
};
