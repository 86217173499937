import { SpinnerIcon } from './SpinnerIcon';

export const FullPageLoading = () => {
  return (
    <div className='h-full w-full fixed flex justify-center items-center top-0 left-0 z-50 bg-black bg-opacity-70 backdrop-blur-sm'>
      <div className='flex flex-col justify-center items-center bg- p-8 max-w-3/4 bg-[#121212]'>
        <div className='flex flex-col items-center'>
          <div className='text-white text-lg font-semibold text-center'>Connecting to the Lineware Cloud Engine...</div>
          <div className='pt-6'>
            <SpinnerIcon />
          </div>
        </div>
      </div>
    </div>
  );
};
