import jwtDecode from 'jwt-decode';

/**
 * Vérifie si un token JWT est expiré.
 *
 * @param {string} token - Le token JWT à vérifier.
 * @returns {boolean} - Retourne `true` si le token est expiré, sinon `false`.
 */
export const isTokenExpired = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const expirationDate = decodedToken.exp * 1000;
    return Date.now() > expirationDate;
  } catch (error) {
    return true;
  }
};

/**
 *  Set user's token in browser storage
 */
export const setCurrentTokenInBrowserStorage = (token) => {
  localStorage.setItem('token', token);
  sessionStorage.setItem('token', token);
};
