import { convertDateToTimestamp } from '../../helpers/date/date-helper';

// Rewriting in datasets to add custom data
export const addHiddenLegendDataset = (formattedDataset, parsedData) => {
  formattedDataset.forEach((dataset) => {
    dataset.datasets.unshift({
      label: 'Hidden Legend Dataset',
      extraData: parsedData.map((data) => ({
        name: data.comment,
        date: convertDateToTimestamp(data.startdate),
        duration: convertDateToTimestamp(data.enddate) - convertDateToTimestamp(data.startdate),
      })),
    });
  });
};
