import { Button } from '../../../../../../components/buttons/Button';
import { Loader } from '../../../../../../components/loading/Loader';
import { useVideoDeviceStream } from '../../../../../../hooks/useVideoDeviceStream';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { generateRandomString } from '../../../../../../helpers/common/generateRandomString';
import { calculateDisplayStreamSizes } from '../../../../../../services/engine/engine-service';
import { useResizeDetector } from 'react-resize-detector';
import { useQueryClient } from 'react-query';

export const CreationSnapshot = ({
  specification,
  closeSituationModal,
  setSelectedSituation,
  specificationQueryKey,
}) => {
  const {
    videoRef,
    frameSizes,
    isReceivingFrames,
    setIsUserRecording,
    originalFramesRecorded,
    setOriginalFramesRecorded,
  } = useVideoDeviceStream(specification.device.stream_id);

  const [snapshot, setSnapshot] = useState(null);

  const { width, height, ref: imageContainerRef } = useResizeDetector();

  const displaySizes = useMemo(() => {
    const { width: frameWidth, height: frameHeight, ratio: frameRatio } = frameSizes;
    return calculateDisplayStreamSizes(frameWidth, frameHeight, frameRatio, width, height);
  }, [width, height, frameSizes, videoRef.current]);

  return (
    <Fragment>
      <div className='relative h-[80vh] w-[90vw] max-w-full max-h-full mx-auto my-4'>
        {/* SNAPSHOT */}
        {snapshot?.img && (
          <div className='flex flex-col items-center justify-center w-full h-full'>
            <div style={displaySizes} className='object-cover'>
              <img src={snapshot.img} alt='Snapshot' className='w-full h-full' />
            </div>
          </div>
        )}
        {/* STREAM CAMERA */}
        <div className={`h-full w-full flex flex-col ${snapshot ? 'invisible' : 'visible'}`}>
          <div className='flex flex-col items-center justify-center flex-auto overflow-hidden' ref={imageContainerRef}>
            {!isReceivingFrames ? <Loader category='transparent' /> : null}
            <div style={displaySizes} className='relative'>
              <div className='relative w-full h-full'>
                <canvas className='w-full h-full' ref={videoRef}></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* MENU */}
      <div className={`flex justify-between ${isReceivingFrames ? 'visible' : 'hidden'} bg-perception-black-800 p-4`}>
        <Button size='small' category='variant-btn' className='mt-0' onClick={closeSituationModal}>
          CANCEL
        </Button>
        <MenuSnapshot
          specification={specification}
          videoRef={videoRef}
          snapshot={snapshot}
          setSnapshot={setSnapshot}
          closeSituationModal={closeSituationModal}
          setSelectedSituation={setSelectedSituation}
          specificationQueryKey={specificationQueryKey}
          setIsUserRecording={setIsUserRecording}
          originalFramesRecorded={originalFramesRecorded}
          setOriginalFramesRecorded={setOriginalFramesRecorded}
        />
      </div>
    </Fragment>
  );
};

const MenuSnapshot = ({
  specification,
  videoRef,
  snapshot,
  setSnapshot,
  closeSituationModal,
  setSelectedSituation,
  specificationQueryKey,
  setIsUserRecording,
  originalFramesRecorded,
  setOriginalFramesRecorded,
}) => {
  const queryClient = useQueryClient();

  const handleContinueWithImage = async () => {
    const lastSnapshot = originalFramesRecorded[originalFramesRecorded.length - 1];
    if (!lastSnapshot || !lastSnapshot.img) return;

    const uniqueFilename = `snapshot_${generateRandomString(10)}.jpg`;
    const snapshot = lastSnapshot.img;
    const boundingBox = lastSnapshot.boundingBox;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = snapshot.naturalWidth;
    canvas.height = snapshot.naturalHeight;
    context.drawImage(snapshot, 0, 0);

    const blob = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });

    const formData = new FormData();
    formData.append(`frame_0`, blob, uniqueFilename);
    formData.append(`boundingBox_0`, JSON.stringify(boundingBox));
    formData.append('specification_id', specification.id);

    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_ADDRESS}specification_situations`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const situationCreated = await response.json();

      queryClient.setQueryData(specificationQueryKey, (oldData) => {
        if (oldData && oldData.length > 0) {
          const updatedData = {
            ...oldData[0],
            specification_situations: [...oldData[0].specification_situations, situationCreated],
          };
          return [updatedData];
        }
        return oldData;
      });

      setSelectedSituation(situationCreated);
      closeSituationModal();
    } catch (error) {
      console.error('Error while creating situation:', error.message);
    }
  };

  const handleClickSnapshot = () => {
    setIsUserRecording(false);
    const canvas = videoRef.current;
    if (canvas) {
      const dataUrl = canvas.toDataURL('image/jpeg');
      setSnapshot({
        object_id: generateRandomString(5),
        img: dataUrl,
      });
    }
  };

  const handleResetSnapshot = () => {
    setSnapshot(null);
    setOriginalFramesRecorded([]);
    setIsUserRecording(true);
  };

  useEffect(() => {
    setIsUserRecording(true);
  }, []);

  if (snapshot?.img) {
    return (
      <div className='flex gap-2'>
        <Button size='small' category='variant-btn' onClick={handleResetSnapshot} className='mt-0'>
          TAKE A NEW SNAPSHOT
        </Button>
        <Button size='small' category='tertiary-btn' onClick={handleContinueWithImage} className='mt-0'>
          CONTINUE WITH THIS IMAGE
        </Button>
      </div>
    );
  } else {
    return (
      <Button size='small' category='tertiary-btn' onClick={handleClickSnapshot} className='mt-0'>
        TAKE SCREENSHOT
      </Button>
    );
  }
};
