import { Input } from './Input';

export const InputWithError = ({
  category,
  className,
  name,
  placeholder,
  errors,
  fieldName,
  register,
  validate,
  ...props
}) => {
  return (
    <div className='relative flex flex-col justify-start'>
      {errors[fieldName] && <span className='text-perception-error-800 text-sm pl-2'>{errors[fieldName].message}</span>}
      <Input
        category={category}
        className={className}
        name={name}
        placeholder={placeholder}
        {...register(fieldName, {
          validate: validate ? validate : undefined,
        })}
        {...props}
      />
    </div>
  );
};
