import { useNavigate } from 'react-router-dom';
import { Button } from '../../../../../../components/buttons/Button';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';
import { ProgressBar } from '../components/ProgressBar';

export const ActionPanel = ({
  isViewMode,
  handleSpecificationSubmission,
  setIsCommentModalOpen,
  setIsSituationModalOpen,
  specification,
  specificationErrors,
}) => {
  const navigate = useNavigate();

  const { setClickedPixel, setMarkerHovered } = useAnnotation();

  const handleBack = () => {
    navigate('/setup');
  };

  const handleCloseAnnotationModal = () => {
    setClickedPixel(null);
    setMarkerHovered(null);
  };

  const handleOpenCommentModal = () => {
    handleCloseAnnotationModal();
    setIsCommentModalOpen(true);
  };

  const handleOpenSituationModal = () => {
    setIsCommentModalOpen(false);
    handleCloseAnnotationModal();
    setIsSituationModalOpen(true);
  };

  return (
    <div className='flex flex-col justify-center h-full gap-6'>
      <ProgressBar specification={specification} specificationErrors={specificationErrors} />
      <div />
      {!isViewMode ? (
        <div className='flex flex-col gap-1'>
          <Button size='small' category='variant-btn' onClick={handleOpenCommentModal}>
            ADD A NEW COMMENT
          </Button>
          <Button size='small' category='variant-btn' onClick={handleOpenSituationModal}>
            ADD A NEW SITUATION
          </Button>
          <Button size='small' category='tertiary-btn' onClick={handleSpecificationSubmission}>
            VALIDATE THIS SPECIFICATIONS
          </Button>
        </div>
      ) : (
        <div className='flex flex-col gap-1'>
          <Button size='small' category='variant-btn' onClick={handleBack}>
            BACK TO APP
          </Button>
        </div>
      )}
    </div>
  );
};
