import { Switch } from '@headlessui/react';
import { handleClassNames } from '../../helpers/common/handleClassNames';

export const Toggle = (props) => {
  const { label, isLeftLabel, enabled, setEnabled } = props;

  return (
    <Switch.Group as='div' className='flex items-center'>
      {isLeftLabel && (
        <Switch.Label as='div' className='mr-3 flex items-center'>
          <div className='text-sm font-normal text-perception-gray-500'>{label}</div>
        </Switch.Label>
      )}
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={handleClassNames(
          enabled ? 'bg-perception-blue' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
        )}
      >
        <span
          aria-hidden='true'
          className={handleClassNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'grow-0 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
      {!isLeftLabel && (
        <Switch.Label as='div' className='ml-3 flex items-center'>
          <span className='text-sm font-medium text-perception-blue'>{label}</span>
        </Switch.Label>
      )}
    </Switch.Group>
  );
};
