import { TrialLiveProvider } from '../../contexts/TrialLiveContext';
import { LeftPanel } from './components/panels/left-panel/LeftPanel';
import { MainPanel } from './components/panels/main-panel/MainPanel';
import { RightPanel } from './components/panels/right-panel/RightPanel';
import { useEffect } from 'react';
import { useTrialSetupContext } from '../../contexts/TrialSetupContext';
import { isFreeUser, isTeamUser } from '../../services/entity/user/user-service';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router';
import { LoadingOverlay } from './components/LoadingOverlay';

export const TrialLivePage = () => {
  const { user } = useUser();
  const { isAccessTimeExceeded, setIsCameraReady } = useTrialSetupContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) return;
    if (!isFreeUser(user) && !isTeamUser(user)) {
      navigate('production');
    }
  }, [user]);

  useEffect(() => {
    if (isAccessTimeExceeded) {
      setIsCameraReady(false);
    }
  }, [isAccessTimeExceeded]);

  useEffect(() => {
    return () => {
      setIsCameraReady(false);
    };
  }, []);

  return (
    <TrialLiveProvider>
      <LoadingOverlay />
      <div id='targets-page' className='flex w-full h-full'>
        <div className='flex w-2/6 h-full xl:w-2/6 2xl:w-1/6'>
          <LeftPanel />
        </div>
        <div className='flex w-3/6 h-full xl:w-3/6 2xl:w-4/6 '>
          <MainPanel />
        </div>
        <div className='flex w-2/6 h-full xl:w-2/6 2xl:w-1/6'>
          <RightPanel />
        </div>
      </div>
    </TrialLiveProvider>
  );
};
