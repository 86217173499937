import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useUser } from './UserContext';
import { useOrgData } from './OrgDataContext';
import { instantiateEngine, keepAliveEngine } from '../services/engine/engine-api';
import { useTrialSetupContext } from './TrialSetupContext';
import { useTargetsByDeviceId } from '../services/entity/target/target-api';

const SetupContext = createContext();

export const useTrialLiveContext = () => {
  return useContext(SetupContext);
};

export const TrialLiveProvider = ({ children }) => {
  const { user } = useUser();
  const { workshopSelected } = useOrgData();
  const { videoRef, isCameraReady } = useTrialSetupContext();

  const [isEngineRunning, setIsEngineRunning] = useState(false);
  const [engineErrorCode, setEngineErrorCode] = useState(0);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [isDefaultWorkshopSelected, setIsDefaultWorkshopSelected] = useState(false);

  const [isViewLoadingData, setIsViewLoadingData] = useState(false);

  const [newTargets, setNewTargets] = useState([]);

  const [cameras, setCameras] = useState([]);

  /* States used for target creation - Need to be reset for each new target */
  const [isCreatingNewTarget, setIsCreatingNewTarget] = useState(false);
  const [newTargetName, setNewTargetName] = useState(null);
  const [snapshot, setSnapshot] = useState(null);
  const [clickedPoints, setClickedPoints] = useState([]);
  const [clickedNativePoints, setClickedNativePoints] = useState([]);
  const [masksLayer, setMasksLayer] = useState(null);

  const device = workshopSelected?.devices?.length
    ? workshopSelected.devices.reduce(
        (min, current) => (current.id < min.id ? current : min),
        workshopSelected.devices[0],
      )
    : undefined;

  console.log('[DEBUG] Device ID', device?.id);

  const { data: targets, queryKey: targetsQueryKey } = useTargetsByDeviceId(device?.id);

  useEffect(() => {
    if (!isCreatingNewTarget) {
      console.log('[DEBUG] Reset setup context');
      setIsCreatingNewTarget(false);
      setNewTargetName('NEW TARGET');
      setSnapshot(null);
      setClickedPoints([]);
      setClickedNativePoints([]);
      setMasksLayer(null);
    }
  }, [isCreatingNewTarget]);

  useEffect(() => {
    let intervalId;

    const initializeEngine = async () => {
      if (user && isCameraReady && device?.stream_id && isDefaultWorkshopSelected) {
        try {
          const { success, error_code } = await instantiateEngine(device.stream_id);
          console.log('[DEBUG] Instantiate engine');
          setIsEngineRunning(success);
          setEngineErrorCode(error_code);

          if (success) {
            intervalId = setInterval(async () => {
              try {
                const { success, error_code } = await keepAliveEngine(device.stream_id);
                console.log('[DEBUG] Keep engine alive');
                setIsEngineRunning(success);
                setEngineErrorCode(error_code);
              } catch (error) {
                console.error('Failed to keep alive engine : ', error);
              }
            }, 60000);
          }
        } catch (error) {
          console.error('Something happened to engine : ', error);
        }
      }
    };

    initializeEngine();

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [user, isCameraReady, device, isDefaultWorkshopSelected]);

  const value = useMemo(
    () => ({
      isEngineRunning,
      setIsEngineRunning,
      engineErrorCode,
      setEngineErrorCode,
      isSocketConnected,
      setIsSocketConnected,
      isCreatingNewTarget,
      setIsCreatingNewTarget,
      newTargetName,
      setNewTargetName,
      snapshot,
      setSnapshot,
      masksLayer,
      setMasksLayer,
      clickedPoints,
      clickedNativePoints,
      setClickedPoints,
      setClickedNativePoints,
      videoRef,
      isViewLoadingData,
      setIsViewLoadingData,
      newTargets,
      setNewTargets,
      cameras,
      setCameras,
      targets,
      targetsQueryKey,
      device,
      isDefaultWorkshopSelected,
      setIsDefaultWorkshopSelected,
    }),
    [
      isEngineRunning,
      setIsEngineRunning,
      engineErrorCode,
      setEngineErrorCode,
      isSocketConnected,
      setIsSocketConnected,
      isCreatingNewTarget,
      setIsCreatingNewTarget,
      newTargetName,
      setNewTargetName,
      snapshot,
      setSnapshot,
      masksLayer,
      setMasksLayer,
      clickedPoints,
      clickedNativePoints,
      setClickedPoints,
      setClickedNativePoints,
      videoRef,
      isViewLoadingData,
      setIsViewLoadingData,
      newTargets,
      setNewTargets,
      cameras,
      setCameras,
      targets,
      targetsQueryKey,
      device,
      isDefaultWorkshopSelected,
      setIsDefaultWorkshopSelected,
    ],
  );

  return <SetupContext.Provider value={value}>{children}</SetupContext.Provider>;
};
