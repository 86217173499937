import 'react-datepicker/dist/react-datepicker.css';
import { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAlert } from '../../../../../contexts/AlertContext';
import { Loader } from '../../../../../components/loading/Loader';
import { useOrgData } from '../../../../../contexts/OrgDataContext';
import { useUser } from '../../../../../contexts/UserContext';
import { patchData, postData } from '../../../../../services/api/api-tools';
import { Button } from '../../../../../components/buttons/Button';

const alertPeriodTypes = [
  { id: 1, name: 'In 24 hours', value: 24 * 3600 },
  { id: 2, name: 'In a week', value: 7 * 24 * 3600 },
  { id: 3, name: 'In 14 days', value: 14 * 24 * 3600 },
  { id: 4, name: 'In a month', value: 30 * 24 * 3600 },
];

export const ModalManageAlert = ({
  alert = null,
  alerts,
  setAlerts,
  isAlertModalOpen,
  setIsAlertModalOpen,
  setAlertOnEdit,
}) => {
  const { showAlert } = useAlert();
  const { user } = useUser();
  const { workstationSelected, eventTypes } = useOrgData();

  const [isAlertLoaded, setIsAlertLoaded] = useState(false);

  // Entity fields
  const [name, setName] = useState('');
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [occurrences, setOccurrences] = useState(0);

  const [error, setError] = useState('');

  // Build body for PATCH or POST request
  const createRequestBody = () => {
    if (selectedEventType === null) throw new Error('No event type selected');
    if (selectedPeriod === null) throw new Error('No period selected');

    return {
      name: name,
      period: alertPeriodTypes.find((type) => type.id === selectedPeriod.id).value,
      occurrences: occurrences,
      user: `/api/users/${user.id}`,
      eventType: `/api/event_types/${selectedEventType.id}`,
      workstation: `/api/workstations/${workstationSelected.id}`,
    };
  };

  // Create a new alarm in database
  const handleCreate = async () => {
    const body = createRequestBody();

    try {
      if (name.length > 1) {
        const response = await postData('alerts', body);
        if (response?.id) {
          showAlert('success', 'Alert has been successfully saved on database.');
          setIsAlertModalOpen(false);
          setAlerts([...alerts, response]);
        } else {
          showAlert('error', 'Oops! Something went wrong while attempting to save the alert.');
          setIsAlertModalOpen(false);
        }
      } else {
        setError('Oops! It looks like you forgot to set the name for the alert. ');
      }
    } catch (err) {
      console.error('Something wrong happened:', err);
      showAlert('error', 'Oops! Something went wrong while attempting to save the alert.');
    }
  };

  // Edit an existing alarm
  const handleEdit = async () => {
    const body = createRequestBody();

    try {
      let response = await patchData(`alerts/${alert.id}`, body);
      if (response) {
        setIsAlertModalOpen(false);
        setAlerts(alerts.map((a) => (a.id === alert.id ? response : a)));
      }
    } catch (err) {
      console.error('Something wrong happened:', err);
      showAlert('error', 'Oops! Something went wrong while attempting to edit the alert.');
    } finally {
      setAlertOnEdit(null);
    }
  };

  useEffect(() => {
    if (!eventTypes || eventTypes.length === 0) return;

    // if alert is not null, we are on edition mode
    if (alert) {
      setName(alert.name);

      /* Event type */
      const eventType = eventTypes.find((eventType) => eventType.id === alert.event_type.id);
      setSelectedEventType(eventType);

      /* Period */
      const period = alertPeriodTypes.find((period) => period.value === parseInt(alert.period));
      setSelectedPeriod(period);

      /* Occurrences */
      setOccurrences(alert.occurrences);

      setIsAlertLoaded(true);
    } else {
      // else we are on creation mode
      setSelectedEventType(eventTypes[0].content);

      setName('');
      setSelectedEventType(eventTypes[0]);
      setSelectedPeriod(alertPeriodTypes[0]);
      setOccurrences(1);

      setIsAlertLoaded(true);
    }
  }, [alert, eventTypes]);

  if (!isAlertLoaded) return <Loader category='transparent' />;

  return (
    <Transition.Root show={isAlertModalOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={setIsAlertModalOpen}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black backdrop-blur-sm bg-opacity-50 transition-opacity' />
        </Transition.Child>
        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='transform bg-white text-left shadow-xl transition-all w-[450px] rounded-md'>
                <h1 className='bg-perception-gray-100 p-4 text-lg font-semibold text-center cursor-default rounded-t-md'>
                  Create a new alert
                </h1>
                <div className='p-6'>
                  {error ? <span className='text-xs text-perception-error-500'>{error}</span> : ''}
                  <InputComponent name='Name' value={name} onChange={(e) => setName(e.target.value)} />
                  <SelectComponent
                    name='Event to detect'
                    options={eventTypes}
                    setter={setSelectedEventType}
                    defaultValue={selectedEventType}
                    displayTextFunction={(option) => option?.content}
                  />
                  <ProgressBar alertOccurrences={occurrences} setAlertOccurrences={setOccurrences} />
                  <div className='flex items-center'>
                    <SelectComponent
                      name='Period'
                      options={alertPeriodTypes}
                      setter={setSelectedPeriod}
                      defaultValue={selectedPeriod}
                      displayTextFunction={(option) => option.name}
                    />
                  </div>
                  <Button
                    category='tertiary-btn'
                    className='inline-flex w-full'
                    type='button'
                    onClick={alert ? handleEdit : handleCreate}
                  >
                    Save
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const SelectComponent = ({ name, options, setter, defaultValue, displayTextFunction }) => {
  return (
    <div className='w-full mb-6'>
      <label htmlFor='location' className='block text-sm font-medium leading-6 text-gray-900'>
        {name}
      </label>
      <select
        id='location'
        name='location'
        className='block w-full rounded-md py-1.5 pl-3 text-gray-900 ring-1 ring-inset shadow-sm ring-gray-300 focus:outline-none text-sm leading-6'
        defaultValue={displayTextFunction(defaultValue)}
        onChange={(e) => setter(options[e.target.selectedIndex])}
      >
        {options.map((option, i) => (
          <option key={`${displayTextFunction(option)}_${i}`}>{displayTextFunction(option)}</option>
        ))}
      </select>
    </div>
  );
};

const InputComponent = ({ name, value, onChange }) => {
  return (
    <div className='mb-6'>
      <label htmlFor='name' className='block text-sm font-medium leading-6 text-gray-900'>
        {name}
      </label>
      <input
        type='text'
        onChange={onChange}
        name='name'
        id='name'
        value={value}
        placeholder='Name of the alert'
        className='block w-full rounded-md pl-3 py-1.5 text-gray-900 focus:outline-none shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 text-sm leading-6'
      />
    </div>
  );
};

const ProgressBar = ({ alertOccurrences, setAlertOccurrences }) => {
  return (
    <div className='w-full mb-6'>
      <label htmlFor='occurrences' className='block text-sm font-medium leading-6 text-gray-900'>
        Occurrences
      </label>
      <div className='flex items-end'>
        <input
          id='occurrences'
          type='range'
          min='1'
          max='20'
          value={alertOccurrences}
          onChange={(e) => setAlertOccurrences(parseInt(e.target.value))}
          className='w-full'
          list='markers'
        />
        <datalist id='markers'>
          <option value='1' />
          <option value='5' />
          <option value='10' />
          <option value='15' />
          <option value='20' />
        </datalist>
        <p className='block text-sm font-medium leading-6 text-gray-900 w-8 text-right'>{alertOccurrences}</p>
      </div>
    </div>
  );
};

export default ProgressBar;
