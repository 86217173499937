import moment from 'moment';
import { Loader } from '../../components/loading/Loader';
import { useGetData } from '../../services/api/api-tools';

export const SalesPage = () => {
  const { data: users, isLoading } = useGetData('users', 'users');

  if (isLoading) return <Loader />;

  const getTargetNames = (user) => {
    const targetNames = [];
    user.organization.workshops.forEach((workshop) => {
      workshop.devices.forEach((device) => {
        device.targets.forEach((target) => {
          targetNames.push(target.name);
        });
      });
    });
    return targetNames.join(', ');
  };

  const handleFileChange = async (event) => {
    event.preventDefault();

    const selectedFile = event.target.files[0];

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(process.env.REACT_APP_API_ADDRESS + 'upload-logo', {
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) console.log('File uploaded successfully');
      else console.error('File upload failed');
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className='p-6 text-white'>
      <div className='overflow-x-auto overflow-y-auto'>
        <h1>Users</h1>
        <table className='w-full text-sm text-left text-gray-400'>
          <thead className='text-xs text-perception-gray-500 uppercase bg-gray-700'>
            <tr>
              <th scope='col' className='px-6 py-3'>
                ID
              </th>
              <th scope='col' className='px-6 py-3'>
                First Name
              </th>
              <th scope='col' className='px-6 py-3'>
                Last Name
              </th>
              <th scope='col' className='px-6 py-3'>
                Email
              </th>
              <th scope='col' className='px-6 py-3'>
                Organization
              </th>
              <th scope='col' className='px-6 py-3'>
                Roles
              </th>
              <th scope='col' className='px-6 py-3'>
                Job
              </th>
              <th scope='col' className='px-6 py-3'>
                Targets
              </th>
              <th scope='col' className='px-6 py-3'>
                Last Login
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id} className='bg-gray-800 border-b border-gray-700'>
                <td className='px-6 py-4'>{user.id}</td>
                <td className='px-6 py-4'>{user.firstname}</td>
                <td className='px-6 py-4'>{user.lastname}</td>
                <td className='px-6 py-4'>{user.email}</td>
                <td className='px-6 py-4'>{user.organization.name}</td>
                <td className='px-6 py-4'>{user.roles.join(', ')}</td>
                <td className='px-6 py-4'>{user.job}</td>
                <td className='px-6 py-4'>{getTargetNames(user)}</td>
                <td className='px-6 py-4'>{user.last_login ? moment(user.last_login).format('LLLL') : 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h1>Upload logo</h1>
        <div>
          <form>
            <input type='file' onChange={handleFileChange} />
          </form>
        </div>
      </div>
    </div>
  );
};
