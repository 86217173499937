export const Checkbox = ({ id, name, label, description, checked, onChange }) => {
  return (
    <div className='relative flex items-start'>
      <div className='flex h-6 items-center'>
        <input
          id={id}
          aria-describedby={`${id}-description`}
          name={name}
          type='checkbox'
          checked={checked}
          onChange={onChange}
          className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
        />
      </div>
      <div className='ml-3 text-sm leading-6'>
        <label htmlFor={id} className='font-medium text-white'>
          {label}
        </label>
        <p id={`${id}-description`} className='text-perception-gray-500 font-normal'>
          {description}
        </p>
      </div>
    </div>
  );
};
