import { Doughnut } from 'react-chartjs-2';

export const DonutChart = ({ value }) => {
  const data = {
    labels: ['Yes', 'No'],
    datasets: [
      {
        data: [value, 100 - value],
        backgroundColor: ['rgba(65, 100, 253, 1)', 'rgba(67, 67, 67, 1)'],
        borderWidth: 0,
      },
    ],
  };

  const options = {
    cutout: '80%',
    plugins: {
      legend: false,
    },
  };

  return (
    <div className='w-36 h-36 relative'>
      <Doughnut data={data} options={options} />
      <div className='text-2xl absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'>{value}%</div>
    </div>
  );
};
