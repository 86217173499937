import { useForm } from 'react-hook-form';
import { Button } from '../../buttons/Button';
import { InputWithError } from '../../forms/inputs/InputWithError';
import { HeadForm } from '../AuthForm';
import { useState } from 'react';
import { postData } from '../../../services/api/api-tools';

export const PasswordForgot = ({ setIsFormLoading, setIsForgotPassword }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const [isMailSend, setIsMailSend] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsFormLoading(true);
      const response = await postData('password/reset', { email: data.email }, true);
      if (response) {
        setIsMailSend(true);
        reset({ email: '' });
      }
    } catch (error) {
      setIsMailSend(false);
      if (error.response.status === 404) {
        setError('email', {
          type: 'manual',
          message: 'Invalid email',
        });
      } else {
        setError('email', {
          type: 'manual',
          message: 'Something bad happened...',
        });
      }
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <div className='w-full h-full flex flex-col justify-center'>
      <HeadForm
        title='Password Reset'
        text='Please enter your email address. We will send you a link to reset your password.'
      />
      <form className='flex flex-col justify-start item-center mt-4' onSubmit={handleSubmit(onSubmit)}>
        {isMailSend && (
          <span className='text-green text-sm text-green-600 font-normal'>Reset password mail successfully send!</span>
        )}
        <InputWithError
          type='email'
          placeholder='email'
          register={register}
          errors={errors}
          fieldName='email'
          autoComplete='email'
          disabled={isMailSend}
        />
        <div className='flex flex-col justify-center items-center mt-2'>
          <Button category='primary-btn' size='medium' type='submit'>
            Reset password
          </Button>
        </div>
        <span
          className='w-full text-center text-xs text-perception-blue cursor-pointer mt-2'
          onClick={() => setIsForgotPassword(false)}
        >
          Back to login
        </span>
      </form>
    </div>
  );
};
