export const loadChatbot = (playbookUuid) => {
  if (window.LeadBooster || _getChatbotDOMElement()) {
    console.log('[ERROR] A chatbot is already loaded.');
    return;
  }

  window.pipedriveLeadboosterConfig = {
    base: 'leadbooster-chat.pipedrive.com',
    companyId: 11526660,
    playbookUuid: playbookUuid,
    version: 2,
  };

  _loadBooster();

  const script = document.createElement('script');
  script.src = 'https://leadbooster-chat.pipedrive.com/assets/loader.js';
  script.async = true;
  script.id = 'chatbot-script';
  document.head.appendChild(script);

  console.log('[DEBUG] Chatbot ' + playbookUuid + ' loaded');
};

export const removeChatbot = () => {
  return new Promise((resolve, reject) => {
    if (!window.LeadBooster || !_getChatbotDOMElement()) {
      console.log('[ERROR] There is no chatbot currently loaded.');
      reject();
    }

    window.LeadBooster = null;

    _getChatbotDOMElement().remove();
    _getChatbotDOMScript().remove();

    console.log('[DEBUG] Chatbot removed');
    resolve();
  });
};

const _loadBooster = () => {
  if (window.LeadBooster) {
    console.warn('LeadBooster already exists');
  } else {
    window.LeadBooster = {
      q: [],
      on: function (n, h) {
        this.q.push({ t: 'o', n: n, h: h });
      },
      trigger: function (n) {
        this.q.push({ t: 't', n: n });
      },
    };
  }
};

export const _getChatbotDOMElement = () => document.getElementById('LeadboosterContainer');
const _getChatbotDOMScript = () => document.getElementById('chatbot-script');
