import Peer from 'peerjs';
import QRCode from 'react-qr-code';
import { useEffect, Fragment, useState } from 'react';
import { useTrialSetupContext } from '../../../../contexts/TrialSetupContext';
import { useResizeDetector } from 'react-resize-detector';
import { ArrowPathRoundedSquareIcon } from '@heroicons/react/20/solid';

export const VideoFromMobile = ({ videoRef, setIsCameraReady, hidden, inStepper = false }) => {
  const { desktopId, peer, setPeer, stream, setStream, isQrCodeShown, setIsQrCodeShown } = useTrialSetupContext();

  const [isPortrait, setIsPortrait] = useState(false);

  const { width: phoneWidth, height: phoneHeight, ref: videoContainerRef } = useResizeDetector();

  useEffect(() => {
    if (phoneWidth === undefined || phoneWidth === 0) return;
    setIsPortrait(phoneHeight > phoneWidth);
  }, [phoneWidth, phoneHeight]);

  useEffect(() => {
    if (!stream && !peer) {
      const newPeer = new Peer(desktopId, {
        host: process.env.REACT_APP_PEER_SERVER_URL,
        port: process.env.REACT_APP_PEER_SERVER_PORT,
        path: process.env.REACT_APP_PEER_SERVER_APP,
        secure: process.env.REACT_APP_PEER_SERVER_SECURE,
      });
      setPeer(newPeer);
    }
  }, [desktopId]);

  useEffect(() => {
    if (peer) {
      peer.on('open', () => {
        peer.on('call', (call) => {
          call.answer();
          call.on('stream', (remoteStream) => {
            setIsQrCodeShown(false);
            if (videoRef.current) {
              videoRef.current.srcObject = remoteStream;
              setStream(remoteStream);
              setIsCameraReady(true);
            }
          });

          call.on('error', (err) => {
            console.error('Erreur Peer:', err);
          });
        });
      });

      peer.on('error', (err) => {
        console.error('Erreur Peer:', err);
      });
    }
  }, [peer]);

  useEffect(() => {
    if (stream) {
      videoRef.current.srcObject = stream;
      setIsCameraReady(true);
    } else {
      setIsQrCodeShown(true);
    }
  }, []);

  const textColor = inStepper ? 'perception-gray-800' : 'white';

  return (
    <Fragment>
      {isQrCodeShown && (
        <div className='flex flex-col items-center justify-center w-full h-full'>
          <QRCode
            value={`${process.env.REACT_APP_ADDRESS}demo/stream/${desktopId}`}
            bgColor='#FFFFFF'
            fgColor='#000000'
            size={256}
          />
          <span className={`text-${textColor} font-semibold mt-2`}>Scan to use your phone camera</span>
        </div>
      )}
      <div className={`h-full flex justify-center relative ${hidden ? 'hidden' : ''}`}>
        {isPortrait && (
          <div className={`w-[400px] absolute mt-10 flex flex-col items-center text-center text-${textColor}`}>
            Please turn your device to landscape mode.
            <ArrowPathRoundedSquareIcon className='h-36' />
          </div>
        )}
        <div className={`h-full inline-block ${isPortrait ? 'opacity-0' : ''}`} ref={videoContainerRef}>
          <video id='source-camera-autosetup' ref={videoRef} autoPlay playsInline className='w-full h-full' />
        </div>
      </div>
    </Fragment>
  );
};
