function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div>
      <div className='sm:hidden'>
        <label htmlFor='tabs' className='sr-only'>
          Select a tab
        </label>
        <select
          id='tabs'
          name='tabs'
          className='block w-full rounded-md border-perception-gray-600 py-2 pl-3 pr-10 text-base sm:text-sm'
          defaultValue={tabs.find((tab) => tab.current)?.name}
        >
          {tabs.map((tab) => (
            <option key={tab.id}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className='hidden sm:block border-b border-perception-gray-600 -mt-4'>
        <nav className='-mb-px flex space-x-8 px-8' aria-label='Tabs'>
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={classNames(
                tab.name === activeTab
                  ? 'border-b-perception-blue text-white'
                  : 'text-perception-gray-500 hover:border-b-perception-blue hover:text-perception-blue',
                'whitespace-nowrap border-transparent border-y-2 py-4 uppercase text-xs font-medium cursor-pointer',
              )}
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(tab.name);
              }}
            >
              {tab.name}
            </div>
          ))}
        </nav>
      </div>
    </div>
  );
};
