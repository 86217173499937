import { useEffect, useState } from 'react';
import { useTrialLiveContext } from '../../../contexts/TrialLiveContext';
import { useSocket } from '../../../contexts/SocketContext';
import { useTrialSetupContext } from '../../../contexts/TrialSetupContext';
import { useUser } from '../../../contexts/UserContext';
import { Button } from '../../../components/buttons/Button';
import { FullPageLoading } from '../../../components/loading/FullPageLoading';

export const LoadingOverlay = () => {
  const { user } = useUser();
  const { isSocketConnected } = useSocket();
  const { isAccessTimeExceeded, isQrCodeShown } = useTrialSetupContext();
  const { isEngineRunning, engineErrorCode } = useTrialLiveContext();
  const [showConnectionErrorModal, setShowConnectionErrorModal] = useState(false);

  useEffect(() => {
    if (!user?.confirmed || isQrCodeShown) {
      return;
    }

    if (isAccessTimeExceeded || engineErrorCode !== 0) {
      setShowConnectionErrorModal(true);
      return;
    } else if (!isAccessTimeExceeded && isEngineRunning) {
      setShowConnectionErrorModal(false);
      return;
    }

    const timer = setTimeout(() => {
      if (!isSocketConnected || !isEngineRunning) {
        setShowConnectionErrorModal(true);
      }
    }, 45000);

    return () => clearTimeout(timer);
  }, [isSocketConnected, isEngineRunning, isAccessTimeExceeded, engineErrorCode]);

  const handleRetry = () => {
    window.location.reload();
  };

  const handleUpgrade = () => {
    window.location = 'https://www.perception-manufacturing.com/contact';
  };

  let buttonText = isAccessTimeExceeded ? 'Upgrade' : 'Retry';
  let buttonAction = isAccessTimeExceeded ? handleUpgrade : handleRetry;

  if (showConnectionErrorModal) {
    return (
      <div className='h-full w-full fixed flex justify-center items-center top-0 left-0 z-50 bg-black bg-opacity-70 backdrop-blur-sm'>
        <div className='flex flex-col justify-center items-center p-8 max-w-3/4 bg-[#121212]'>
          <p className='text-center text-lg font-normal pb-4 text-white'>
            {isAccessTimeExceeded ? (
              'Free time access exceeded. Please upgrade for continued access.'
            ) : engineErrorCode === 509 ? (
              <>
                There are currently too many users connected to the engine.
                <br />
                Please try again in a few moments.
              </>
            ) : (
              'We are unable to connect to the AI engine. Please try again later.'
            )}
          </p>
          <div>
            <Button type='button' category='tertiary-btn' size='small' onClick={buttonAction}>
              {buttonText}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  if (!isSocketConnected || !isEngineRunning) {
    if (isQrCodeShown) {
      return;
    }
    return <FullPageLoading />;
  }

  return <></>;
};
