import { useLocation } from 'react-router-dom';
import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { ExclamationTriangleIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { _getChatbotDOMElement, loadChatbot, removeChatbot } from '../services/chatbot/chatbot-service';

const AlertContext = createContext();

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(null);
  const location = useLocation();

  // load or remove Pipedrive Chatbot depending on alert
  useEffect(() => {
    if (alert && _getChatbotDOMElement()) {
      removeChatbot();
    } else {
      if (location.pathname === '/demo/live' || location.pathname === '/demo/setup') {
        if (!_getChatbotDOMElement()) {
          loadChatbot('248e9347-a31c-408f-996e-9f6e179b58df');
        }
      }
    }
    return () => {
      if (_getChatbotDOMElement()) {
        removeChatbot();
      }
    };
  }, [alert, location]);

  const showAlert = (type, message) => {
    setAlert({ type, message });
  };

  const closeAlert = () => {
    setAlert(null);
  };

  const value = useMemo(() => ({ alert, showAlert, closeAlert }), [alert]);

  return (
    <AlertContext.Provider value={value}>
      {children}
      {alert && <AlertComponent type={alert.type} message={alert.message} closeAlert={closeAlert} />}
    </AlertContext.Provider>
  );
};

export const AlertComponent = ({ type, message, closeAlert }) => {
  let iconColor, bgColor, titleColor, messageColor, borderColor, IconComponent;

  switch (type) {
    case 'success':
      iconColor = 'text-white';
      titleColor = 'text-white';
      messageColor = 'text-white';
      bgColor = 'bg-perception-success-600';
      borderColor = 'border-perception-success-300';
      IconComponent = CheckCircleIcon;
      break;
    case 'warning':
      iconColor = 'text-white';
      titleColor = 'text-white';
      messageColor = 'text-white';
      bgColor = 'bg-perception-warn-500';
      borderColor = 'border-perception-warn-500';
      IconComponent = ExclamationTriangleIcon;
      break;
    case 'error':
      iconColor = 'text-white';
      titleColor = 'text-white';
      messageColor = 'text-white';
      bgColor = 'bg-perception-error-500';
      borderColor = 'border-perception-error-800';
      IconComponent = ExclamationCircleIcon;
      break;
    default:
      iconColor = 'text-white';
      titleColor = 'text-white';
      messageColor = 'text-white';
      bgColor = 'bg-perception-error-500';
      borderColor = 'border-perception-error-800';
      IconComponent = ExclamationTriangleIcon;
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      closeAlert();
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [closeAlert]);

  return (
    <div className={`fixed ${bgColor} bottom-8 right-8 rounded-md p-4 border ${borderColor} z-90`}>
      <button onClick={closeAlert} className='absolute top-0 right-0 p-2'>
        <svg className='w-4 h-4' fill='none' stroke='#FFFFFF' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
        </svg>
      </button>
      <div className='flex flex-col'>
        <div className='flex items-center'>
          <div className='flex-shrink-0'>
            <IconComponent className={`h-5 w-5 ${iconColor}`} aria-hidden='true' />
          </div>
          <div className='ml-2'>
            <h3 className={`text-sm font-medium capitalize ${titleColor}`}>{type}</h3>
          </div>
        </div>
        <div className='ml-1 mt-2'>
          <div className={`text-sm ${messageColor}`}>
            <p>{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
