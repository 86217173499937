import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';

export const GenericListbox = (props) => {
  const { controllerOnChange, onChangeAction, value, selectedElementName, label, children } = props;

  return (
    <Listbox
      onChange={(e) => {
        if (controllerOnChange) {
          controllerOnChange(e);
        }
        onChangeAction(e);
      }}
      value={value}
    >
      {({ open }) => (
        <div className='mx-2 w-full'>
          {/* Label */}
          <Listbox.Label className='block font-normal text-white'>{label}</Listbox.Label>
          <div className='mt-1 relative'>
            {/* Main button */}
            <Listbox.Button className='bg-perception-gray-800 relative w-full pl-3 pr-10 py-2 text-left text-white cursor-default focus:outline-none'>
              <span className='block truncate sm:text-sm'>{selectedElementName}</span>
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <span className='h-5 w-5 text-gray-400 material-icons' aria-hidden='true'>
                  expand_more
                </span>
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              {/* Options */}
              <Listbox.Options className='absolute z-10 text-white sm:text-sm mt-1 w-full bg-perception-gray-800 max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none'>
                {/* Add Listbox.Option elements here */}
                {children}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};
