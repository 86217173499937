import { UserPlusIcon, ArrowLeftIcon } from '@heroicons/react/24/outline';
import { isFreeUser } from '../../../services/entity/user/user-service';
import { useUser } from '../../../contexts/UserContext';
import { useState } from 'react';
import { postData } from '../../../services/api/api-tools';
import { Loader } from '../../loading/Loader';

export const AddMember = () => {
  const { user } = useUser();

  const [isModalOpen, setIsModalOpen] = useState(false);

  if (isFreeUser(user)) return null;

  return (
    <div>
      <UserPlusIcon className='h-6 w-6 text-perception-gray-500 cursor-pointer' onClick={() => setIsModalOpen(true)} />
      {isModalOpen && <ModalInvitation setIsModalOpen={setIsModalOpen} />}
    </div>
  );
};

const ModalInvitation = ({ setIsModalOpen }) => {
  return (
    <div className='absolute left-0 top-0 w-screen h-screen bg-white z-40'>
      <section className='py-10 bg-perception-black-900 h-full sm:py-16 lg:py-24'>
        <div className='max-w-6xl px-4 mx-auto sm:px-6 lg:px-8'>
          <div className='grid grid-cols-1 md:items-stretch md:grid-cols-2 gap-x-12 lg:gap-x-20 gap-y-10'>
            <LeftSide />
            <Form setIsModalOpen={setIsModalOpen} />
          </div>
        </div>
      </section>
    </div>
  );
};

export const LeftSide = () => {
  return (
    <div className='flex flex-col justify-between lg:py-5'>
      <div>
        <h2 className='text-3xl font-bold leading-tight text-white sm:text-4xl lg:leading-tight lg:text-5xl'>
          Invite your colleagues to your organization!
        </h2>
        <p className='max-w-xl mx-auto mt-4 text-base leading-relaxed text-white'>
          Work and analyze together to boost your factory, identify dangerous actions, get alerted when a malfunction is
          detected in your processes.
        </p>
        <img
          className='hidden w-full translate-x-24 translate-y-8 md:block'
          src='https://cdn.rareblocks.xyz/collection/celebration/images/contact/4/curve-line.svg'
          alt=''
        />
      </div>
      <div className='hidden md:mt-auto md:block'>
        <div className='flex items-center'>
          <Star />
          <Star />
          <Star />
          <Star />
          <Star />
        </div>
        <blockquote className='mt-6'>
          <p className='text-lg leading-relaxed text-white'>
            Lineware has allowed me to improve my productivity at work by alerting me during moments when my eyes close,
            and I know I need to make myself a coffee.
          </p>
        </blockquote>
        <div className='flex items-center mt-8'>
          <img
            className='flex-shrink-0 object-cover w-10 h-10 rounded-full'
            src='https://media.licdn.com/dms/image/D4E03AQHRTOS4-Wg5fw/profile-displayphoto-shrink_200_200/0/1678953792145?e=1711584000&v=beta&t=7rnGLZCbL30ub7GHI7ePN-3q5Ui8xzAfzpxCoHJq1xs'
            alt=''
          />
          <div className='ml-4'>
            <p className='text-base font-semibold text-white'>Pierre Marigo</p>
            <p className='mt-px text-sm text-gray-400'>CTO</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Form = ({ setIsModalOpen }) => {
  const { user } = useUser();

  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [message, setMessage] = useState({});

  const getMessageColor = () => {
    if (message.status === 'success') {
      return 'text-perception-success-600';
    } else if (message.status === 'warn') {
      return 'text-perception-warn-500';
    } else if (message.status === 'error') {
      return 'text-perception-error-500';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const organizationToken = user.organization.token;
      if (organizationToken) {
        const response = await postData('email/send', {
          email: email,
          type: 'LINEWARE_INVITE_ORGANIZATION',
          parameters: {
            link: `${process.env.REACT_APP_ADDRESS}register/${organizationToken}`,
          },
        });
        if (response.message === 'Email sent successfully') {
          setMessage({ status: 'success', message: 'The invitation has been successfully sent to your colleague.' });
          setEmail('');
        }
      } else {
        setMessage({ status: 'warn', message: 'Your organization is not allowed to send invitation.' });
      }
    } catch (err) {
      console.error(err);
      setMessage({
        status: 'error',
        message: 'Oops! We encountered an issue while attempting to send the invitation.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='lg:pl-12 flex flex-col justify-center'>
      {isLoading && <Loader />}
      <div className='overflow-hidden bg-white'>
        <div className='p-6 sm:p-10'>
          <h3 className='text-3xl font-semibold text-black'>Send an invitation</h3>
          <p className='mt-4 text-base text-gray-600'>
            Invite your colleagues. All they need to do is open the link received via email and sign up through it.
          </p>

          <form className='mt-4'>
            <div className='space-y-6'>
              <div>
                <label htmlFor='email' className='text-base font-medium text-gray-900'>
                  Email address
                </label>
                {message?.status ? <p className={getMessageColor()}>{message.message}</p> : ''}
                <div className='mt-2.5 relative'>
                  <input
                    type='text'
                    name='email'
                    id='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Enter your colleague email'
                    className='block w-full px-4 py-4 text-black placeholder-gray-500 transition-all duration-200 bg-white border border-gray-200 focus:outline-none focus:perception-blue focus:border-perception-blue'
                  />
                </div>
              </div>
              <div>
                <button
                  type='submit'
                  className='inline-flex items-center justify-center w-full px-4 py-4 text-base font-semibold text-white transition-all duration-200 bg-perception-blue border border-transparent focus:outline-none hover:bg-perception-blue/80'
                  onClick={handleSubmit}
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='flex items-center pt-2 cursor-pointer'>
        <ArrowLeftIcon className='w-4 h-4 text-white' />
        <span className='text-white font-normal pl-2' onClick={() => setIsModalOpen(false)}>
          back to Lineware
        </span>
      </div>
    </div>
  );
};

const Star = () => {
  return (
    <svg className='w-6 h-6 text-yellow-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
      <path d='M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z' />
    </svg>
  );
};
