import { useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';
import { AuthForm } from '../auth/AuthForm';

export const LoginLayout = () => {
  const { user, isUserDataLoading } = useUser();
  const url = useLocation();

  const denylist = ['/register', '/demo', '/reset-password', '/calibration'];
  const exception = '/demo/live';

  if (denylist.some((path) => url.pathname.startsWith(path)) && url.pathname !== exception) return null;
  if (isUserDataLoading) return null;
  if (user?.confirmed) return null;
  return (
    <div id='login-layout' className='fixed top-0 left-0 z-50 w-full h-full'>
      <div className='absolute top-0 left-0 block w-full h-full bg-perception-black-800 opacity-30' />
      <AuthForm />
    </div>
  );
};
