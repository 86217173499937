export const CardIcon = ({ setter, iconComponent: Icon, text, resume }) => {
  return (
    <div
      onClick={setter}
      className={`card-icon-autosetup flex flex-col justify-around items-center h-[200px] w-[200px] border border-perception-black-800 ${
        setter ? 'cursor-pointer' : ''
      }`}
    >
      <Icon className='w-16 h-16' />
      <div className='flex flex-col w-full justify-center items-center'>
        <span className='text-md font-semibold text-center w-2/3'>{text}</span>
        <span className='text-md font-normal text-center w-full'>{resume}</span>
      </div>
    </div>
  );
};
