import { CubeIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { useState, useRef, useEffect } from 'react';
import { useTrialLiveContext } from '../../contexts/TrialLiveContext';
import { patchData, removeItemFromCache, updateItemInCache } from '../../services/api/api-tools';
import { useAlert } from '../../contexts/AlertContext';
import { segmentDelete } from '../../services/ia/segmentation-api';
import { useQueryClient } from 'react-query';

export const Target = ({ target }) => {
  const { showAlert } = useAlert();
  const { setIsViewLoadingData, targetsQueryKey } = useTrialLiveContext();
  const inputRef = useRef(null);

  const queryClient = useQueryClient();

  const [isTargetMenuOpened, setIsTargetMenuOpened] = useState(false);
  const [targetName, setTargetName] = useState(target.name);
  const [initialTargetName, setInitialTargetName] = useState(target.name);
  const [isTargetNameEditing, setIsTargetNameEditing] = useState(false);

  const handleClickTarget = () => {
    if (!isTargetNameEditing) {
      setIsTargetMenuOpened(!isTargetMenuOpened);
    }
  };

  const handleUpdateTarget = () => {
    updateItemInCache(
      queryClient,
      targetsQueryKey,
      (d) => d.id === target.id,
      () => ({ name: targetName }),
    );
  };

  const handleNameChange = (event) => {
    setTargetName(event.target.value);
  };

  const handleNameSubmit = async () => {
    if (initialTargetName !== targetName) {
      try {
        setIsViewLoadingData(true);
        let path = `targets/${target.id}`;
        let body = { name: targetName };
        const response = await patchData(path, body);
        if (response) {
          setInitialTargetName(targetName);
          handleUpdateTarget();
        } else {
          showAlert('warning', 'Oops! We encountered an issue while trying to update the name.');
        }
      } catch (err) {
        console.error('Something bad happened:', err);
      } finally {
        setIsTargetNameEditing(false);
        setIsViewLoadingData(false);
      }
    } else {
      setIsTargetNameEditing(false);
    }
  };

  // Automatically select all text in the input field when entering edit mode.
  useEffect(() => {
    if (isTargetNameEditing && inputRef.current) {
      inputRef.current.select();
    }
  }, [isTargetNameEditing]);

  // This function checks if a click event occurred outside the input element.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isTargetNameEditing && !inputRef.current.contains(event.target) && event.target.id !== 'input-name') {
        handleNameSubmit();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isTargetNameEditing]);

  return (
    <div className='relative flex justify-between p-2 overflow-visible'>
      <div className='flex'>
        <CubeIcon className='w-5 h-5 text-perception-blue' />
        <input
          ref={inputRef}
          type='text'
          id='input-name'
          value={targetName}
          onChange={handleNameChange}
          onKeyDown={(e) => e.key === 'Enter' && handleNameSubmit()}
          className={`text-xs text-perception-gray-500 ml-4 uppercase font-semibold bg-transparent ${
            isTargetNameEditing ? 'border' : 'border-none pointer-events-none'
          } outline-none border-perception-blue`}
          readOnly={!isTargetNameEditing}
        />
      </div>
      <EllipsisHorizontalIcon onClick={handleClickTarget} className='w-5 h-5 cursor-pointer text-perception-gray-500' />

      {isTargetMenuOpened && (
        <TargetMenu
          targetId={target.id}
          setIsTargetMenuOpened={setIsTargetMenuOpened}
          setIsTargetNameEditing={setIsTargetNameEditing}
        />
      )}
    </div>
  );
};

const TargetMenu = ({ targetId, setIsTargetMenuOpened, setIsTargetNameEditing }) => {
  const { showAlert } = useAlert();
  const { setIsViewLoadingData, targetsQueryKey, device } = useTrialLiveContext();
  const targetRef = useRef(null);

  const queryClient = useQueryClient();

  const handleDeleteTarget = async () => {
    try {
      setIsViewLoadingData(true);
      await segmentDelete(targetId, device.stream_id);
      showAlert('success', 'The target has been successfully deleted from database');
      removeItemFromCache(queryClient, targetsQueryKey, (target) => target.id === targetId);
      setIsTargetMenuOpened(false);
    } catch (err) {
      console.error('An error occurred while deleting target:', err);
      showAlert('error', 'Oops! Something went wrong while attempting to delete the target');
    } finally {
      setIsViewLoadingData(false);
    }
  };

  const handleClickOutside = (event) => {
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      setIsTargetMenuOpened(false);
    }
  };

  const handleRename = () => {
    setIsTargetNameEditing(true);
    setIsTargetMenuOpened(false);
  };

  // This function checks if a click event occurred outside the input element.
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={targetRef}
      className='bg-perception-gray-800 border border-perception-black-800 text-white text-xs rounded-xs absolute right-2 top-[100%] flex flex-col z-30'
    >
      <span className='p-2 px-4 text-center border-b cursor-pointer border-perception-black-800' onClick={handleRename}>
        Rename
      </span>
      <span className='p-2 px-4 text-center cursor-pointer' onClick={handleDeleteTarget}>
        Delete
      </span>
    </div>
  );
};
