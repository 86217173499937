import { InputFile } from '../../../../components/forms/inputs/InputFile';
import { Modal } from '../../../../components/modals/Modal';
import { Input } from '../../../../components/forms/inputs/Input';
import { Button } from '../../../../components/buttons/Button';
import { useState } from 'react';
import axios from 'axios';

export const ImportModal = ({ isOpen, setIsOpen, refetch }) => {
  const [clientName, setClientName] = useState('');
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('client_name', clientName);
    formData.append('zip', file);

    try {
      await axios.post(`${process.env.REACT_APP_API_ADDRESS}import-specification`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      setIsOpen(false);
      setFile(null);
      refetch();
    } catch (error) {
      console.error('Error importing data', error);
      alert('Failed to import data');
    }
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='flex flex-col gap-4 px-4 py-2 flex-start'>
        <h2 className='text-xl font-semibold'>Import client data</h2>
        <div className='flex items-center w-full gap-2'>
          <label className='w-1/3'>Client name</label>
          <Input
            category='primary'
            value={clientName}
            onChange={(e) => setClientName(e.target.value)}
            className='m-0'
          />
        </div>
        <div className='flex items-center w-full gap-2'>
          <label className='w-1/3'>Client file</label>
          <InputFile onChange={handleFileChange} buttonText='Upload CSV file' />
          {file && <span>{file.name}</span>}
        </div>
        <div className='self-end pt-2'>
          <Button category='primary-btn' className='inline-block' size='small' onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
