import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { VideoFromMobile } from './VideoFromMobile';
import { VideoFromComputer } from './VideoFromComputer';
import { useTrialSetupContext } from '../../../../contexts/TrialSetupContext';
import { Loader } from '../../../../components/loading/Loader';

export const SourceVideo = ({ showSpinner, showCheckLogo }) => {
  const { typeDevice, videoRef, isCameraReady, setIsCameraReady } = useTrialSetupContext();

  return (
    <div className='overflow-hidden border border-gray-300 relative'>
      {showSpinner && <Loader />}
      {showCheckLogo && (
        <div className='absolute inset-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center'>
          <CheckCircleIcon className='w-[64px] h-[64px] text-perception-blue' />
        </div>
      )}
      {typeDevice === 'mobile' ? (
        <VideoFromMobile
          videoRef={videoRef}
          isCameraReady={isCameraReady}
          setIsCameraReady={setIsCameraReady}
          hidden={false}
          inStepper={true}
        />
      ) : (
        <VideoFromComputer
          videoRef={videoRef}
          setIsCameraReady={setIsCameraReady}
          keepRatio={false}
          hidden={false}
          inStepper={true}
        />
      )}
    </div>
  );
};
