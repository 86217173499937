import { Listbox } from '@headlessui/react';
import { handleClassNames } from '../../helpers/common/handleClassNames';

export const ListboxOptionWithCheck = (props) => {
  const { value, name, isDark } = props;

  return (
    <Listbox.Option
      value={value}
      className={({ active }) =>
        handleClassNames(
          active ? 'text-white bg-perception-blue' : isDark ? 'text-white' : 'text-white',
          'cursor-default select-none relative py-2 pl-3 pr-9',
        )
      }
    >
      {({ selected, active }) => (
        <>
          <span className={handleClassNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>{name}</span>
          {selected ? (
            <span
              className={handleClassNames(
                active ? 'text-white' : 'text-perception-blue',
                'absolute inset-y-0 right-0 flex items-center pr-4',
              )}
            >
              <span className='h-5 w-5 material-icons text-perception-blue-800' aria-hidden='true'>
                check
              </span>
            </span>
          ) : null}
        </>
      )}
    </Listbox.Option>
  );
};
