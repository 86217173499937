import { SignalSlashIcon } from '@heroicons/react/24/outline';

export const NoActivity = () => {
  return (
    <div className='flex items-center justify-center pb-12 h-screen'>
      <div className='flex flex-col items-center'>
        <div className='animate-pulse animate-pulse-grow'>
          <SignalSlashIcon className='h-36 text-black' />
        </div>
        <h1 className='mt-5 text-4xl text-center font-bold'>Aucune activité détectée</h1>
      </div>
    </div>
  );
};
