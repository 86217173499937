import { useParams } from 'react-router-dom';
import { useGetData } from '../../services/api/api-tools';
import { CameraSetup } from './components/specification/camera-setup/CameraSetup';
import { Annotation } from './components/specification/annotation/Annotation';
import { Loader } from '../../components/loading/Loader';

export const SpecificationPage = () => {
  const { deviceId } = useParams();

  // Retrieves all information from the specifications sheet associated with the device.
  const specificationQueryKey = ['specification', deviceId];
  const {
    data,
    isLoading,
    refetch: refetchSpecification,
  } = useGetData(specificationQueryKey, `specifications?device.id=${deviceId}`);

  if (isLoading) return <Loader category='transparent' />;

  const specification = data[0];

  /* Specification states:
      1: Camera setup required
      2: Annotation phase not completed
      3: Annotation completed, under review
      4: Annotation completed, Perception review done (error or success)
  */
  const renderComponent = () => {
    if (specification.state < 2) {
      return <CameraSetup specification={specification} refetchSpecification={refetchSpecification} />;
    } else if (specification.state >= 2) {
      return (
        <Annotation
          specification={specification}
          refetchSpecification={refetchSpecification}
          specificationQueryKey={specificationQueryKey}
        />
      );
    }
  };

  return <div className='flex w-full h-full'>{renderComponent()}</div>;
};
