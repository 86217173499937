import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';

export const Modal = ({ children, isOpen, setIsOpen, staticBackdrop = false, size = 'small' }) => {
  const handleClose = () => {
    if (!staticBackdrop) {
      setIsOpen(false);
    }
  };

  const modalStyle =
    'relative transform overflow-hidden rounded-sm bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6';

  let widthClass = 'sm:max-w-sm';
  if (size === 'medium') {
    widthClass = 'sm:max-w-md';
  } else if (size === 'large') {
    widthClass = 'sm:max-w-2xl';
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div
            style={{ backgroundColor: 'black', opacity: 0.3, backdropFilter: 'blur(20px)' }}
            className='fixed inset-0 bg-handddle-black transition-opacity'
          />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className={clsx(modalStyle, widthClass)}>{children}</Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
