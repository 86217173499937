import { InputSelect } from '../../../../../components/forms/inputs/InputSelect';
import { useTrialLiveContext } from '../../../../../contexts/TrialLiveContext';
import { useTrialSetupContext } from '../../../../../contexts/TrialSetupContext';
import { useSocket } from '../../../../../contexts/SocketContext';

export const SetupSettings = () => {
  const { isCreatingNewTarget, cameras } = useTrialLiveContext();
  const { sendEvent, subscribeToEvent } = useSocket();
  const { selectedCamera, setSelectedCamera, setTypeDevice, isQrCodeShown, setIsQrCodeShown, setIsCameraReady } =
    useTrialSetupContext();

  const handleChangeCamera = async (item) => {
    if (!isQrCodeShown) {
      sendEvent('stop_ffmpeg');

      await new Promise((resolve) => {
        subscribeToEvent('ffmpeg_stopped', () => {
          resolve();
        });
      });
    }

    if (item.id === 'mobile-camera') {
      setTypeDevice('mobile');
      setSelectedCamera(item);
      setIsCameraReady(false);
      setIsQrCodeShown(true);
    } else {
      setTypeDevice('desktop');
      setSelectedCamera(item);
      setIsQrCodeShown(false);
    }
  };

  return (
    <InputSelect
      items={cameras}
      onChange={(item) => handleChangeCamera(item)}
      itemToString={(item) => item?.label}
      defaultValue={selectedCamera}
      disabled={isCreatingNewTarget}
    />
  );
};
