import { MainLineChartBlock } from './MainLineChartBlock';
import { DonutChart } from './DonutChart';
import { SummaryBlock } from './SummaryBlock';
import { ValueChart } from './ValueChart';
import { ValueDurationChart } from './ValueDurationChart';

export const GenericChart = ({ type, ...props }) => {
  let chartComponent;

  switch (type) {
    case 'main-graph':
      chartComponent = <MainLineChartBlock {...props} />;
      break;
    case 'value':
      chartComponent = <ValueChart {...props} />;
      break;
    case 'value_duration':
      chartComponent = <ValueDurationChart {...props} />;
      break;
    case 'donut':
      chartComponent = <DonutChart {...props} />;
      break;
    case 'donut_duration':
      chartComponent = <DonutChart {...props} />;
      break;
    case 'summary':
      chartComponent = <SummaryBlock {...props} />;
      break;
    default:
      chartComponent = <div>Aucun type de graphique spécifié</div>;
  }

  return chartComponent;
};
