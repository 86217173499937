import { useEffect } from 'react';
import { useTrialSetupContext } from '../../../../contexts/TrialSetupContext';
import { useSocket } from '../../../../contexts/SocketContext';
import { useAlert } from '../../../../contexts/AlertContext';

export const VideoFromComputer = ({ videoRef, setIsCameraReady, hidden = false }) => {
  const { sendEvent } = useSocket();
  const { selectedCamera, setSelectedCamera } = useTrialSetupContext();
  const { showAlert } = useAlert();

  // Permission is requested from the user's browser to access the stream of this camera,
  // and then we assign this stream to our video react reference.
  const initCamera = async (cameraId) => {
    // Before each initialization, we execute a cleanup function.
    cleanUpCamera();

    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: cameraId ? { deviceId: { exact: cameraId } } : true,
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        setIsCameraReady(true);
      }
    } catch (err) {
      console.error('Error when trying to connect camera device: ', err);
      setIsCameraReady(false);
      setSelectedCamera(null);
      showAlert('error', 'Oops! We encountered an issue while attempting to connect to the camera.');
    }
  };

  // Cleanup function to ensure that no streams or video feeds are left running.
  const cleanUpCamera = (videoRefInstance) => {
    if (videoRefInstance?.srcObject) {
      videoRefInstance.srcObject.getTracks().forEach((track) => {
        if (track.readyState === 'live') {
          track.stop();
        }
      });
    }
    sendEvent('stop_ffmpeg');
    setIsCameraReady(false);
  };

  useEffect(() => {
    const videoRefInstance = videoRef.current;
    // A camera is already selected if the user has completed the trial mode process.
    if (selectedCamera) {
      initCamera(selectedCamera.id);
    } else {
      // Otherwise, we retrieve the default camera from their browser.
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');
        if (videoDevices.length > 0) {
          setSelectedCamera(videoDevices[0]);
        }
      });
    }

    return () => {
      cleanUpCamera(videoRefInstance);
    };
  }, [selectedCamera]);

  return (
    <div className={`h-full w-full ${hidden ? 'hidden' : ''}`}>
      <video id='source-camera-autosetup' ref={videoRef} autoPlay playsInline className='h-full w-full' />
    </div>
  );
};
