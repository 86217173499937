import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Button } from '../../components/buttons/Button';
import { UseCaseCard } from './components/use-cases/UseCaseCard';
import { UseCaseVideo } from './components/use-cases/UseCaseVideo';
import { useCases } from '../../temp/mock';
import LogoBlueBlack from '../../assets/images/logos/logo-blue-black.png';
import LogoWhite from '../../assets/images/logos/logo-mini-white.png';

export const TrialHomePage = () => {
  return (
    <div className='bg-white h-full w-full flex'>
      <LeftSide />
      <RightSide />
    </div>
  );
};

const LeftSide = () => {
  const location = useLocation();

  const [selectedUseCaseId, setSelectedUseCaseId] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const videoId = queryParams.get('video');
    if (videoId) {
      setSelectedUseCaseId(parseInt(videoId));
    }
  }, [location]);

  return (
    <div id='discover-step' className='bg-perception-blue h-full w-1/2 flex justify-center items-center relative'>
      {selectedUseCaseId ? (
        <UseCaseVideo selectedUseCaseId={selectedUseCaseId} setSelectedUseCaseId={setSelectedUseCaseId} />
      ) : (
        <div className='w-full h-full flex flex-col justify-center items-center'>
          <div className='absolute left-0 top-0 w-full h-full p-4'>
            <div className='border-2 border-white w-full h-full'></div>
          </div>
          <div className='absolute left-0 top-0 w-full h-full p-4'>
            <img id='trial-white-logo' src={LogoWhite} className='w-16 h-16' />
          </div>

          <h3 className='text-white text-center text-2xl font-bold mb-2'>
            Curious about what we can make for you? <br /> Discover different use cases.{' '}
          </h3>
          <h4 className='text-white text-center text-lg  mb-6'>The power of AI for operation field.</h4>
          <div className='discover-container flex flex-wrap w-3/4'>
            {useCases.map((discover) => {
              return (
                <UseCaseCard
                  id={discover.id}
                  img={discover.img}
                  text={discover.text}
                  key={discover.text}
                  setSelectedUseCaseId={setSelectedUseCaseId}
                />
              );
            })}
            <Link to='/demo/setup' style={{ width: 'calc(50% - 32px)' }}>
              <div
                id='try'
                className='xl:h-[200px] overflow-hidden bg-perception-black-800 cursor-pointer relative m-4 w-full'
              >
                <div className='absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 opacity-1 pointer-events-none'>
                  <p className='font-semibold text-lg text-white z-20'>Get the same on yours!</p>
                </div>
                <span className='absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 opacity-0 pointer-events-none'>
                  <p className='font-bold text-xl text-white z-20'>Try</p>
                </span>
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const RightSide = () => {
  const navigate = useNavigate();

  const handleSkip = () => {
    navigate('/demo/live');
  };

  return (
    <div className='h-full w-1/2 flex flex-col justify-center items-center relative'>
      <div className='absolute top-2 right-4'>
        <Button type='button' category='primary-btn' size='xs' onClick={handleSkip}>
          Login
        </Button>
      </div>
      <img src={LogoBlueBlack} className='sm:h-16 md:h-24' />
      <p className='text-lg lg:text-lg xl:text-xl font-normal w-full text-center mt-2 xl:mt-4'>
        Get access to Lineware Demo and explore our AI capabilities. Fast and
        <span className='text-perception-blue uppercase font-semibold'>{''} free</span>
      </p>
      <p className='text-md lg:text-md xl:text-lg italic w-full text-center mt-2 xl:mt-4'>(3min to get access)</p>
      <div className='mt-3 xl:mt-6 flex'>
        <Link to='/demo/setup'>
          <div className='m-2'>
            <Button type='button' category='trial-btn' size='large'>
              Start
            </Button>
          </div>
        </Link>
        <div className='m-2'></div>
      </div>
      <a
        href='https://www.perception-manufacturing.com'
        target='_blank'
        rel='noopener noreferrer'
        className='absolute bottom-0 text-sm pb-6'
      >
        www.perception-manufacturing.com
      </a>
    </div>
  );
};
