import { InfluxDB } from '@influxdata/influxdb-client-browser';
import { convertSmallUnitToSeconds } from '../../helpers/date/date-helper';

// REQUEST FUNCTIONS
/**
 * get data in data range (one data per field)
 * @param bucket (bucket)
 * @param measurement (equivalent to database table)
 * @param filterTags (list of triplets [tagName, tagOperation, tagValue])
 * @param filterFields (list of triplets [fieldName, fieldOperation, fieldValue] (fields to filter on) or string (fields to get))
 * @param start (data range start time)
 * @param stop (data range stop time)
 * @param window (period for one single data - ex: 1m = one data per minute, each data is an average of all existing data in that period)
 * @param aggregation (aggregation function)
 * @param uniqueAggregation (if provided, return a unique value based on this aggregation function)
 * @param group (list of keys for grouping during aggregation)
 * @param rateMultiplier (multiplier compared to window period, used to compute rate)
 * @param fullRequest (if provided, run the given request explicitly)
 * @param createEmpty (if true, create points with empty value for each missing aggregation slot)
 * @param sort (if provided, sort points in ASC or DESC time)
 * @returns {Promise<Array<any>>}
 */
export const getInfluxdbData = async (
  bucket,
  measurement,
  filterTags = [],
  filterFields = [],
  start = '-5m',
  stop = 'now()',
  window = '500ms',
  aggregation = null,
  uniqueAggregation = null,
  group = [],
  rateMultiplier = null,
  fullRequest = null,
  createEmpty = false,
  sort = null,
) => {
  // Warnings to prevent bad requests
  if (filterFields.length > 1 && uniqueAggregation !== null)
    console.warn(
      'The request contains several fields but also a unique aggregation function. The results may be incorrect.',
    );
  if (window !== null && uniqueAggregation !== null)
    console.warn(
      'The request contains a window aggregation period but also a unique aggregation function. The results may be incorrect.',
    );
  if (aggregation !== null && uniqueAggregation !== null)
    console.warn(
      'The request contains an aggregation function but also a unique aggregation function. The results may be incorrect.',
    );
  if (aggregation !== null && filterFields.length === 0)
    console.warn('The request contains an aggregation function but no selected fields. The results will be incorrect.');
  if (aggregation !== null && filterFields.length > 1)
    console.warn(
      'The request contains an aggregation function but several filters on fields. The first filter will be considered, and the results may be incorrect.',
    );

  let request;

  if (fullRequest !== null) request = _replaceDatesInRange(fullRequest.replace(/[\n\r]/g, ''), start, stop);
  else {
    // Build the query
    request = _buildFluxRequest(
      bucket,
      measurement,
      filterTags,
      filterFields,
      start,
      stop,
      window,
      aggregation,
      uniqueAggregation,
      group,
      rateMultiplier,
      createEmpty,
      sort,
    );
  }

  // Execute the query and format results
  return await _getQueryApi().collectRows(request);
};

// INTERNAL FUNCTIONS
/* Build request body (flux query) */
const _buildFluxRequest = (
  bucket,
  measurement,
  filterTags,
  filterFields,
  start,
  stop,
  window,
  aggregation,
  uniqueAggregation,
  group,
  rateMultiplier,
  createEmpty,
  sort,
) => {
  let request = _getFirstPartRequest(bucket, measurement, start, stop);

  /* Filter on tags */
  for (let [tagName, tagOp, tagValue] of filterTags) {
    if (!Array.isArray(tagValue)) tagValue = [tagValue];

    request +=
      '|> filter(fn: (r) => ' + tagValue.map((value) => `r["${tagName}"] ${tagOp} "${value}"`).join(' or ') + ')';
  }

  /* Select fields */
  let selectorFieldsList = [];
  let filterFieldsList = [];
  for (const filterField of filterFields) {
    if (Array.isArray(filterField)) {
      if (!selectorFieldsList.includes(filterField[0])) selectorFieldsList.push(filterField[0]);
      filterFieldsList.push(filterField);
    } else {
      if (!selectorFieldsList.includes(filterField)) selectorFieldsList.push(filterField);
    }
  }

  if (selectorFieldsList.length > 0) {
    request += '|> filter(fn: (r) =>';
    selectorFieldsList.map((field, index) => {
      request += ` r["_field"] == "${field}" `;
      if (index < selectorFieldsList.length - 1) {
        request += 'or';
        // TODO Manage operation "hasPrefix" in filter fields
        // } else if (includeDataFields) {
        //   request += ' or strings.hasPrefix(v: r["_field"], prefix: "data_"))';
      } else {
        request += ')';
      }
    });
  }

  /* Pivot */
  request += '|> pivot(rowKey: ["_time"], columnKey: ["_field"], valueColumn: "_value")';

  /* Filter on fields */
  for (let [fieldName, fieldOp, fieldValue] of filterFieldsList) {
    if (!Array.isArray(fieldValue)) fieldValue = [fieldValue];

    request +=
      '|> filter(fn: (r) => ' + fieldValue.map((value) => `r["${fieldName}"] ${fieldOp} ${value}`).join(' or ') + ')';
  }

  /* Aggregation */
  if (selectorFieldsList.length > 0 && (aggregation !== null || uniqueAggregation !== null)) {
    const aggregationField = selectorFieldsList[0];
    request += '|> group(columns: [' + group.map((e) => '"' + e + '"').join(',') + '])';

    /* Aggregation with temporal window */
    if (aggregation !== null) {
      request += `|> aggregateWindow(every: ${window}, fn: ${aggregation}, column: "${aggregationField}", createEmpty: ${createEmpty})`;
    } else {
      /* Unique aggregation */
      request += `|> ${uniqueAggregation}(column: "${aggregationField}")`;
    }

    request += `|> rename(columns: {${aggregationField}: "value"})`;
  }

  if (sort !== null) {
    request += '|> group(columns: [])';
    request += `|> sort(columns: ["_time"], desc: ${sort === 'desc'})`;
  }

  if (rateMultiplier !== null && window !== null) {
    const divider = convertSmallUnitToSeconds(window) * rateMultiplier;
    request += `|> map(fn: (r) => ({ r with value: float(v: r.value) / ${parseInt(divider)}.0 }))`;
  }

  /* Temporal window - Rename column */
  if (window !== null) {
    request += `|> rename(columns: {_time: "time"})`;
  }

  return request;
};

const _getFirstPartRequest = (bucket, measurement, start, stop) => {
  return (
    'import "strings" ' +
    `from(bucket: "${bucket}") ` +
    `|> range(start: ${start}, stop: ${stop}) ` +
    `|> filter(fn: (r) => r["_measurement"] == "${measurement}") `
  );
};

const _replaceDatesInRange = (request, start, stop) => {
  return request.replaceAll('v.timeRangeStart', start).replaceAll('v.timeRangeStop', stop);
};

/* Get Influxdb client query API */
const _getQueryApi = () => {
  return new InfluxDB({
    url: process.env.REACT_APP_INFLUX_PROTOCOL + '://' + process.env.REACT_APP_INFLUX_URL,
    token: process.env.REACT_APP_INFLUX_TOKEN,
  }).getQueryApi(process.env.REACT_APP_INFLUX_ORG);
};

/**
 * Get date string (ex: -7d) for influxdb request, depending on a Date
 * @param date
 * @return {string}
 */
export const getInfluxdbDateString = (date) => {
  let daysDifference = Math.floor((new Date().getTime() - date.getTime()) / 86400000);
  if (daysDifference > 0) {
    return '-' + daysDifference + 'd';
  } else {
    return 'now()';
  }
};

// DATA FORMAT FUNCTIONS
/* Get timestamp from response data, using app date format */
export const getTimestampFromTimeValue = (datetime) => {
  const date = new Date(datetime);
  return date.getTime();
};

/**
 * Get grouped influxdb data by date
 * @param data
 * @return {*[]}
 */
export const groupInfluxdbDataByDate = (data) => {
  let groupedData = [];
  data.map((singleData) => {
    if (groupedData[singleData._time]) {
      groupedData[singleData._time][singleData._field] = singleData._value;
    } else {
      groupedData[singleData._time] = {};
      groupedData[singleData._time][singleData._field] = singleData._value;
    }
  });
  return groupedData;
};
