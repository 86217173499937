import 'moment';
import 'chartjs-adapter-moment';
import { useEffect, useMemo, useState } from 'react';
import { Chart, registerables } from 'chart.js';
import { getLineDatasetsFromPoints, mergeDatasetsByChartId } from '../../services/entity/chart/chart-service';
import { SummaryStats } from './components/SummaryStats';
import { useDashboard } from '../../contexts/DashboardContext';
import { FilterBar } from './components/FilterBar';
import { useOrgData } from '../../contexts/OrgDataContext';
import { getChartsPromises } from '../../services/entity/chart/chart-api';
import { splitCharts } from '../../services/entity/chart/chart-service';
import { ChartBarIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { LoaderBlock } from './components/LoaderBlock';
import { MessageBlock } from './components/MessageBlock';
import { GenericChart } from './components/charts/GenericChart';
import { addHiddenLegendDataset } from '../../services/dashboard/dashboard-service';
import { cleanParsedData } from '../../helpers/file/file-helper';

Chart.register(...registerables);

export const Workshop = () => {
  const { workshops } = useOrgData();
  const {
    charts,
    selectedDateRange,
    setSelectedDateRange,
    workshopSelected,
    setWorkshopSelected,
    customData,
    setCustomDataCount,
  } = useDashboard();

  const [isSpecificChartsLoading, setIsSpecificChartsLoading] = useState(true);

  // Data for the graph
  const [graphsData, setGraphsData] = useState({});

  // Split charts into two arrays: 'summaryCharts' for those in the summary and 'specificCharts' for others
  const [summaryCharts, specificCharts] = useMemo(
    () =>
      splitCharts(
        charts.filter((chart) => chart.workshop?.id === workshopSelected.id),
        'workshop',
      ),
    [charts, workshopSelected],
  );

  const renderSpecificChart = () => {
    if (specificCharts?.length) {
      if (graphsData?.length) {
        return graphsData.map((dataset) => {
          const width = 100 / graphsData.length;
          const style = { width: `${width}%` };
          return (
            <div key={dataset.chartId} style={style}>
              <GenericChart
                type='main-graph'
                charts={specificCharts}
                graphsData={dataset}
                isLoading={isSpecificChartsLoading}
                selectedDateRange={selectedDateRange}
                setSelectedDateRange={setSelectedDateRange}
              />
            </div>
          );
        });
      } else if (isSpecificChartsLoading) {
        return <LoaderBlock />;
      } else {
        return <MessageBlock icon={InformationCircleIcon} message='No logs found for this period' />;
      }
    } else {
      return <MessageBlock icon={ChartBarIcon} message='No graphs saved yet' />;
    }
  };

  // Retrieve the information from our charts and create datasets for the graphs.
  useEffect(() => {
    const fetchData = async () => {
      if (specificCharts.length === 0) {
        setGraphsData(null);
        setIsSpecificChartsLoading(false);
        return;
      }

      setIsSpecificChartsLoading(true);

      // We retrieve the raw logs for each chartData in each chart.
      // The expected return is an array of objects, where each object represents a dataset with an ID for chart affiliation,
      // a chart type, and data points to create the chart curves.
      const resolvedData = await getChartsPromises(specificCharts, selectedDateRange, false);

      // We format our datasets to be suitable for what the Chart.js library expects for building a graph.
      const allDatasets = resolvedData.map((dataset, datasetIndex) => {
        return getLineDatasetsFromPoints(dataset, datasetIndex, selectedDateRange);
      });

      // We merge all datasets sharing the same chart ID to have only one dataset for a graph.
      const mergeDatasets = mergeDatasetsByChartId(allDatasets);

      // We do the final formatting to have an array of datasets with properties including the dataset and the chartId.
      const formattedDataset = Object.entries(mergeDatasets).map(([chartId, datasets]) => ({
        chartId: Number(chartId),
        datasets,
      }));

      // If we have custom data in localStorage, we apply it to our charts.
      const storedCSVData = localStorage.getItem('custom-csv-data');

      if (storedCSVData && formattedDataset.length > 0) {
        const parsedData = JSON.parse(storedCSVData);
        const cleanedData = cleanParsedData(parsedData);
        addHiddenLegendDataset(formattedDataset, cleanedData);
        setCustomDataCount(cleanedData.length);
      }

      setGraphsData(formattedDataset);
      setIsSpecificChartsLoading(false);
    };

    fetchData();
  }, [specificCharts, selectedDateRange]);

  // If a user uploads new custom data, we edit our charts with this new data.
  useEffect(() => {
    if (customData.length > 0 && graphsData?.length) {
      setIsSpecificChartsLoading(true);

      let oldData = graphsData;

      addHiddenLegendDataset(oldData, customData);

      setGraphsData(oldData);

      setTimeout(() => {
        setIsSpecificChartsLoading(false);
      }, 1000);
    } else {
      let oldData = graphsData;
      if (oldData?.length) {
        const dataWithoutCustomDataset = oldData.map((chart) => {
          return {
            ...chart,
            datasets: chart.datasets.filter((dataset) => dataset.label !== 'Hidden Legend Dataset'),
          };
        });
        setGraphsData(dataWithoutCustomDataset);
      }
    }
  }, [customData]);

  return (
    <>
      <FilterBar
        contexts={workshops}
        contextSelected={workshopSelected}
        setContextSelected={setWorkshopSelected}
        isChartLoading={isSpecificChartsLoading}
        graphsData={graphsData}
      />
      <SummaryStats summaryCharts={summaryCharts} context='workshop' />
      <div className='w-full h-full pt-4 flex gap-4'>{renderSpecificChart()}</div>
    </>
  );
};
