import { useState } from 'react';
import { Button } from '../../../../components/buttons/Button';
import { useAlert } from '../../../../contexts/AlertContext';
import { useTrialSetupContext } from '../../../../contexts/TrialSetupContext';

const checklist = [
  {
    id: 0,
    title: 'Camera Visibility',
    text: 'Camera is not obstructed',
    description: 'Ensure nothing blocks the view.',
  },
  {
    id: 1,
    title: 'Camera Stability',
    text: 'Camera is not moving',
    description: 'The camera should remain static.',
  },
  {
    id: 2,
    title: 'Image Quality',
    text: 'The image is cleaned up',
    description: 'No dirt or smudges on the lens.',
  },
  {
    id: 3,
    title: 'Relevance of Content',
    text: 'You are filming a relevant situation',
    description: 'The scene is appropriate and relevant.',
  },
];

const analysisChecklist = [
  {
    id: 0,
    title: 'Position Analysis',
    text: 'Analyzing camera position',
    description: "Checking the camera's angle and positioning.",
  },
  {
    id: 1,
    title: 'Scene Clarity',
    text: 'Checking scene clarity',
    description: 'Making sure the scene is clear and visible.',
  },
  {
    id: 2,
    title: 'Stability Verification',
    text: 'Verifying camera stability',
    description: 'Confirming the camera is steady.',
  },
  {
    id: 3,
    title: 'Cleanliness Inspection',
    text: 'Inspecting camera cleanliness',
    description: 'Checking for cleanliness of the camera.',
  },
];

export const SourcePanel = ({ isVideoAnalysis, setStep, isPrivacyPolicyAccepted, setIsVideoAnalysis }) => {
  const { showAlert } = useAlert();
  const { isCameraReady } = useTrialSetupContext();

  const [checkedItems, setCheckedItems] = useState({});

  const handleTestClick = () => {
    if (isCameraReady) {
      if (allChecked) {
        setIsVideoAnalysis(true);
      } else {
        showAlert('warning', 'Please check all checklist items before testing.');
      }
    } else {
      showAlert('warning', 'Please connect properly your video device.');
    }
  };

  const allChecked = checklist.every((item) => checkedItems[item.id]);

  return (
    <div
      id='source-panel-autosetup'
      className='border flex-grow border-gray-300 px-12 py-12 flex flex-col justify-between'
    >
      <CheckList isVideoAnalysis={isVideoAnalysis} checkedItems={checkedItems} setCheckedItems={setCheckedItems} />
      <div className='relative flex mt-4'>
        <Button
          type='button'
          category='secondary-btn'
          size='small'
          onClick={() => setStep(0)}
          disabled={isVideoAnalysis || !isPrivacyPolicyAccepted}
          className={isVideoAnalysis || (!isPrivacyPolicyAccepted && 'disabled')}
        >
          Back
        </Button>
        <div className='relative'>
          <Button
            type='button'
            category='tertiary-btn'
            size='small'
            onClick={handleTestClick}
            disabled={isVideoAnalysis || !isPrivacyPolicyAccepted}
            className={isVideoAnalysis || !isPrivacyPolicyAccepted ? 'disabled ml-4' : 'ml-4'}
          >
            Test
          </Button>
        </div>
      </div>
    </div>
  );
};

const CheckList = ({ isVideoAnalysis, checkedItems, setCheckedItems }) => {
  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className='flex flex-col justify-start item-start'>
      <p className='font-semibold text-lg'>{isVideoAnalysis ? 'Analysis' : 'Please verify : '}</p>
      <div className='mt-8 space-y-5'>
        {(isVideoAnalysis ? analysisChecklist : checklist).map((item) => (
          <div className='relative flex items-start' key={item.id}>
            <div className='flex h-6 items-center'>
              <input
                id={`checkbox-${item.id}`}
                aria-describedby={`checkbox-${item.id}-description`}
                name={item.id}
                type='checkbox'
                checked={checkedItems[item.id] || false}
                onChange={handleCheckboxChange}
                className='h-4 w-4 rounded border-gray-300 text-perception-blue focus:text-perception-blue'
              />
            </div>
            <div className='ml-3 text-sm leading-6'>
              <label htmlFor={`checkbox-${item.id}`} className='font-medium text-gray-900'>
                {item.title}
              </label>
              <p id={`checkbox-${item.id}-description`} className='text-gray-500'>
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
