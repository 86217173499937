import { useState, useRef } from 'react';
import { Modal } from '../../../../components/modals/Modal';
import { Button } from '../../../../components/buttons/Button';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import PrivacyFile from '../../../../assets/IP&Privacy commitments_Perception_VEn201123.pdf';

export const ModalPrivacy = ({
  isPrivacyPolicyOpened,
  setIsPrivacyPolicyOpened,
  setIsPrivacyPolicyAccepted,
  setStep,
}) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const contentRef = useRef(null);

  const handleScroll = () => {
    const element = contentRef.current;
    if (element) {
      const isNearBottom = element.scrollHeight - element.scrollTop - element.clientHeight < 1;

      if (isNearBottom) setIsScrolledToBottom(true);
    }
  };

  const handleClick = () => {
    setIsPrivacyPolicyAccepted(true);
    setIsPrivacyPolicyOpened(false);
  };

  return (
    <Modal isOpen={isPrivacyPolicyOpened} setIsOpen={setIsPrivacyPolicyOpened} staticBackdrop={true} size='large'>
      <div className='flex justify-between items-center'>
        <div className='flex flex-col'>
          <h2 className='text-xl font-bold'>Perception undertakes to respect your IP and Privacy</h2>
          <h3 className='text-lg'>Please read our IP & Privacy commitments.</h3>
        </div>
        <a href={PrivacyFile} download='IP&Privacy commitments_Perception_VEn201123.pdf'>
          <ArrowDownTrayIcon className='w-6 h-6 cursor-pointer text-perception-blue' />
        </a>
      </div>
      <div
        ref={contentRef}
        onScroll={handleScroll}
        className='border border-handddle-gray p-6 rounded max-h-[15rem] overflow-y-scroll mt-2'
      >
        <p className='text-sm'>
          <span className='font-semibold'>HANDDDLE SAS</span>, with a capital of 31,000 euros, whose registered office
          is located at 19 rue Berthelot, 33130 Bègles, registered with the RCS of Bordeaux under number 884116518, duly
          represented by Dylan TALEB in his capacity as Managing Director, acting both in his own name and on behalf of
          its current and future members, Hereinafter referred to as &quot;Perception Manufacturing&quot;
          <br />
          <br />
          <span className='font-semibold'>AND</span>
          <br />
          <br />
          The user, referred to individually and collectively as &quot;the User,&quot; accessing the Perception
          Manufacturing platform in the context of the Trial Mode, Hereinafter referred to as &quot;User&quot;
          <br />
          <br />
          <span className='font-bold'>ARTICLE 1 - OBJECT</span>
          <br />
          The Trial Mode of Perception Manufacturing provides an immersive interface allowing users to explore the
          functionalities of the platform. This includes visualizing basic events (such as human detection) and
          manipulating objects identified by Perception Manufacturing&quot;s algorithms. This experience aims to
          demonstrate technical capabilities without compromising the privacy or security of users.
          <br />
          <br />
          <span className='font-bold'>ARTICLE 2 - DATA COLLECTION</span>
          <br />
          <span className='font-semibold'>2.1 Types of data</span>
          <br />
          Perception Manufacturing will temporarily collect data in the user&quot;s environment. This data will be
          related to: Presence and movement of individuals Presence and movement of objects Time spent/durations Human
          interactions in the environment
          <br />
          <span className='font-semibold'>2.2 Real-time Video Streaming</span>
          <br />
          Images captured by the Perception Manufacturing platform are not stored. It is only real-time video streaming.
          No images are stored by Perception Manufacturing. It is specified that the Perception Manufacturing platform,
          utilizing computer vision technologies based on cameras to record video data, does not involve the parallel
          recording of their soundtracks.
          <br />
          <br />
          <span className='font-bold'>ARTICLE 3 - CONFIDENTIALITY AND DATA SECURITY</span>
          <br />
          Perception Manufacturing acknowledges that information and data related to the use of its platform are
          confidential and commits to treating them confidentially. Perception Manufacturing pledges not to disclose any
          confidential information to third parties unless such disclosure is required by law. Perception Manufacturing
          assures that rigorous measures have been implemented to ensure the confidentiality and security of collected
          data. These measures include secure storage protocols, restricted access to data, and protection measures
          against unauthorized access. Only individuals directly involved in data analysis, in compliance with their
          confidentiality obligations, will have access to the collected information. All collected data are processed
          in accordance with current regulations, especially regarding personal data protection.
          <br />
          <br />
          <span className='font-bold'>ARTICLE 4 - RIGHT TO IMAGE</span>
          <br />
          Perception Manufacturing recognizes the fundamental importance of protecting individual rights. Therefore,
          Perception Manufacturing commits to respecting the right to the image of users and individuals. This means
          that any use of visual data, such as photographs or videos, will be done respecting the individual rights of
          users. If, within the Trial Mode, a person other than the user is involved in the platform&quot;s use, the
          user must obtain informed consent from those individuals to ensure that each concerned person is fully
          informed and has a say in the use of their data or image. Since the Perception Manufacturing Algorithm is not
          trained for identifications, Perception Manufacturing assures the user that the right to the image, identity,
          and privacy of individuals will be fully respected.
          <br />
          <br />
          <span className='font-bold'>ARTICLE 5 - RESPONSIBLE USE OF THE TRIAL MODE</span>
          <br />
          Although the Trial Mode offers immersion in the functionalities of the Perception Manufacturing platform, it
          is imperative to respect certain usage limits. Users must not use the platform for illegal, intrusive, or
          harmful purposes towards others. The platform is provided &quot;as is&quot; without any warranties.
          <br />
          <br />
          <span className='font-bold'>ARTICLE 6 - MISUSE AND LIMITS OF LIABILITY</span>
          <br />
          Perception Manufacturing takes rigorous measures to prevent misuse of its platform. However, the company
          cannot be held responsible for actions independent of users. Thus, Perception Manufacturing disclaims all
          responsibility for the abusive use of the Trial Mode, including but not limited to activities contrary to the
          law, privacy infringements, or harmful consequences resulting from the platform&quot;s operation.
          <br />
          <br />
          <span className='font-bold'>ARTICLE 7 - RIGHT TO ACCOUNT DELETION</span>
          <br />
          Users have the right to request the deletion of their account at any time. Perception Manufacturing commits to
          carry out this deletion as quickly as possible, following the current legal provisions.
        </p>
      </div>
      <div id='modal-privacy-button' className='flex w-full mt-2'>
        <Button type='button' category='secondary-btn' size='medium' onClick={() => setStep(0)}>
          Back
        </Button>
        <Button
          type='button'
          category='tertiary-btn'
          disabled={!isScrolledToBottom}
          className={!isScrolledToBottom ? 'disabled ml-4' : 'ml-4'}
          size='medium'
          onClick={handleClick}
        >
          Next
        </Button>
      </div>
    </Modal>
  );
};
