import { useLocation } from 'react-router';
import { useUser } from '../../contexts/UserContext';

export const BlurLayout = () => {
  const { user, isUserDataLoading } = useUser();

  const url = useLocation();

  const whitelist = ['demo', 'reset-password', 'calibration'];

  if (whitelist.some((path) => url.pathname.includes(path))) return null;
  if (isUserDataLoading) return null;
  if (user?.confirmed) return null;

  return <div className='fixed top-0 left-0 z-20 w-full h-full bg-black/40 backdrop-blur-sm' />;
};
