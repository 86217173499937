export const PanelData = ({ workstation, patternFailures, isLoading }) => {
  const { name } = workstation;

  const getPatternFailureNotFixed = () => {
    return patternFailures.filter((patternFailure) => patternFailure.has_been_fixed === 0).length;
  };

  if (isLoading) return null;

  return (
    <div className='fixed bottom-0 left-0 w-full bg-gray-100 border-t border-perception-gray-300'>
      <div className='flex justify-between w-full px-4 py-2 text-sm'>
        <span className='font-bold uppercase text-md'>{name}</span>
        <span>{patternFailures.length} alertes détectées</span>
        <span>{getPatternFailureNotFixed()} non résolue</span>
        <span />
        <span />
      </div>
    </div>
  );
};
