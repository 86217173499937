import axios from 'axios';

export const instantiateEngine = async (id) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ENGINE_ORCHESTRATOR_ADDRESS}instantiate`, {
      id: id,
    });

    if (response.data.success) return { success: true, error_code: 0, error_message: null };
    else {
      if (response.data.error_code === 409)
        return {
          success: true,
          error_code: response.data.error_code,
          error_message: response.data.error_message,
        };
      else
        return {
          success: false,
          error_code: response.data.error_code,
          error_message: response.data.error_message,
        };
    }
  } catch (error) {
    console.error('Error instantiating service:', error);
    return { success: false, error };
  }
};

export const keepAliveEngine = async (userId, newData = null) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_ENGINE_ORCHESTRATOR_ADDRESS}alive`, {
      id: userId,
      new_data: newData,
    });

    if (response.data.success) return { success: true, error_code: 0, error_message: null };
    else
      return {
        success: true,
        error_code: response.data.error_code,
        error_message: response.data.error_message,
      };
  } catch (error) {
    console.error('Error keeping service alive:', error);
    return { success: false, error };
  }
};
