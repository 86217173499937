import { InputSelect } from '../../../../components/forms/inputs/InputSelect';
import { useOrgData } from '../../../../contexts/OrgDataContext';
import { Panel } from '../../../../components/panels/Panel';

export const SideSetupPanel = ({ workshopSelected, setWorkshopSelected }) => {
  const { workshops } = useOrgData();

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='flex flex-col h-1/3'>
        <Panel title='WORKSHOPS'>
          <InputSelect
            items={workshops}
            onChange={(item) => setWorkshopSelected(item)}
            itemToString={(item) => item?.name}
            defaultValue={workshopSelected}
          />
        </Panel>
      </div>
      <div className='flex flex-col flex-1'>
        <Panel title='OTHER'></Panel>
      </div>
    </div>
  );
};
