export const USER_FUNCTIONS = [
  { id: 1, name: 'Operations Manager' },
  { id: 2, name: 'Production Supervisor' },
  { id: 3, name: 'Maintenance Technician' },
  { id: 4, name: 'Quality Control Engineer' },
  { id: 5, name: 'Process Engineer' },
  { id: 6, name: 'Supply Chain Analyst' },
  { id: 7, name: 'Manufacturing Engineer' },
  { id: 8, name: 'Health and Safety Officer' },
  { id: 9, name: 'Industrial Engineer' },
  { id: 10, name: 'Plant Manager' },
  { id: 11, name: 'Continuous Improvement Manager' },
  { id: 12, name: 'Automation Engineer' },
  { id: 13, name: 'Data Analyst' },
  { id: 14, name: 'Project Manager' },
  { id: 15, name: 'Research and Development Engineer' },
  { id: 16, name: 'Environmental Engineer' },
  { id: 17, name: 'Logistics Manager' },
  { id: 18, name: 'Product Manager' },
  { id: 19, name: 'Business Development Manager' },
  { id: 20, name: 'IT/Systems Manager' },
  { id: 21, name: 'Other' },
];

export const ASSISTANCE_POPUP = {
  CREATION_SEGMENT: {
    id: 'creation_segment',
    title: 'Create a new point of interest',
    content:
      'To create a new point of interest, you can hover over areas of your images and click on the point that interests you. You will then be prompted to provide a name and a comment for this point of interest.',
  },
};
