import { useState, useEffect, useRef } from 'react';
import { LogPanelFactory } from '../../../../components/logs/LogPanelFactory';
import { useCases } from '../../../../temp/mock';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline';
import { convertTimeToSeconds } from '../../../../helpers/date/date-helper';

export const UseCaseVideo = ({ selectedUseCaseId, setSelectedUseCaseId }) => {
  const videoRef = useRef();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [logs, setLogs] = useState([]);
  const [panelOpacity, setPanelOpacity] = useState(0);

  const selectedUseCase = useCases.find((useCase) => useCase.id === selectedUseCaseId);
  const useCaseEvents = selectedUseCase.logs;

  const handleBack = () => setSelectedUseCaseId(null);

  useEffect(() => {
    const checkEvents = () => {
      if (!isVideoLoaded) return;

      const currentTime = Math.floor(videoRef.current.currentTime);
      useCaseEvents.forEach((event) => {
        const eventTime = convertTimeToSeconds(event.time);
        if (currentTime === eventTime) {
          const logWithTime = {
            ...event,
            time: new Date().toISOString(),
          };
          setLogs((prevLogs) => [...prevLogs, logWithTime]);
        }
      });
    };

    let interval;
    if (isVideoLoaded) {
      interval = setInterval(checkEvents, 1000);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isVideoLoaded, useCaseEvents]);

  useEffect(() => {
    if (isVideoLoaded) {
      const timeoutId = setTimeout(() => {
        setPanelOpacity(1);
      }, 100);

      return () => clearTimeout(timeoutId);
    }
  }, [isVideoLoaded]);

  return (
    <div className='flex flex-col justify-start items-start w-full px-2'>
      <div className='flex flex-col justify-center items-center w-full overflow-hidden'>
        <div id='discover-video-container' className='w-5/6 flex justify-center items-center overflow-hidden relative'>
          <ArrowLeftCircleIcon
            className='absolute left-4 top-4 w-8 h-8 text-white cursor-pointer z-20'
            onClick={handleBack}
          />
          <video
            ref={videoRef}
            src={selectedUseCase.video}
            className='max-w-full max-h-[500px] w-auto h-auto object-contain'
            controls={false}
            autoPlay
            muted
            onLoadedData={() => setIsVideoLoaded(true)}
          />
        </div>
        <div
          id='discover-video-panel'
          className='w-5/6 h-[400px] sm:h-[300px] md:h-[350px] border-gray-300 flex flex-col transition-opacity duration-700 ease-in-out'
          style={{ opacity: panelOpacity }}
        >
          <div className='h-full bg-perception-black-800 overflow-y-hidden'>
            <div className='h-full pt-4 pl-6'>
              <LogPanelFactory logs={logs} setLogs={setLogs} filter={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
