import { LOGS_TYPES } from '../../log/log-service';

export const groupCalibrationsByDate = (calibrations) => {
  const calibrationsGroupedByDate = calibrations.reduce((acc, calibration) => {
    const timestamp = calibration.date;
    if (!acc[timestamp]) {
      acc[timestamp] = {
        date: timestamp,
        imageUrls: [],
      };
    }
    acc[timestamp].imageUrls.push(calibration.file_path);
    return acc;
  }, {});

  return calibrationsGroupedByDate;
};

export const convertCalibrationsToLogs = (calibrations) => {
  return Object.values(calibrations).map((calibration) => {
    const time = new Date(calibration.date * 1000).toISOString();
    return {
      ...calibration,
      time: time,
      duration: 0,
      type: LOGS_TYPES.CALIBRATION,
    };
  });
};
