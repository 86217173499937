import axios from 'axios';

export const getWorkshopsByOrganizationId = async (organizationId) => {
  const token = localStorage.getItem('token');

  if (!token) {
    console.error('Token is required');
    return null;
  }

  const apiUrl = `${process.env.REACT_APP_API_ADDRESS}workshops?organization=${organizationId}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 && response.data && response.data[0]) {
      return response.data;
    } else {
      console.error('Not workshops found');
      return null;
    }
  } catch (error) {
    console.error('Error retrieving workshop on database', error);
    return null;
  }
};

export const getOrganizationByToken = async (token) => {
  const apiUrl = `${process.env.REACT_APP_API_ADDRESS}organizations?token=${token}`;
  const response = await axios.get(apiUrl);
  if (response) {
    return response.data[0];
  }
};
