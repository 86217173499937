import axios from 'axios';
import { getInfluxdbData } from '../../api/influxdb-tools';
import { _computeTrendBetweenValues, _getDatasetDisplayData } from './chart-service';

// Take a list of charts data from the database
// and return a list of promises to get the data corresponding to each chart
export const getChartsPromises = async (charts, selectedDateRange, computeTrend = false) => {
  if (!charts.length) return [];

  const promises = charts.flatMap((chart) => {
    return chart.chart_data.map(async (chartData) => {
      const {
        request_bucket,
        request_measurement,
        request_filter_tags,
        request_filter_fields,
        request_window,
        request_aggregation,
        request_unique_aggregation,
        request_group,
        request_rate_multiplier,
        request_full_request,
      } = chartData;

      // Warnings about chart data
      if ((chart.type === 'value' || chart.type === 'donut') && request_unique_aggregation === null)
        console.warn(
          `Chart "${chartData.name}" with ID "${chartData.id}" is of type "${chart.type}" but has no unique aggregation function.`,
        );
      if (chart.type === 'line' && request_unique_aggregation !== null)
        console.warn(
          `Chart "${chartData.name}" with ID "${chartData.id}" is of type "${chart.type}" but has a unique aggregation function.`,
        );

      // Convert start and stop date
      const startDate = new Date(selectedDateRange.start);
      const stopDate = new Date(selectedDateRange.stop);

      // Convert date to UTC
      const start = new Date(startDate.getTime()).toISOString();
      const stop = new Date(stopDate.getTime()).toISOString();

      const createEmpty = chart.type === 'line';

      // Get data from Influx Database with provided information
      const data = await getInfluxdbData(
        request_bucket,
        request_measurement,
        request_filter_tags,
        request_filter_fields,
        start,
        stop,
        request_window,
        request_aggregation,
        request_unique_aggregation,
        request_group,
        request_rate_multiplier,
        request_full_request,
        createEmpty,
      );

      let datasetDisplayData = _getDatasetDisplayData(chart, chartData, data, selectedDateRange);

      // If needed, compute same data for the previous period
      if (computeTrend) {
        // Compute the previous time period based on the range date selected by the user
        const previousStart = new Date(
          new Date(selectedDateRange.start).getTime() - (selectedDateRange.stop - selectedDateRange.start),
        );
        const previousStop = new Date(
          new Date(selectedDateRange.stop).getTime() - (selectedDateRange.stop - selectedDateRange.start),
        );

        // Adjust for time zone offset
        const previousStartAdjusted = new Date(previousStart.getTime()).toISOString();
        const previousStopAdjusted = new Date(previousStop.getTime()).toISOString();

        // Fetch data for the previous time period
        const previousData = await getInfluxdbData(
          request_bucket,
          request_measurement,
          request_filter_tags,
          request_filter_fields,
          previousStartAdjusted,
          previousStopAdjusted,
          request_window,
          request_aggregation,
          request_unique_aggregation,
          request_group,
          request_rate_multiplier,
          request_full_request,
          createEmpty,
        );

        if (data.length > 0 && previousData.length > 0) {
          datasetDisplayData.trend = _computeTrendBetweenValues(data[0].value, previousData[0].value);
        }
      }

      return datasetDisplayData;
    });
  });

  return Promise.all(promises);
};

// Get all charts from an organization based on his id
export const getChartsByOrganizationId = async (organizationId) => {
  const token = localStorage.getItem('token');

  if (!token) {
    console.error('Token is required');
    return null;
  }

  const apiUrl = `${process.env.REACT_APP_API_ADDRESS}charts?organization=${organizationId}`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200 && response.data && response.data[0]) {
      return response.data;
    } else {
      console.error('Not charts found');
      return null;
    }
  } catch (error) {
    console.error('Error retrieving charts on database', error);
    return null;
  }
};
