import { Controller, useForm } from 'react-hook-form';
import { patchData, useGetData } from '../../../services/api/api-tools';
import { useState } from 'react';
import { ListboxOptionWithCheck } from '../../../components/forms/ListboxOptionWithCheck';
import { GenericListbox } from '../../../components/forms/GenericListbox';
import { getUserGroupById } from '../../../services/entity/user/user-service';
import { ProfileForm } from './ProfileForm';
import { useAlert } from '../../../contexts/AlertContext';
import { getColorById } from '../../../services/entity/color/color-service';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Input } from '../../../components/forms/inputs/Input';
import { Loader } from '../../../components/loading/Loader';

export const UpdateUserForm = (props) => {
  const { user } = props;

  const { showAlert } = useAlert();

  const colors = useGetData('colors', 'colors');

  const { handleSubmit, control } = useForm();
  const [selectedUserGroupId, setSelectedUserGroupId] = useState(user.user_group ? user.user_group.id : 0);
  const [selectedColorId, setSelectedColorId] = useState(user.color ? user.color.id : 0);
  const [isFormLoading, setIsFormLoading] = useState(false);

  const [acceptEmailNotifications, setAcceptEmailNotifications] = useState(
    user.notifications_settings?.includes('PFD_EMAIL'),
  );

  /* Form submission */
  const onSubmit = async (data) => {
    setIsFormLoading(true);
    data.user_group = selectedUserGroupId > 0 ? selectedUserGroupId : null;
    data.color = selectedColorId > 0 ? selectedColorId : null;

    if (acceptEmailNotifications) {
      data.notifications_settings = user.notifications_settings || [];
      if (!data.notifications_settings.includes('PFD_EMAIL')) {
        data.notifications_settings.push('PFD_EMAIL');
      }
    } else {
      data.notifications_settings = (user.notifications_settings || []).filter((setting) => setting !== 'PFD_EMAIL');
    }

    const path = `users/${data.id}`;
    const body = {
      color: `/api/colors/${data.color}`,
      email: data.email,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone,
      notifications_settings: data.notifications_settings,
    };

    await patchData(path, body)
      .then(() => {
        showAlert('success', 'Your profile has been successfully updated.');
        setIsFormLoading(false);
      })
      .catch(() => {
        showAlert('error', 'Oops! An error occurred while updating your profile.');
        setIsFormLoading(false);
      });
  };

  /* Get name of a user group - default : 'None' */
  const getUserGroupNameById = (userGroupId) => {
    if (userGroupId > 0) {
      let userGroup = getUserGroupById(userGroupId, user);
      if (userGroup !== null) {
        return userGroup.name;
      }
    }
    return 'None';
  };

  /* Get name of selected user group - default : 'None' */
  const getColorNameById = (colorId) => {
    if (colorId > 0) {
      let color = getColorById(colorId, colors.data);
      if (color !== null) {
        return color.color_name;
      }
    }
    return 'None';
  };

  return (
    <ProfileForm
      title='My personal informations'
      isLoading={colors.isLoading}
      isError={colors.isError}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      buttonIcon={CheckIcon}
      buttonLabel='Update my profile'
    >
      {isFormLoading ? <Loader /> : ''}
      <>
        {/* id (hidden) */}
        <Controller
          control={control}
          name='id'
          defaultValue={user.id}
          render={({ field }) => <input type='hidden' {...field} />}
        />

        {/* Firstname */}
        <Controller
          control={control}
          name='firstname'
          rules={{ required: true }}
          defaultValue={user.firstname}
          render={({ field }) => (
            <Input
              label='Firstname'
              htmlFor='firstname'
              type='text'
              id='firstname'
              category='secondary'
              placeholder='Your firstname'
              isLabelHidden={false}
              {...field}
            />
          )}
        />

        {/* Name */}
        <Controller
          control={control}
          name='lastname'
          rules={{ required: true }}
          defaultValue={user.lastname}
          render={({ field }) => (
            <Input
              label='Name'
              htmlFor='lastname'
              type='text'
              id='lastname'
              category='secondary'
              placeholder='Your lastname'
              isLabelHidden={false}
              {...field}
            />
          )}
        />

        {/* Email */}
        <Controller
          control={control}
          name='email'
          rules={{ required: true }}
          defaultValue={user.email}
          render={({ field }) => (
            <Input
              label='Email'
              htmlFor='email'
              type='email'
              id='email'
              category='secondary'
              placeholder='Your email'
              isLabelHidden={false}
              {...field}
            />
          )}
        />

        {/* Phone */}
        <Controller
          control={control}
          name='phone'
          rules={{ required: false }}
          defaultValue={user.phone}
          render={({ field }) => (
            <Input
              label='Phone'
              htmlFor='phone'
              type='phone'
              id='email'
              category='secondary'
              placeholder='Your phone number'
              isLabelHidden={false}
              {...field}
            />
          )}
        />

        {/* Group */}
        <div>
          <Controller
            control={control}
            name='user_group'
            rules={{ required: false }}
            value={selectedUserGroupId}
            render={({ field: { onChange } }) => (
              <GenericListbox
                controllerOnChange={onChange}
                onChangeAction={setSelectedUserGroupId}
                value={selectedUserGroupId}
                selectedElementName={getUserGroupNameById(selectedUserGroupId)}
                label='Group'
              >
                <>
                  <ListboxOptionWithCheck value={0} name='None' key='user_group_0' />
                  {user.organization ? (
                    user.organization.user_groups.map((user_group) => (
                      <ListboxOptionWithCheck
                        value={user_group.id}
                        name={user_group.name}
                        key={'user_group_' + user_group.id}
                      />
                    ))
                  ) : (
                    <></>
                  )}
                </>
              </GenericListbox>
            )}
          />
        </div>

        {/* Color */}
        <div>
          <Controller
            control={control}
            name='color'
            rules={{ required: false }}
            value={selectedColorId}
            render={({ field: { onChange } }) => (
              <GenericListbox
                controllerOnChange={onChange}
                onChangeAction={setSelectedColorId}
                value={selectedColorId}
                selectedElementName={getColorNameById(selectedColorId)}
                label='Color'
              >
                <>
                  <ListboxOptionWithCheck value={0} name='None' key='color_0' />
                  {colors.data ? (
                    colors.data.map((color) => (
                      <ListboxOptionWithCheck value={color.id} name={color.color_name} key={'color_' + color.id} />
                    ))
                  ) : (
                    <></>
                  )}
                </>
              </GenericListbox>
            )}
          />
        </div>

        {/* Mail notifications */}
        <Controller
          control={control}
          name='acceptEmailNotifications'
          defaultValue={acceptEmailNotifications}
          render={() => (
            <div className='checkbox-container ml-2'>
              <input
                type='checkbox'
                id='acceptEmailNotifications'
                className='checkbox-input'
                checked={acceptEmailNotifications}
                onChange={(e) => setAcceptEmailNotifications(e.target.checked)}
              />
              <label htmlFor='acceptEmailNotifications' className='checkbox-label ml-6 text-white'>
                Accept to receive email notifications
              </label>
            </div>
          )}
        />
      </>
    </ProfileForm>
  );
};
