import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { InputSelect } from '../../../../../components/forms/inputs/InputSelect';
import { ChevronUpDownIcon, TrashIcon } from '@heroicons/react/24/outline';
import { usePatternSetup } from '../../../../../contexts/PatternSetupContext';
import { Modal } from '../../../../../components/modals/Modal';
import { useOrgData } from '../../../../../contexts/OrgDataContext';

const defaultOption = { id: 'default', name: 'Add new log' };

export const PatternEventTypes = ({ isWarnModalOpen, setIsWarnModalOpen, setIdPatternEventTypeToDelete }) => {
  const { patternEventTypes, setPatternEventTypes, patternIsActive } = usePatternSetup();
  const { eventTypes } = useOrgData();

  const [selectKey, setSelectKey] = useState(Math.random());
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [draggedResult, setDraggedResult] = useState(null);

  // Add an patternEventType to the events pattern.
  const addPatternEventTypeToPattern = (eventType) => {
    const newPatternEventType = {
      id: eventType.id,
      order: patternEventTypes.length + 1,
      event_type: {
        id: eventType.id,
        name: eventType.name,
        subtype: eventType.subtype,
      },
    };

    setPatternEventTypes((currentPatternEventTypes) => {
      return [...currentPatternEventTypes, newPatternEventType];
    });

    // keep default value on select input
    setSelectKey(Math.random());
  };

  // Handle the end of a drag operation in the drag-and-drop context.
  // It rearranges the events pattern based on the result of the drag operation.
  // If pattern is active, we ask user to confirm is modification
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (patternIsActive) {
      setDraggedItem(patternEventTypes[result.source.index]);
      setDraggedResult(result);
      setIsConfirmationVisible(true);
    } else {
      const items = Array.from(patternEventTypes);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      const updatedItems = items.map((item, index) => {
        return {
          ...item,
          order: index + 1,
        };
      });

      setPatternEventTypes(updatedItems);
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <span className='mt-4 font-semibold text-md text-perception-gray-500'>EVENTS</span>
      <div className='w-full mt-4'>
        <InputSelect
          key={selectKey}
          items={eventTypes}
          onChange={addPatternEventTypeToPattern}
          itemToString={(item) => item?.name}
          defaultValue={defaultOption}
        />
      </div>
      <StrictModeDroppable droppableId='droppable'>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className='mt-2' style={{ userSelect: 'none' }}>
            {patternEventTypes.map((patternEventType, index) => (
              <Draggable key={patternEventType.id} draggableId={patternEventType.id.toString()} index={index}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <PatternEventType
                      patternEventType={patternEventType}
                      isWarnModalOpen={isWarnModalOpen}
                      setIsWarnModalOpen={setIsWarnModalOpen}
                      setIdPatternEventTypeToDelete={setIdPatternEventTypeToDelete}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
      {isConfirmationVisible && (
        <DraggableConfirmation
          isConfirmationVisible={isConfirmationVisible}
          setIsConfirmationVisible={setIsConfirmationVisible}
          onConfirm={() => {
            const items = Array.from(patternEventTypes);
            const [reorderedItem] = items.splice(patternEventTypes.indexOf(draggedItem), 1);
            items.splice(draggedResult.destination.index, 0, reorderedItem);

            const updatedItems = items.map((item, index) => {
              return {
                ...item,
                order: index + 1,
              };
            });

            setPatternEventTypes(updatedItems);
          }}
        />
      )}
    </DragDropContext>
  );
};

const PatternEventType = ({ patternEventType, setIsWarnModalOpen, setIdPatternEventTypeToDelete }) => {
  const { eventTypes } = useOrgData();

  const onDeleteClick = (e) => {
    e.stopPropagation();
    setIsWarnModalOpen(true);
    setIdPatternEventTypeToDelete(patternEventType.id);
  };

  const eventType = eventTypes.find((eventType) => eventType.subtype === patternEventType.event_type.subtype);
  const eventTypeText = eventType.name;

  return (
    <div className='flex flex-col sm:flex-row items-center mt-2 sm:mt-4 w-full gap-x-2 border-b-[1px] pb-2 border-perception-gray-500'>
      <ChevronUpDownIcon className='w-5 h-5 text-perception-gray-500' />
      <p className='text-xs sm:text-sm text-perception-gray-500'>{patternEventType.order} - </p>
      <div className='flex items-start justify-start flex-1'>
        <p className='text-xs sm:text-sm text-perception-gray-500'>{eventTypeText}</p>
      </div>
      <div className='flex items-center'>
        <TrashIcon className='w-4 h-4 cursor-pointer text-perception-gray-500' onClick={onDeleteClick} />
      </div>
    </div>
  );
};

const DraggableConfirmation = ({ isConfirmationVisible, setIsConfirmationVisible, onConfirm }) => {
  return (
    <Modal isOpen={isConfirmationVisible} setIsOpen={setIsConfirmationVisible}>
      <div className='p-4'>
        <h3 className='text-lg font-medium leading-6 text-gray-900 text-start'>Confirm changes</h3>
        <div className='mt-2'>
          <p className='text-sm text-gray-500 text-start'>Are you sure you want to rearrange this item?</p>
        </div>
        <div className='flex justify-start mt-4'>
          <button
            className='inline-flex justify-center px-4 py-2 mr-2 text-sm font-medium text-white border border-transparent rounded-md bg-perception-blue text-whitem'
            onClick={() => {
              onConfirm();
              setIsConfirmationVisible(false);
            }}
          >
            Modify
          </button>
          <button
            className='inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50'
            onClick={() => setIsConfirmationVisible(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);

  if (!enabled) {
    return null;
  }

  return <Droppable {...props}>{children}</Droppable>;
};
