import Peer from 'peerjs';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { generateRandomString } from '../../helpers/common/generateRandomString';

export const TrialMobilePage = () => {
  const [mobileId] = useState(generateRandomString(8));
  const [stream, setStream] = useState();
  const videoRef = useRef();
  const { desktopId } = useParams();

  useEffect(() => {
    const instanceVideoRef = videoRef.current;
    let newPeer;

    const cleanUpCamera = (videoRefInstance) => {
      if (videoRefInstance?.srcObject) {
        videoRefInstance.srcObject.getTracks().forEach((track) => {
          if (track.readyState === 'live') {
            track.stop();
          }
        });
      }
    };

    if (!stream) {
      navigator.mediaDevices
        .getUserMedia({
          video: { facingMode: 'environment' },
        })
        .then((currentStream) => {
          if (videoRef.current) {
            setStream(currentStream);
            videoRef.current.srcObject = currentStream;
          }

          newPeer = new Peer(mobileId, {
            host: process.env.REACT_APP_PEER_SERVER_URL,
            port: process.env.REACT_APP_PEER_SERVER_PORT,
            path: process.env.REACT_APP_PEER_SERVER_APP,
            secure: process.env.REACT_APP_PEER_SERVER_SECURE,
          });

          newPeer.on('open', () => {
            newPeer.call(desktopId, currentStream);
          });

          newPeer.on('error', (err) => {
            console.error('Erreur Peer:', err);
          });
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération du flux média:', error);
        });
    }

    return () => {
      cleanUpCamera(instanceVideoRef);
    };
  }, [mobileId, desktopId]);

  return <video ref={videoRef} autoPlay playsInline className='absolute w-full h-auto' />;
};
