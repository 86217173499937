import { Button } from '../../../components/buttons/Button';
import { Loader } from '../../../components/loading/Loader';

export const ProfileForm = (props) => {
  const { title, isLoading, isError, handleSubmit, onSubmit, buttonIcon: Icon, buttonLabel, children } = props;

  return (
    <div className='h-full bg-perception-black-800 p-12'>
      <h2 className='text-lg mb-4 text-white font-semibold'>{title}</h2>
      {isLoading ? (
        <Loader />
      ) : isError ? (
        <Error message='Form unavailable' />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-5 mb-6'>{children}</div>
          <div className='flex justify-start pl-2'>
            <Button type='submit' className='text-sm' size='small' category='tertiary-btn'>
              <Icon />
              <span className='pl-2'>{buttonLabel}</span>
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

const Error = (props) => {
  const { message } = props;

  return (
    <div>
      <h1 className='font-bold text-sm'>An error occurred</h1>
      {message && <div className='text-gray-600 text-sm'>{message}</div>}
    </div>
  );
};
