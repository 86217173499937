import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { patchData } from '../../services/api/api-tools';
import { useAlert } from '../../contexts/AlertContext';
import { getUserByToken } from '../../services/entity/user/user-api';
import { Loader } from '../../components/loading/Loader';

export const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (token) {
      try {
        const getUser = async () => {
          const user = await getUserByToken(token);
          if (user) {
            setUserId(user.id);
          } else {
            navigate('/production');
          }
        };

        getUser();
      } catch (err) {
        console.error(err);
      }
    }
  }, [token]);

  return (
    <div className='fixed left-0 top-0 w-screen h-screen z-50'>
      <section className='py-10 bg-perception-black-900 h-full sm:py-16 lg:py-24'>
        <div className='flex justify-center h-full w-full'>
          <Form token={token} userId={userId} />
        </div>
      </section>
    </div>
  );
};

export const Form = ({ token, userId }) => {
  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const validatePassword = (value) => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError('');
    setIsLoading(true);

    if (password !== confirmPassword) {
      setError('The passwords do not match.');
      setIsLoading(false);
      return;
    }

    if (!validatePassword(password)) {
      setError('Password must contain at least 8 characters, including a number, an uppercase and a lowercase letter.');
      setIsLoading(false);
      return;
    }
    try {
      if (token) {
        const response = await patchData(
          `users/${userId}/password`,
          {
            new_password: password,
            confirm_password: confirmPassword,
            token: token,
          },
          true,
        );
        if (response.id) {
          showAlert('success', 'Password has been successfully edited on database.');
          navigate('production');
        } else {
          showAlert('warning', 'Oops! Something went wrong while attempting to edit the password.');
        }
      } else {
        showAlert('warning', 'Oops! Your are not allowed to update your password.');
      }
    } catch (err) {
      console.error(err);
      showAlert('error', 'Oops! Something went wrong while attempting to edit the password.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className='flex flex-col justify-center'>
      {isLoading && <Loader />}
      <h3 className='text-3xl font-semibold text-perception-gray-500'>Reset your password</h3>
      <form className='my-2 w-[400px]' onSubmit={handleSubmit}>
        <div className='space-y-6'>
          <div>
            <div className='mt-2.5 relative'>
              {error && <p className='text-red-500'>{error}</p>}
              <input
                type='password'
                id='password'
                className='block w-full border-0 bg-perception-gray-800 text-white outline-none shadow-sm  py-2 px-2 placeholder:text-gray-400 my-2 sm:text-sm'
                placeholder='Enter your new password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                type='password'
                id='confirm-password'
                className='block w-full border-0 bg-perception-gray-800 text-white outline-none shadow-sm  py-2 px-2 placeholder:text-gray-400 my-2 sm:text-sm'
                placeholder='Confirm your new password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type='submit'
              className='inline-flex items-center justify-center w-full px-4 py-2 text-sm font-semibold text-white transition-all duration-200 bg-perception-blue border border-transparent focus:outline-none hover:bg-perception-blue/80'
            >
              Update
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
