import { Fragment } from 'react';
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';

const Banner = ({ pattern, success }) => {
  const completedStepsCount = pattern?.pattern_event_types?.filter(
    (patternEventType) => patternEventType.validated,
  ).length;

  const getBackgroundColor = () => {
    if (success?.state && success?.error_history.length) {
      return 'bg-perception-warn-500';
    } else if (success?.state && !success?.error_history.length) {
      return 'bg-perception-success-300';
    } else if (pattern) {
      return 'bg-perception-blue';
    } else {
      return 'bg-perception-gray-500';
    }
  };

  const backgroundClass = getBackgroundColor();

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.5 }}
      className={clsx(backgroundClass, 'text-white w-full h-full flex justify-center items-center px-6 flex-col')}
    >
      {success?.state ? <div className='text-center text-4xl font-bold'>FIN DE LA GAMME OPERATOIRE</div> : ''}
      <div className='flex justify-center items-center mt-4 w-full'>
        <div className='flex-grow text-center text-4xl font-bold'>{pattern?.name || 'Aucun pattern détecté'}</div>
        {completedStepsCount && (
          <div className='ml-2 text-2xl'>
            {completedStepsCount}/{pattern.pattern_event_types.length}
          </div>
        )}
      </div>
    </motion.div>
  );
};

export const Banners = ({ highestScorePattern, otherPatterns, success }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='flex h-[200px] w-full gap-x-2'
    >
      <Fragment>
        <div
          className={` ${!success.state && otherPatterns.length > 0 ? 'w-1/2 transition-all duration-500' : 'w-full transition-all duration-500'}`}
        >
          {highestScorePattern ? (
            <div className='w-full h-full'>
              <Banner pattern={highestScorePattern} success={success} />
            </div>
          ) : (
            <div className='w-full h-full'>
              <Banner />
            </div>
          )}
        </div>
        <AnimatePresence>
          {!success.state && otherPatterns.length > 0 && (
            <motion.div layout className='flex-1 flex flex-col gap-y-2 opacity-70'>
              {otherPatterns.map((pattern, index) => (
                <motion.div
                  key={index}
                  layout
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className='flex-1 w-full border-b last:border-b-0 opacity-70'
                >
                  <Banner pattern={pattern} />
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </Fragment>
    </motion.div>
  );
};
