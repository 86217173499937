import { useUser } from '../../../contexts/UserContext';
import { UpdateUserForm } from './UpdateUserForm';
import { UpdateUserPassword } from './UpdateUserPassword';

export const Profile = () => {
  const { user } = useUser();

  if (!user) return null;

  return (
    <div className='relative h-full bg-black px-6 flex flex-col justify-center items-center'>
      <div className='flex justify-center items-center w-full'>
        <div className='grid grid-cols-1 w-full'>
          <div className='mt-8 grid grid-cols-1 lg:grid-cols-2 gap-4'>
            <UpdateUserForm user={user} />
            <UpdateUserPassword user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};
