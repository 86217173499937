import { useQuery } from 'react-query';
import axios from 'axios';

const methodGetRequest = 'GET';

/* Fetch data using useQuery hook */
export const useGetData = (key, path, params = {}, enabled = true) => {
  const token = localStorage.getItem('token');
  const getData = async () => {
    let header = token ? { Authorization: `Bearer ${token}` } : null;
    let url = process.env.REACT_APP_API_ADDRESS + path;
    const { data } = await axios.get(url, {
      headers: header,
      params: params,
    });

    if (data['hydra:member']) {
      return data['hydra:member'];
    } else {
      return data;
    }
  };
  return useQuery(key, getData, { refetchOnWindowFocus: false, enabled: enabled });
};

/* Fetch data without useQuery hook */
export const fetchData = (path, params = null) => {
  const token = localStorage.getItem('token');
  let url = process.env.REACT_APP_API_ADDRESS + path;

  if (params !== null) {
    url += '?' + params;
  }

  return fetch(url, {
    method: methodGetRequest,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => response.json());
};

/**
 * Generic function to patch data
 * @param path
 * @param body
 * @param anonymous_request
 * @returns {Promise<any>}
 */
export const patchData = (path, body, anonymous_request = false) => {
  const token = localStorage.getItem('token');

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/merge-patch+json',
  };

  if (!anonymous_request) headers.Authorization = `Bearer ${token}`;

  return fetch(process.env.REACT_APP_API_ADDRESS + path, {
    method: 'PATCH',
    headers: headers,
    body: JSON.stringify(body),
  }).then((response) => response.json());
};

/**
 * Generic function to post data
 * @param path
 * @param body
 * @param anonymous_request
 * @return {Promise<any>}
 */
export const postData = (path, body, anonymous_request = false) => {
  const token = localStorage.getItem('token');

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  if (!anonymous_request) headers.Authorization = `Bearer ${token}`;

  return fetch(process.env.REACT_APP_API_ADDRESS + path, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  }).then((response) => response.json());
};

export const downloadAPIFile = (path, fileName, successCallback, errorCallback) => {
  const token = localStorage.getItem('token');
  fetch(process.env.REACT_APP_API_ADDRESS + path, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  })
    .catch((error) => {
      if (errorCallback !== undefined) errorCallback(error);
    })
    .then((response) => response.blob())
    .then((blob) => {
      const {
        URL: { createObjectURL, revokeObjectURL },
        setTimeout,
      } = window;
      const url = createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.setAttribute('href', url);
      anchor.setAttribute('download', fileName);
      anchor.click();

      setTimeout(() => {
        revokeObjectURL(url);
      }, 100);

      if (successCallback !== undefined) successCallback(blob);
    });
};

export const deleteData = async (path, dataId) => {
  const token = localStorage.getItem('token');
  await fetch(process.env.REACT_APP_API_ADDRESS + path + `/${dataId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((response) => {
    if (!response.ok) throw Error;
  });
};

// Example of use:
// updateItemInCache(queryClient, annotationQueryKey,
//   (annotation) => annotation.id === segment.id,
//   () => ({ title: editedTitle })
// );
export const updateItemInCache = (queryClient, queryKey, predicate, update) => {
  queryClient.setQueryData(queryKey, (oldData) => {
    if (!oldData) return oldData;

    return oldData.map((item) => (predicate(item) ? { ...item, ...update(item) } : item));
  });
};

// Example of use:
// removeItemInCache(queryClient, annotationQueryKey,
//   (annotation) => annotation.id === segment.id
// );
export const removeItemFromCache = (queryClient, queryKey, predicate) => {
  queryClient.setQueryData(queryKey, (oldData) => {
    if (!oldData) return oldData;

    return oldData.filter((item) => !predicate(item));
  });
};

// Example of use:
// addItemInCache(queryClient, annotationQueryKey, newAnnotation);
export const addItemToCache = (queryClient, queryKey, newItem) => {
  queryClient.setQueryData(queryKey, (oldData) => {
    if (!oldData) return [newItem];

    return [...oldData, newItem];
  });
};
