const token = localStorage.getItem('token');

// Marks all unread notifications as read
export const readAllNotifications = (notifications) => {
  for (const notification of notifications) {
    if (!notification.read_date) {
      fetch(process.env.REACT_APP_API_ADDRESS + `notifications/${notification.id}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/merge-patch+json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          read_date: Math.floor(Date.now() / 1000),
        }),
      });
    }
  }
};
