import axios from 'axios';
import { keepAliveEngine } from '../engine/engine-api';
import { deleteData } from '../api/api-tools';
import { base64ToBlobURL } from '../../helpers/image/image-helper';

// Sends an image to an endpoint for fully segmentation, retrieving masks representing segmented objects.
export const segmentEverything = async (object_id, img) => {
  try {
    const requestData = {
      object_id: object_id,
      image: img,
    };

    const response = await axios.post(`${process.env.REACT_APP_ENGINE_SEGMENTATION_ADDRESS}everything`, requestData);

    const { masks } = response.data.data;

    return masks;
  } catch (error) {
    throw new Error('Error while segment img everything : ' + error.message);
  }
};

// Sends points data to an endpoint for specific segmentation, retrieving masks representing segmented objects.
export const segmentPoints = async (object_id, points) => {
  try {
    const requestData = {
      object_id: object_id,
      points: points,
    };

    const response = await axios.post(`${process.env.REACT_APP_ENGINE_SEGMENTATION_ADDRESS}points`, requestData);

    const { masks } = response.data.data;

    return masks;
  } catch (error) {
    throw new Error('Error while segment image point : ' + error.message);
  }
};

const EVENT_TYPE_DEFAULT = 1;

// Saves a segmented object by sending the object's data to the segmentation engine endpoint for saving.
// If successful, it then saves the object's metadata to the database.
export const segmentSave = async (object_id, points, name, workstationSelected, deviceId, streamId) => {
  try {
    const requestData = {
      object_id: object_id,
      points: points,
    };

    console.log('[DEBUG] Save object (engine)');
    const saveEngine = await axios.post(`${process.env.REACT_APP_ENGINE_SEGMENTATION_ADDRESS}save`, requestData);

    if (saveEngine.data.success) {
      console.log('[DEBUG] Save object (database)');

      const saveDatabase = await axios.post(
        `${process.env.REACT_APP_API_ADDRESS}targets`,
        {
          name: name,
          workstation: `/api/workstations/${workstationSelected.id}`,
          position: saveEngine.data.data.position,
          device: `/api/devices/${deviceId}`,
          eventType: `/api/event_types/${EVENT_TYPE_DEFAULT}`,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      if (saveDatabase) {
        console.log('[DEBUG] Keep engine alive (with new data)');

        await keepAliveEngine(streamId, true);

        return saveDatabase; // TODO Create a specific response dict
      }
    }
  } catch (error) {
    throw new Error('Error while saving the segment : ' + error.message);
  }
};

// Deletes a segmented object by first removing its metadata from the database.
// It logs debugging information and keeps the segmentation engine alive with new data after deletion.
export const segmentDelete = async (targetId, streamId) => {
  try {
    console.log('[DEBUG] Delete object (database)');
    let path = 'targets';
    const response = await deleteData(path, targetId);
    console.log('[DEBUG] Keep engine alive (with new data)');
    await keepAliveEngine(streamId, true);
    return response; // TODO Create a specific response dict
  } catch (error) {
    throw new Error('An error occurred while deleting target:' + error.message);
  }
};

export const getSegmentationMasks = async (imageId, imageURL) => {
  const masksURL = await segmentEverything(imageId, imageURL);
  const blobURL = base64ToBlobURL(masksURL);
  return blobURL;
};

export const getPointSegmentationMask = async (imageId, pointsCoordinates) => {
  const masksURL = await segmentPoints(imageId, pointsCoordinates);
  const blobURL = base64ToBlobURL(masksURL);
  return blobURL;
};
