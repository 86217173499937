import clsx from 'clsx';
import { getImageDisplayCoordinates } from '../../../../../../helpers/image/image-helper';
import { useAnnotation } from '../../../../../../contexts/AnnotationContext';

export const CircleMarker = ({ marker, frameSizes, displaySizes }) => {
  const { position, id } = marker;

  const isMarkerInError = marker.specification_error?.is_error_reviewed === false;

  const { markerHovered, setMarkerHovered, isMarkerSelected, setIsMarkerSelected } = useAnnotation();

  const isNewMarker = typeof id === 'string';
  const isMarkerActive = markerHovered?.id === marker.id;

  const coordinates = getImageDisplayCoordinates(position, frameSizes, displaySizes);

  const baseClass = 'bg-transparent z-20 shadow-lg absolute rounded-full';
  let variantClass;

  if (isNewMarker) {
    variantClass = 'w-3 h-3 border-4 border-perception-blue';
  } else if (isMarkerInError) {
    variantClass = ' w-6 h-6  border-8 cursor-pointer border-perception-warn-500';
  } else {
    variantClass = ' w-6 h-6  border-8 cursor-pointer border-perception-blue';
  }

  if (isMarkerActive) {
    // TODO : Make highlight
    variantClass += ' border-perception-blue';
  }

  const handleMouseEnter = () => {
    if (!isNewMarker && !isMarkerSelected) {
      setMarkerHovered(marker);
    }
  };

  const handleMouseLeave = () => {
    if (!isMarkerSelected) {
      setMarkerHovered(null);
    }
  };

  const handleClick = () => {
    if (!isNewMarker) {
      setMarkerHovered(marker);
      setIsMarkerSelected(true);
    }
  };

  return (
    <div
      className={clsx(baseClass, variantClass)}
      style={{
        top: coordinates.y - 3,
        left: coordinates.x - 3,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    />
  );
};
