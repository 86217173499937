import { useSocket } from '../../contexts/SocketContext';
import { useTrialLiveContext } from '../../contexts/TrialLiveContext';
import { useTrialSetupContext } from '../../contexts/TrialSetupContext';

// "view" can be either of type "setup" or of type "prod" to adjust the logic according to the page.
// For a different logic, you need to modify the above code to allow for a new view.
export const ServiceStatusIndicator = ({ view }) => {
  let indicatorColor, indicatorText, tooltipContent;

  if (view === 'prod') {
    const { isSocketConnected } = useSocket();
    if (isSocketConnected) {
      indicatorColor = '#2CEAA8';
      indicatorText = 'Receiving logs';
      tooltipContent = 'Logs are being received';
    } else {
      indicatorColor = '#F9224B';
      indicatorText = 'Not receiving logs';
      tooltipContent = 'Logs are not being received';
    }
  } else if (view === 'setup') {
    const { isSocketConnected } = useSocket();
    const { isCameraReady } = useTrialSetupContext();
    const { isEngineRunning } = useTrialLiveContext();

    if (isEngineRunning && isSocketConnected && isCameraReady) {
      indicatorColor = '#2CEAA8';
      indicatorText = 'Engine running';
      tooltipContent = 'All services are connected';
    } else if (isEngineRunning || isSocketConnected || isCameraReady) {
      indicatorColor = '#ec6b22';
      const countServices = (isEngineRunning ? 1 : 0) + (isSocketConnected ? 1 : 0) + (isCameraReady ? 1 : 0);
      indicatorText = countServices + '/3 services running';

      tooltipContent = `Engine: ${
        isEngineRunning ? 'connected' : 'disconnected'
      } | Socket server: ${isSocketConnected ? 'connected' : 'disconnected'} | Camera: ${
        isCameraReady ? 'ready' : 'not ready'
      }`;
    } else {
      indicatorColor = '#F9224B';
      indicatorText = 'Engine not running';
      tooltipContent = 'Unable to connect to engine services';
    }
  }

  return (
    <div className='flex items-center relative group cursor-pointer'>
      <span className='text-white mr-2 mb-1 text-xs'>{indicatorText}</span>
      <span className='relative flex h-3 w-3'>
        <span
          className='animate-ping absolute inline-flex h-full w-full rounded-full opacity-75'
          style={{ backgroundColor: indicatorColor }}
        />
        <span className='relative inline-flex rounded-full h-3 w-3' style={{ backgroundColor: indicatorColor }} />
      </span>
      <span className='absolute right-0 top-100 mb-1 w-max p-2 text-xs text-white bg-black rounded hidden group-hover:block'>
        {tooltipContent}
      </span>
    </div>
  );
};
