import { useState } from 'react';

export const UseCaseCard = ({ id, img, text, setSelectedUseCaseId }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleClick = () => setSelectedUseCaseId(id);

  return (
    <div style={{ width: 'calc(50% - 32px)' }} className='m-4'>
      <div onClick={handleClick} className='xl:h-[200px] overflow-hidden cursor-pointer relative'>
        <img
          src={img}
          onLoad={() => setIsImageLoaded(true)}
          className='object-cover w-full h-full'
          alt='factory environment'
        />
        {isImageLoaded && <PlayIcon />}
        <div className='w-full h-full bg-perception-black-800 absolute opacity-0 pointer-events-none top-0 left-0' />
        <span className='absolute top-0 left-0 w-full h-full flex justify-center items-center z-10 opacity-0 pointer-events-none'>
          <p className='font-semibold text-lg text-white z-20'>{text}</p>
        </span>
      </div>
      <div className='text-white mt-2'>
        {id} | {text}
      </div>
    </div>
  );
};

const PlayIcon = () => {
  return (
    <svg
      className='absolute top-1/2 left-1/2 z-10 pointer-events-none opacity-100 w-10 h-10'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      fill='white'
    >
      <path d='M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z' />
    </svg>
  );
};
