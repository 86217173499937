import { useForm } from 'react-hook-form';
import { useUser } from '../../../contexts/UserContext';
import { Button } from '../../buttons/Button';
import { InputWithError } from '../../forms/inputs/InputWithError';
import { postUserLogin } from '../../../services/entity/user/user-api';
import { HeadForm } from '../AuthForm';
import { GoogleLoginButton } from '../../buttons/GoogleLoginButton';
import { generateRandomString } from '../../../helpers/common/generateRandomString';

export const LoginForm = ({ setIsFormLoading, setIsAuth, setIsForgotPassword }) => {
  const {
    handleSubmit,
    formState: { errors },
    setError,
    register,
  } = useForm();

  const { updateUser } = useUser();

  const randomStreamId = generateRandomString(5);

  const onSubmit = async (data) => {
    try {
      setIsFormLoading(true);
      let userData = await postUserLogin({
        email: data.email,
        password: data.password,
      });
      if (userData) {
        updateUser(userData);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setError('email', {
          type: 'manual',
          message: 'Invalid email',
        });
      } else if (error.response.status === 401) {
        setError('password', {
          type: 'manual',
          message: 'Invalid password',
        });
      } else {
        setError('email', {
          type: 'manual',
          message: 'Something bad happened...',
        });
      }
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <div className='w-full h-full justify-center flex flex-col'>
      <HeadForm title='Connexion' />
      <form className='flex flex-col justify-start item-center mt-4' onSubmit={handleSubmit(onSubmit)}>
        <InputWithError
          category='primary'
          placeholder='email'
          name='email'
          errors={errors}
          type='email'
          fieldName='email'
          register={register}
        />
        <InputWithError
          category='primary'
          placeholder='password'
          name='password'
          errors={errors}
          type='password'
          fieldName='password'
          register={register}
        />
        <div className='flex items-center justify-between w-full py-2'>
          <span
            className='w-full text-center text-xs text-perception-blue cursor-pointer mt-2'
            onClick={() => setIsAuth(false)}
          >
            Don&apos;t have an account?
          </span>
          <span
            className='w-full text-center text-xs text-perception-blue cursor-pointer mt-2'
            onClick={() => setIsForgotPassword(true)}
          >
            Forgot password
          </span>
        </div>

        <div className='flex flex-col justify-center items-center mt-2'>
          <Button category='primary-btn' size='medium' type='submit'>
            Log In
          </Button>
          <GoogleLoginButton streamId={randomStreamId} setIsFormLoading={setIsFormLoading} />
        </div>
      </form>
    </div>
  );
};
