import PreviewMaintenance from '../assets/images/autosetup/preview_maintenance.png';
import PreviewProduction from '../assets/images/autosetup/preview_production.png';
import PreviewSecurity from '../assets/images/autosetup/preview_security.png';
import UseCase1 from '../assets/videos/usecase1.mp4';
import UseCase2 from '../assets/videos/usecase2.mp4';
import UseCase3 from '../assets/videos/usecase3.mp4';

export const logsText = [
  {
    id: 0,
    text: 'No event detected yet',
  },
  {
    id: 1,
    text: 'Interaction with {{TARGET_NAME}}',
  },
  {
    id: 2,
    text: 'Target created:',
  },
  {
    id: 3,
    text: 'Human working in area (Total: {{HUMANS_COUNT}})',
  },
  {
    id: 4,
    text: 'A human left the area (Total: {{HUMANS_COUNT}})',
  },
  {
    id: 5,
    text: 'No human working in area',
  },
  {
    id: 10,
    text: 'Machine opened',
  },
  {
    id: 11,
    text: 'Human working in area',
  },
  {
    id: 12,
    text: 'Human interaction with bench start',
  },
  {
    id: 13,
    text: 'Human interaction with bench end',
  },
  {
    id: 14,
    text: 'Machine closed',
  },
  {
    id: 15,
    text: 'Machine production launch',
  },
  {
    id: 16,
    text: 'No human working in area',
  },
  {
    id: 17,
    text: 'Human interaction with machine interior start',
  },
  {
    id: 18,
    text: 'Human interaction with machine interior end',
  },
  {
    id: 19,
    text: 'Human interaction with screen',
  },
  {
    id: 20,
    text: 'Machine production end',
  },
  {
    id: 21,
    text: 'Maintenance barrel detected',
  },
  {
    id: 22,
    text: 'Maintenance level 1 started',
  },
  {
    id: 23,
    text: 'Production barrel moving',
  },
  {
    id: 24,
    text: 'Maintenance barrel in position',
  },
  {
    id: 25,
    text: 'Human interaction with maintenance barrel',
  },
  {
    id: 26,
    text: 'Human interaction with machine tank',
  },
  {
    id: 27,
    text: 'Maintenance step 1 non achieved',
  },
  {
    id: 28,
    text: 'Alert sent to operator',
  },
  {
    id: 29,
    text: 'Undefined object moving',
  },
  {
    id: 30,
    text: 'Undefined object left in working area',
  },
  {
    id: 31,
    text: 'Dangerous situation',
  },
  {
    id: 32,
    text: 'Event sent to MES',
  },
  {
    id: 33,
    text: 'Last time occurrence 20/08/2023 without problem',
  },
  {
    id: 34,
    text: 'Same situation detected 10 days ago',
  },
  {
    id: 40,
    text: 'Work plate positioned',
  },
  {
    id: 41,
    text: 'Work plate removed',
  },
  {
    id: 42,
    text: 'Plate support open',
  },
  {
    id: 43,
    text: 'Plate support closed',
  },
  {
    id: 44,
    text: 'Parametric paper positioned',
  },
  {
    id: 45,
    text: 'Parametric paper removed',
  },
  {
    id: 46,
    text: 'Human working in area (Total: 1)',
  },
  {
    id: 47,
    text: 'Human working in area (Total: 2)',
  },
  {
    id: 48,
    text: 'Pattern detected: work plate changed',
  },
  {
    id: 49,
    text: 'Pattern detected: machine ready to use',
  },
  {
    id: 50,
    text: 'Machine locked',
  },
];

export const useCases = [
  {
    id: 1,
    text: 'Production',
    img: PreviewProduction,
    video: UseCase1,
    logs: [
      {
        id: 1,
        type: 1,
        subtype: 10,
        time: '00:00',
      },
      {
        id: 2,
        type: 1,
        subtype: 11,
        time: '00:00',
      },
      {
        id: 3,
        type: 2,
        subtype: 12,
        time: '00:00',
      },
      {
        id: 4,
        type: 2,
        subtype: 13,
        time: '00:03',
      },
      {
        id: 5,
        type: 2,
        subtype: 17,
        time: '00:04',
      },
      {
        id: 6,
        type: 2,
        subtype: 18,
        time: '00:34',
      },
      {
        id: 7,
        type: 2,
        subtype: 19,
        time: '00:35',
      },
      {
        id: 8,
        type: 1,
        subtype: 14,
        time: '00:43',
      },
      {
        id: 9,
        type: 3,
        subtype: 15,
        sub: 32,
        time: '00:43',
      },
      {
        id: 10,
        type: 1,
        subtype: 16,
        time: '00:45',
      },
      {
        id: 11,
        type: 1,
        subtype: 11,
        time: '00:50',
      },
      {
        id: 12,
        type: 1,
        subtype: 10,
        time: '00:53',
      },
      {
        id: 13,
        type: 2,
        subtype: 17,
        time: '00:56',
      },
      {
        id: 14,
        type: 2,
        subtype: 18,
        time: '01:22',
      },
    ],
  },
  {
    id: 2,
    text: 'Maintenance',
    img: PreviewMaintenance,
    video: UseCase2,
    logs: [
      {
        id: 1,
        type: 1,
        subtype: 16,
        time: '00:00',
      },
      {
        id: 2,
        type: 1,
        subtype: 11,
        time: '00:04',
      },
      {
        id: 3,
        type: 1,
        subtype: 21,
        time: '00:04',
      },
      {
        id: 4,
        type: 3,
        subtype: 22,
        sub: 32,
        time: '00:04',
      },
      {
        id: 5,
        type: 1,
        subtype: 23,
        time: '00:05',
      },
      {
        id: 6,
        type: 1,
        subtype: 24,
        time: '00:09',
      },
      {
        id: 7,
        type: 2,
        subtype: 25,
        time: '00:11',
      },
      {
        id: 8,
        type: 2,
        subtype: 26,
        time: '00:13',
      },
      {
        id: 9,
        type: 4,
        subtype: 27,
        sub: 33,
        time: '00:12',
      },
      {
        id: 10,
        type: 4,
        subtype: 28,
        time: '00:12',
      },
      {
        id: 11,
        type: 1,
        subtype: 16,
        time: '00:21',
      },
    ],
  },
  {
    id: 3,
    text: 'Dangerous situation',
    img: PreviewSecurity,
    video: UseCase3,
    logs: [
      {
        id: 1,
        type: 1,
        subtype: 11,
        time: '00:00',
      },
      {
        id: 2,
        type: 2,
        subtype: 19,
        time: '00:02',
      },
      {
        id: 3,
        type: 1,
        subtype: 14,
        time: '00:07',
      },
      {
        id: 4,
        type: 1,
        subtype: 16,
        time: '00:10',
      },
      {
        id: 5,
        type: 1,
        subtype: 29,
        time: '00:16',
      },
      {
        id: 6,
        type: 1,
        subtype: 30,
        time: '00:24',
      },
      {
        id: 7,
        type: 4,
        subtype: 31,
        sub: 34,
        time: '00:25',
      },
      {
        id: 8,
        type: 4,
        subtype: 28,
        time: '00:25',
      },
    ],
  },
];
