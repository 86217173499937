import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

export const InfoMessage = ({ text }) => {
  return (
    <div className='flex justify-start items-center mt-6'>
      <div className='flex flex-col justify-center items-center rounded-full w-[30px] h-[30px] bg-perception-blue'>
        <QuestionMarkCircleIcon className='w-[20px] h-[20px] text-white' />
      </div>
      <p className='pl-2 w-2/3'>{text}</p>
    </div>
  );
};
