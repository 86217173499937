import clsx from 'clsx';
import { useEffect, useRef } from 'react';

export const Modal = ({ type = 'info', className, closeModal, children }) => {
  const modalRef = useRef(null);

  const baseClass =
    'min-w-[24rem] p-12 bg-perception-gray-800 flex flex-col border fixed left-1/2 top-1/2 z-50 text-white -translate-x-2/4 -translate-y-2/4';
  let variantClass = '';

  switch (type) {
    case 'info':
      variantClass += ' border-perception-blue';
      break;
    case 'error':
      variantClass += ' border-perception-error-500';
      break;
    case 'success':
      variantClass += ' border-perception-success-600';
      break;
    default:
      variantClass += ' border-perception-blue';
      break;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        if (closeModal) {
          closeModal();
        }
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal]);
  return (
    <>
      <div className='fixed inset-0 h-full w-full bg-black/40 z-20 backdrop-blur-xs'></div>
      <div ref={modalRef} className={clsx(baseClass, variantClass, className)}>
        {children}
      </div>
    </>
  );
};
