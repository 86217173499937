import { Outlet } from 'react-router-dom';
import { Topbar } from '../topbar/Topbar';
import { Navbar } from '../navbar/Navbar';
import { LoginLayout } from './LoginLayout';
import { BlurLayout } from './BlurLayout';
import { RegisterOrganization } from '../auth/RegisterOrganization';

export const CoreLayout = () => {
  return (
    <div className='flex h-full flex-col'>
      <Topbar />
      <Navbar />
      <div className='flex min-h-0 flex-1 overflow-hidden z-0'>
        <main className='min-w-0 flex-1 lg:flex bg-perception-black-800'>
          <section
            aria-labelledby='primary-heading'
            className='flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last'
          >
            <div className='relative h-full'>
              <Outlet />
            </div>
          </section>
        </main>
      </div>
      <LoginLayout />
      <BlurLayout />
      <RegisterOrganization />
    </div>
  );
};
