import { useRef, useState, useEffect } from 'react';
import { Panel } from '../../../../components/panels/Panel';
import { PatternEventTypes } from './components/PatternEventTypes';
import { usePatternSetup } from '../../../../contexts/PatternSetupContext';
import { DataPattern } from './components/DataPattern';
import { CommentaryPattern } from './components/CommentaryPattern';
import { WarnModal } from './components/WarnModal';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useOrgData } from '../../../../contexts/OrgDataContext';

export const PatternPanel = () => {
  const inputRef = useRef(null);
  const { workstationSelected } = useOrgData();

  const {
    isPatternPanelOpened,
    setIsPatternPanelOpened,
    patternName,
    setPatternName,
    setIsPatternTextOnEdit,
    setPatternEventTypes,
    setLogsChecked,
    cleanEditingPattern,
  } = usePatternSetup();

  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);
  const [idPatternEventTypeToDelete, setIdPatternEventTypeToDelete] = useState(null);

  const handleNameChange = (event) => {
    setPatternName(event.target.value.toUpperCase());
    setIsPatternTextOnEdit(true);
  };

  const handleNameBlur = () => {
    setIsPatternTextOnEdit(false);
  };

  const handleDeleteEvent = (id) => {
    setPatternEventTypes((currentPatternEventTypes) =>
      currentPatternEventTypes
        .filter((patternEventType) => patternEventType.id !== id)
        .map((patternEventType, index) => ({ ...patternEventType, order: index + 1 })),
    );

    setLogsChecked((prevLogsChecked) => ({
      ...prevLogsChecked,
      [id]: false,
    }));
  };

  const onConfirmDelete = (id) => {
    handleDeleteEvent(id);
    setIsWarnModalOpen(false);
    setIdPatternEventTypeToDelete(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
    }
  };

  const handleExitPattern = () => {
    setIsPatternPanelOpened(false);
    cleanEditingPattern();
  };

  useEffect(() => {
    if (workstationSelected) {
      handleExitPattern();
    }
  }, [workstationSelected]);

  useEffect(() => {
    if (isPatternPanelOpened && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isPatternPanelOpened]);

  return (
    <div className={`flex flex-col w-full h-full overflow-hidden ${isPatternPanelOpened ? '' : 'opacity-0'}`}>
      <Panel category='secondary' title='CREATE PATTERN'>
        <div className='flex justify-between items-start'>
          <input
            name='pattern-name'
            ref={inputRef}
            type='text'
            value={patternName}
            onChange={handleNameChange}
            onBlur={handleNameBlur}
            onKeyDown={handleKeyDown}
            className='text-xl text-perception-gray-500 font-semibold bg-transparent border outline-none border-perception-blue'
          />
          <XMarkIcon className='h-6 w-6 text-perception-gray-500 cursor-pointer' onClick={handleExitPattern} />
        </div>
        <PatternEventTypes
          isWarnModalOpen={isWarnModalOpen}
          setIsWarnModalOpen={setIsWarnModalOpen}
          setIdPatternEventTypeToDelete={setIdPatternEventTypeToDelete}
        />
        <DataPattern />
        <CommentaryPattern />
      </Panel>
      {isWarnModalOpen && (
        <WarnModal
          isOpen={isWarnModalOpen}
          setIsWarnModalOpen={setIsWarnModalOpen}
          onConfirmDelete={() => onConfirmDelete(idPatternEventTypeToDelete)}
        />
      )}
    </div>
  );
};
