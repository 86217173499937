// Get the user color (or default color if no color)
export const getUserColor = (user) => {
  let color;
  if (!user.state && user.color !== undefined) {
    color = user.color.hex_code;
  } else {
    color = '#FFFFFF';
  }

  return color;
};

// Get user_group depending on id - return null if no match
export const getUserGroupById = (id, user) => {
  if (user.organization && user.organization.user_groups) {
    let userGroup = user.organization.user_groups.filter((group) => group.id === id)[0];
    return userGroup ? userGroup : null;
  }
};

export const isFreeUser = (user) => {
  return user?.roles?.includes('ROLE_FREE');
};

export const isTeamUser = (user) => {
  return user?.roles?.includes('ROLE_ROOT');
};
