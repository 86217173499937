import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { UserProvider } from './contexts/UserContext';
import { AlertProvider } from './contexts/AlertContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { OrgDataProvider } from './contexts/OrgDataContext';
import { SocketProvider } from './contexts/SocketContext';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GoogleOAuthProvider clientId='220792802624-rscc8ojpkuob4oobdn8gnvek848dbf6h.apps.googleusercontent.com'>
        <QueryClientProvider client={queryClient}>
          <AlertProvider>
            <UserProvider>
              <OrgDataProvider>
                <SocketProvider>
                  <App />
                </SocketProvider>
              </OrgDataProvider>
            </UserProvider>
          </AlertProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
