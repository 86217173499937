export const parseCSV = (csvData) => {
  const lines = csvData.split('\n');
  const headers = lines[0].split(',').map((header) => header.trim());
  const data = lines.slice(1).map((line) => {
    const values = line.split(',');
    const obj = {};
    headers.forEach((header, index) => {
      obj[header] = values[index].trim();
    });
    return obj;
  });
  return data;
};

export const cleanParsedData = (data) => {
  return data.map((item) => {
    const cleanedItem = {};
    for (const key in item) {
      const cleanedKey = key.replace(/"/g, '').trim();
      const cleanedValue = item[key].replace(/"/g, '').trim();
      cleanedItem[cleanedKey] = cleanedValue;
    }
    return cleanedItem;
  });
};

export const dataURLtoFile = (dataURI, filename) => {
  const arr = dataURI.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
