import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getOrganizationByToken } from '../../services/entity/organization/organization-api';
import { AuthForm } from './AuthForm';

export const RegisterOrganization = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { organization_token } = useParams();

  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    if (organization_token) {
      try {
        const getOrganization = async () => {
          const organization = await getOrganizationByToken(organization_token);
          if (organization) {
            setOrganization(organization);
          } else {
            navigate('/production');
          }
        };

        getOrganization();
      } catch (err) {
        console.error(err);
      }
    }
  }, [organization_token]);

  if (!location.pathname.includes('register')) return null;

  return <AuthForm organization={organization} />;
};
