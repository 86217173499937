export const areObjectsEqual = (objA, objB) => {
  if (objA === null && objB === null) return true;
  else if (objA === null || objB === null) return false;

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (objA.length !== undefined && keysA.length !== keysB.length) return false;

  for (const key of keysA) {
    if (!keysB.includes(key)) continue;

    const valueA = objA[key];
    const valueB = objB[key];

    if (typeof valueA === 'object' && typeof valueB === 'object') {
      if (!areObjectsEqual(valueA, valueB)) {
        return false;
      }
    } else if (valueA !== valueB) {
      return false;
    }
  }

  return true;
};
