import { LOGS_TYPES } from '../../log/log-service';

// This code retrieves possible patterns associated with given targets,
// filtering patterns based on their event types and the provided targets.
export const getPossiblePatternsFromTarget = (targets, patterns) => {
  try {
    if (patterns.length) {
      const targetIds = new Set(targets.map((target) => target.id));
      return patterns.filter(
        (pattern) =>
          pattern.pattern_event_types.length > 0 &&
          pattern.pattern_event_types.every(
            (patternEventType) => !patternEventType.target || targetIds.has(patternEventType.target.id),
          ),
      );
    }
  } catch (err) {
    console.error("Une erreur s'est produite lors de la récupération des motifs possibles :", err);
  }
};

const MAX_DURATION_BETWEEN_PATTERN_EVENTS = 10 * 60;
const MIN_PERCENTAGE_TO_DISPLAY_PATTERN = 50;

export const detectPatternsInLogs = (originalLogs, patterns) => {
  let detectedPatterns = [];
  let logs = originalLogs.toReversed();

  // Try to detect each pattern
  for (const pattern of patterns) {
    if (!pattern.is_active || pattern.pattern_event_types.length < 2) continue;

    let lastDetectedPatternEventDate = null;
    let currentPatternEventIndex = 0;

    let patternLogs = [];

    // We go through each log to check if it is part of a pattern
    for (const log of logs) {
      const logDate = new Date(log.time);

      // If the last detected pattern event is too old, we consider the pattern is incomplete
      if (
        lastDetectedPatternEventDate !== null &&
        Math.abs(lastDetectedPatternEventDate - logDate) / 1000 > MAX_DURATION_BETWEEN_PATTERN_EVENTS
      ) {
        const percentage = (currentPatternEventIndex / pattern.pattern_event_types.length) * 100;

        if (percentage > MIN_PERCENTAGE_TO_DISPLAY_PATTERN) {
          detectedPatterns.push({
            client_id: log.client_id,
            type: LOGS_TYPES.PATTERN,
            human_id: log.human_id,
            measurement: log.measurement,
            time: lastDetectedPatternEventDate,
            pattern_name: pattern.name,
            pattern_logs: patternLogs.toReversed(),
            object_id: log.object_id,
            percentage: percentage,
          });
        }

        // We reset both variables and start the detection again without skipping any pattern event
        lastDetectedPatternEventDate = null;
        currentPatternEventIndex = 0;
        patternLogs = [];
      }

      // Current pattern event type to detect
      const currentPatternEvent = pattern.pattern_event_types[currentPatternEventIndex];
      // Previous pattern event detected
      const previousPatternEvent =
        currentPatternEventIndex > 0 ? pattern.pattern_event_types[currentPatternEventIndex - 1] : null;

      // If we detect the right pattern event at the right place
      if (parseInt(log.subtype) === parseInt(currentPatternEvent.event_type.subtype)) {
        patternLogs.push(log);
        // If the pattern is complete
        if (currentPatternEventIndex === pattern.pattern_event_types.length - 1) {
          detectedPatterns.push({
            client_id: log.client_id,
            type: LOGS_TYPES.PATTERN,
            human_id: log.human_id,
            measurement: log.measurement,
            time: log.time,
            pattern_name: pattern.name,
            pattern_logs: patternLogs.toReversed(),
            object_id: log.object_id,
            percentage: 100,
          });

          lastDetectedPatternEventDate = null;
          currentPatternEventIndex = 0;
          patternLogs = [];
        }

        // Else, continue to find next pattern events
        else {
          lastDetectedPatternEventDate = new Date(log.time);
          currentPatternEventIndex++;
        }
      }

      // The current event type is not the one we want in the pattern,
      // but it is the same as the previous one => we consider the pattern starting from this new pattern event
      else if (
        previousPatternEvent !== null &&
        parseInt(log.subtype) === parseInt(previousPatternEvent.event_type.subtype) &&
        parseInt(log.object_id) === parseInt(previousPatternEvent?.target?.id)
      ) {
        lastDetectedPatternEventDate = new Date(log.time);
        patternLogs[patternLogs.length - 1] = log;
      }
    }
  }

  return detectedPatterns;
};
