import { postData } from '../../api/api-tools';

export const createPatternFailure = async (eventId, isFixed) => {
  try {
    const body = {
      patternEventType: `/api/pattern_event_types/${eventId}`,
      date: Math.floor(Date.now() / 1000),
      has_been_fixed: isFixed,
    };

    const response = await postData('pattern_failures', body);
    if (response) return response;
  } catch (error) {
    console.error('Error creating new pattern on database', error);
    return null;
  }
};
