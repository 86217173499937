import clsx from 'clsx';
import { SpinnerIcon } from './SpinnerIcon';

export const Loader = ({ category = 'default', className }) => {
  const baseClass = 'flex absolute left-0 top-0 justify-center z-50 items-center w-full h-full backdrop-blur-sm';
  let customClass;

  if (category === 'default') {
    customClass = 'bg-black bg-opacity-50';
  } else if (category === 'transparent') {
    customClass = '';
  }

  return (
    <div className={clsx(baseClass, customClass, className)}>
      <SpinnerIcon />
    </div>
  );
};
