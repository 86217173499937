import { useRef } from 'react';
import { Button } from '../../buttons/Button';

export const InputFile = ({ onChange, buttonText, ...props }) => {
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    onChange(e);
  };

  return (
    <div>
      <input type='file' ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} {...props} />
      <Button category='tertiary-btn' size='xs' onClick={handleClick}>
        {buttonText}
      </Button>
    </div>
  );
};
