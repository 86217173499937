import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { InputWithError } from '../../forms/inputs/InputWithError';
import { Button } from '../../buttons/Button';
import { HeadForm } from '../AuthForm';
import { useUser } from '../../../contexts/UserContext';
import { postUserConfirmRegister } from '../../../services/entity/user/user-api';
import { Loader } from '../../loading/Loader';

export const VerificationCode = () => {
  const { user, updateUser, logoutUser } = useUser();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      const response = await postUserConfirmRegister({
        email: user.email,
        confirmation_code: data.verificationCode,
      });

      if (response.status === 200) {
        updateUser({
          user: response.data,
          token: localStorage.getItem('token'),
        });
      }
    } catch (error) {
      console.error('Something bad happened:', error);

      if (error.response) {
        switch (error.response.status) {
          case 400:
            setError('verificationCode', {
              type: 'manual',
              message: 'You have already confirmed your email.',
            });
            break;
          case 404:
            setError('verificationCode', {
              type: 'manual',
              message: 'User not found.',
            });
            break;
          default:
            setError('verificationCode', {
              type: 'manual',
              message: 'An error occurred. Please try again later.',
            });
        }
      } else {
        setError('verificationCode', {
          type: 'manual',
          message: 'An error occurred during the verification process.',
        });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleKeyDown = (event) => {
    const acceptedKeys = ['Backspace', 'Tab', 'ArrowRight', 'ArrowLeft'];

    if (isNaN(event.key) && !(acceptedKeys.includes(event.key) || (event.ctrlKey && event.key === 'v'))) {
      event.preventDefault();
    }
  };

  return (
    <div className='w-full h-full flex flex-col justify-center'>
      <button onClick={logoutUser} className='absolute top-0 right-0 mt-2 mr-3'>
        X
      </button>
      {isSubmitting && <Loader />}
      <HeadForm title='Verification Code' text='Please enter the 6-digit verification code sent to your email' />
      <form className='flex flex-col justify-center items-center mt-4' onSubmit={handleSubmit(onSubmit)}>
        <InputWithError
          type='text'
          placeholder='Enter verification code'
          register={register}
          errors={errors}
          fieldName='verificationCode'
          onKeyDown={handleKeyDown}
          maxLength={6}
          errorMessage={errors.verificationCode?.message || 'Verification code must be 6 digits'}
        />
        <div className='flex flex-col justify-center items-center mt-2'>
          <Button type='submit' category='primary-btn' size='medium'>
            Verify
          </Button>
        </div>
      </form>
    </div>
  );
};
