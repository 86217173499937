import clsx from 'clsx';

export const Panel = ({ category = 'default', title, children, className }) => {
  const getBackgroundColor = () => {
    if (category === 'default') {
      return 'bg-perception-black-800';
    } else if (category === 'main') {
      return 'bg-perception-black-900';
    } else if (category === 'secondary') {
      return 'bg-perception-black-500';
    }
  };

  return (
    <div className={clsx('panel', getBackgroundColor(), 'flex flex-col h-full w-full relative', className)}>
      <HeaderPanel title={title} />
      <div className='py-4 px-4 h-full w-full flex flex-col text-perception-gray-500 overflow-y-auto'>{children}</div>
    </div>
  );
};

const HeaderPanel = ({ title }) => {
  return (
    <div className='bg-perception-gray-800 flex pl-4 py-[0.15rem] w-full'>
      <span className='text-white text-xs'>{title}</span>
    </div>
  );
};
